import { AvatarStatus } from "./avatar-with-status";
import { Link } from "react-router-dom";
import { FooterCollab } from "../collaboration/footer-collab";
import { FooterRequest } from "../request/footer-request";
import SignatureMenu from "./signature-menu";

const SignatureStatusCard = ({ page, status, data }) => {
  const FooterChild = () => {
    if (page.includes("collaboration")) {
      return <FooterCollab status={status} data={data} />;
    } else if (page.includes("request")) {
      return <FooterRequest data={data} />;
    }
    return <></>;
  };

  return (
    <>
      <Link to={`/dashboard/${page}/detail/${data.id}`}>
        <div className="signature__card py-5 px-5 bg-white rounded-lg">
          <div className="absolute text-right inset-y-0 right-0 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            <SignatureMenu pages={page} id={data.id} data={data} />
          </div>
          <img
            src={`data:image/png;base64,${data.qr_code}`}
            alt="unknown"
            width="130px"
            height="130px"
          />
          <h6>{data.name}</h6>
          <span>{data.description}</span>

          {page === "request" && (
            <span>
              Requested by{" "}
              <strong className="text__color_primary">{data.request_by}</strong>
            </span>
          )}

          <div className="signature__card_footer mb-5">
            <span className="label">
              {data.type ? data.type : data.category}
            </span>
            <span className="flex">
              {data.signer && data.signer.length > 0 ? (
                data.signer.map((item, ix) => (
                  <AvatarStatus key={`sg-${ix}`} data={item} />
                ))
              ) : (
                <></>
              )}

              {data.signers && data.signers.length > 0 ? (
                data.signers.map((item, ix) => (
                  <AvatarStatus key={`sgs-${ix}`} data={item} />
                ))
              ) : (
                <></>
              )}
            </span>
          </div>
          <FooterChild />
        </div>
      </Link>
    </>
  );
};

export default SignatureStatusCard;
