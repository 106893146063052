import React, { useEffect, useState } from "react";
import { Link, Redirect, useParams } from "react-router-dom";
import UploadImg from "../../assets/images/upload.png";
import { AlertComponent } from "../../components/alerts/alert-component";
import { useCustomAlert } from "../../components/alerts/alert-provider";
import { LoadingBarier } from "../../components/loading-barrier";
import { getData, postData } from "../../utility/network";
import { useCustomRefresh } from "../../utility/refresh-provider";
import utils, { print } from "../../utility/utils";
import logo from "../../assets/images/attachment.png";
import { useRef } from "react";
import { PencilIcon } from "@heroicons/react/solid";
import { FormSignatureStep } from "../../tour-step/form-signature";
import { FormCollaborationStep } from "../../tour-step/form-collaboration";
import Tour from "reactour";

const NewSignature = ({ single, key }) => {
  const [file, setFile] = useState(null);
  const [attachmentType, setAttachmentType] = useState(true);
  const [fileName, setFileName] = useState("");
  const [fileSize, setFileSize] = useState(false);
  const [signers, setSigners] = useState([]);
  const [types, setTypes] = useState([]);
  const [peoples, setPeoples] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [isError, setIsError] = useState(false);
  const [lp, setLP] = useState(false);
  const [code, setCode] = useState(false);
  const [signdata, setSignData] = useState({
    sign_number: "",
    user_name: "",
    user_email: "",
    name: "",
    kode: "",
    category_id: 0,
    type_id: 0,
    description: "",
    link: "",
    file: "",
    expired_date_at: "",
    expired_time_at: "",
    message: "",
  });
  const [errorInput, setErrorInput] = useState({
    sign_number: "",
    user_name: "",
    user_email: "",
    name: "",
    category_id: "",
    type_id: "",
    link: "",
    code: "",
    file: "",
    expired_date_at: "",
    expired_time_at: "",
  });
  const [setList] = useCustomAlert();
  const { id } = useParams();
  //reload data trigger by the child
  const [refresher, setRefresher] = useCustomRefresh();
  const loadingRef1 = useRef();
  const loadingRef2 = useRef();
  const [edit_sign, setEditSign] = useState(true);
  const [edit_name, setEditName] = useState(true);
  const [edit_email, setEditEmail] = useState(true);
  const [tour_signature, setSignatureTour] = useState(false);
  const [tour_collaboration, setCollaborationTour] = useState(false);

  useEffect(() => {
    document.title = "Teken | Add New Signature";
    loadingRef1.current.style.width = "0px";
    let sign_number = "";

    const getCategory = () => {
      let key = utils.randomAlphaNumeric();

      getData(
        "categories",
        {},
        (data) => {
          setCategories(data.data);
        },
        (msg, error) => {
          print(error);
          setList([
            <AlertComponent key={key} type="error" msg={error.message} />,
          ]);
        },
        () => {
          //print("Fetch category Finished");
        }
      );
    };

    const getType = () => {
      let key = utils.randomAlphaNumeric();

      getData(
        "types",
        {},
        (data) => {
          setTypes(data.data);
        },
        (msg, error) => {
          print(error);
          setList([
            <AlertComponent key={key} type="error" msg={error.message} />,
          ]);
        },
        () => {
          //print("Fetch types Finished");
        }
      );
    };

    const getPeoples = () => {
      let key = utils.randomAlphaNumeric();

      getData(
        "signers",
        {},
        (data) => {
          setPeoples(data.data);
        },
        (msg, error) => {
          print(error);
          setList([
            <AlertComponent key={key} type="error" msg={error.message} />,
          ]);
        },
        () => {
          //print("Fetch peoples Finished");
        }
      );
    };

    const getUser = () => {
      getData(
        "user/my-information",
        {},
        (data) => {
          setSignData({
            sign_number: sign_number,
            user_name: data.data.name,
            user_email: data.data.email,
            name: "",
            kode: "",
            category_id: "",
            type_id: "",
            description: "",
            link: "",
            expired_date_at: "",
            expired_time_at: "",
            message: "",
          });
        },
        (msg, error) => {
          print("error");
          print(error);
        },
        () => {
          //print("Fetch User Finished");
        }
      );
    };

    // get sign number
    const getSignNumber = () => {
      getData(
        "document/sign-number",
        {},
        (data) => {
          sign_number = utils.generateSignNumber(
            data.data.user_id,
            data.data.counter
          );

          getUser();
        },
        (msg, error) => {
          print(error);
        },
        () => {
          // print("Fetch sign number");
        }
      );
    };

    let unmounted = false;

    if (!unmounted) {
      getType();
      getCategory();
      getPeoples();
      getSignNumber();

      if (id !== null && id !== undefined) {
        const getSignatureData = () => {
          let key = utils.randomAlphaNumeric();
          let uri = single
            ? "sign/my-signature/" + id
            : "collab/" + id + "/detail";

          getData(
            uri,
            {},
            (data) => {
              data.data.category_id = data.data.category === "External" ? 1 : 2;
              data.data.user_email = data.data.email;
              data.data.user_name = data.data.created_by.split("(")[0];

              if (data.data.type === "Publish") {
                data.data.type_id = 2;
              } else if (data.data.type === "Private") {
                data.data.type_id = 1;
              } else {
                data.data.type_id = 3;
                setCode(true);
              }

              data.data.kode = data.data.code;
              data.data.expired_date_at = utils.formatDate(data.data.due_date);
              data.data.expired_time_at = data.data.time.replace(".", ":");

              setSigners(data.data.signers);
              setSignData(data.data);
            },
            (msg, error) => {
              print(error);
              setList([
                <AlertComponent key={key} type="error" msg={error.message} />,
              ]);
            },
            () => {
              //print("Fetch user Finished");
            }
          );
        };

        getSignatureData();
      }
    }

    if (single) {
      const status = JSON.parse(localStorage.getItem("tour_add_signature"));
      setSignatureTour(status === null && !status);
    } else {
      const status = JSON.parse(localStorage.getItem("tour_add_collab"));
      setCollaborationTour(status === null && !status);
    }

    return () => {
      unmounted = true;
    };
  }, [setList, id, single]);

  const handleChangeAttachment = (val) => {
    setAttachmentType(val);
  };

  const handleDoneSignature = () => {
    localStorage.setItem(
      "tour_add_signature",
      JSON.stringify({ tour_add_signature: true })
    );
    setSignatureTour(false);
  };

  const handleDoneCollaboration = () => {
    localStorage.setItem(
      "tour_add_collab",
      JSON.stringify({ tour_add_signature: true })
    );
    setCollaborationTour(false);
  };

  // ========== GET FILE FOR PREVIEW ========== //
  const getFile = ({ target: { files } }) => {
    let re = /(?:\.([^.]+))?$/;
    let ext = re.exec(files[0].name)[1];
    let err = {
      sign_number: "",
      user_name: "",
      user_email: "",
      name: "",
      category_id: "",
      type_id: "",
      expired_date_at: "",
      expired_time_at: "",
      kode: "",
      file: "",
    };

    if (ext.includes("pdf") || ext.includes("doc")) {
      // loading move
      setLP(true);
      loadingRef1.current.style.width = "90%";
      loadingRef2.current.style.width = "100%";

      let lprogress = setTimeout(() => {
        clearInterval(lprogress);
        setLP(false);
        setFileSize(false);

        // setUploading(true);
        // console.log(files[0]);
        // console.log(files[0].size);

        // Jika file lebih dari 35mb
        if (files[0].size > 36700160) {
          setFileSize(true);
          return;
        }

        setFile(files[0]);
        setFileName(files[0].name);

        loadingRef1.current.style.width = "0px";
        loadingRef2.current.style.width = "0px";
      }, 3000);
    } else {
      err["file"] = "Wrong file extension!";
    }
    setErrorInput(err);
  };

  // ========== DELETE FILE PREVIEW ========== //
  const deleteFile = () => {
    setFile(null);
    // setUploading(false);
  };

  // handle when user input on form
  const handleChange = (e) => {
    const { name, value } = e.currentTarget;

    let err = {
      sign_number: "",
      user_name: "",
      user_email: "",
      name: "",
      category_id: "",
      type_id: "",
      description: "",
      link: "",
      file: "",
      kode: "",
      expired_date_at: "",
      expired_time_at: "",
      message: "",
    };

    if (name === "type_id" && value === "3") {
      setCode(true);
    } else if (name === "type_id" && value !== "3") {
      setCode(false);
    }

    const newData = utils.parseObject(signdata, name, value);
    setSignData(newData);
    setErrorInput(err);
  };

  const handleAddSignature = () => {
    let kosong = false;
    setIsError(kosong);
    let err = {
      sign_number: "",
      user_name: "",
      user_email: "",
      name: "",
      category_id: "",
      type_id: "",
      expired_date_at: "",
      expired_time_at: "",
      kode: "",
    };

    Object.keys(signdata).forEach((key) => {
      if (signdata[key] === "") {
        // exception for the optional field
        if (key !== "description" && key !== "link" && key !== "message") {
          // my signature
          if (single) {
            if (
              key !== "expired_date_at" &&
              key !== "kode" &&
              key !== "expired_time_at"
            ) {
              let errName = utils.replaceAll(key, "_", " ");
              err[key] = `${errName} field can't be empty!`;
              kosong = true;
              return;
            }
          }

          //collaboration
          else {
            if (key !== "kode") {
              let errName = utils.replaceAll(key, "_", " ");
              err[key] = `${errName} field can't be empty!`;
              kosong = true;
              return;
            }
          }
        }
      }
    });

    if (
      file === null &&
      (signdata.link === null ||
        signdata.link === "" ||
        signdata.link === undefined)
    ) {
      err.file = `One of document file or document link can't be empty!`;
      kosong = true;
    }

    if (code) {
      if (signdata.kode.length === 0) {
        err.kode = "Field can't be empty!";
        kosong = true;
      } else if (signdata.kode.length < 6) {
        err.kode = "Minimal 6 character!";
        kosong = true;
      }
    }

    setErrorInput(err);
    setIsError(kosong);

    if (!loading && !isError && !kosong) {
      setLoading(true);
      setRefresher(false);

      const bodyForm = new FormData();
      bodyForm.append("user_name", signdata.user_name);
      bodyForm.append("user_email", signdata.user_email);
      bodyForm.append("name", signdata.name);
      bodyForm.append("category_id", signdata.category_id);
      bodyForm.append("type_id", signdata.type_id);
      bodyForm.append("description", signdata.description);
      bodyForm.append("link", signdata.link);
      bodyForm.append("file", file);
      bodyForm.append("sign_number", "#" + signdata.sign_number);

      if (code) {
        bodyForm.append("code", signdata.kode);
      }

      if (!single) {
        bodyForm.append("expired_date_at", signdata.expired_date_at);
        bodyForm.append("expired_time_at", signdata.expired_time_at);
        signers.map((item) => bodyForm.append("email[]", item.email));
      }

      let key = utils.randomAlphaNumeric();
      if (id === null || id === undefined) {
        // post new data
        // for (let [key, value] of bodyForm) {
        //   print(`${key}: ${value}`);
        // }
        postData(
          single ? "qr-sign/sign-yourself" : "qr-sign/request-signature",
          bodyForm,
          (data) => {
            setList([
              <AlertComponent
                key={key}
                type="success"
                msg="Success to submit signature!"
              />,
            ]);

            setRedirect(true);
          },
          (msg, error) => {
            print(error);
            Object.keys(error.message).forEach((keyword) => {
              key = utils.randomAlphaNumeric();
              setList([
                <AlertComponent
                  key={key}
                  type="error"
                  msg={error.message[keyword]}
                />,
              ]);
            });
          },
          () => {
            //print("Finished");
            setLoading(false);
            setRefresher(true);
          }
        );
      } else {
        // update new data
        postData(
          single ? "document/" + id + "/recreate" : "collab/" + id + "recreate",
          bodyForm,
          (data) => {
            setList([
              <AlertComponent
                key={key}
                type="success"
                msg="Success to update signature!"
              />,
            ]);

            setRedirect(true);
          },
          (msg, error) => {
            print(error);
            Object.keys(error.message).forEach((keyword) => {
              key = utils.randomAlphaNumeric();
              setList([
                <AlertComponent
                  key={key}
                  type="error"
                  msg={error.message[keyword]}
                />,
              ]);
            });
          },
          () => {
            //print("Finished");
            setLoading(false);
            setRefresher(true);
          }
        );
      }
    }

    print(refresher);
  };

  const addSigner = (data) => {
    if (!signers.includes(data)) setSigners([...signers, data]);
  };

  const deleteSigners = (idx) => {
    signers.splice(idx, 1);
    setSigners([...signers]);
  };

  const CustomOption = ({ data, callback }) => {
    return (
      <div
        className="flex items-center py-1 px-2 cursor-pointer hover:bg-blue-500"
        onClick={callback}
      >
        <img
          src={
            data.photo
              ? data.photo
              : "https://icon-library.com/images/windows-user-icon/windows-user-icon-14.jpg"
          }
          alt="unknown"
          className="flex-shrink-0 h-8 w-8 rounded-full"
        />
        <div>
          <span className="font-normal ml-3 block truncate text-base ">
            {data.name}
          </span>
          <span className="font-normal ml-3 block truncate text-gray-400 text-xs">
            {data.email}
          </span>
        </div>
      </div>
    );
  };

  const InputSigner = () => {
    const [focus, setFocus] = useState(false);
    const [searh_user, setSearchPeople] = useState(peoples);
    let key = utils.randomAlphaNumeric();

    const handleSearch = (e) => {
      let q = e.target.value;

      if (q.length % 3 === 0 && q.length < 10) {
        getData(
          "signers",
          { search: q },
          (data) => {
            setSearchPeople(data.data);
          },
          (msg, error) => {
            print(error);
            setList([
              <AlertComponent key={key} type="error" msg={error.message} />,
            ]);
          },
          () => {
            //print("Fetch search Finished");
          }
        );
      }
    };

    return (
      <div className="relative">
        <div className="grid signature__input">
          <label>
            Signers Email<span>*</span>
          </label>
          <input
            type="text"
            name="emails"
            onFocus={() => setFocus(true)}
            onBlur={() => {
              setTimeout(() => {
                setFocus(false);
              }, 200);
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                let user = {
                  name: "",
                  email: e.target.value,
                  photo:
                    "https://icon-library.com/images/windows-user-icon/windows-user-icon-14.jpg",
                };

                addSigner(user);
              }
            }}
            onChange={handleSearch}
            placeholder="Enter your Signer Email"
            autoComplete="off"
          />
        </div>
        {focus && (
          <div className="z-10 absolute left-0 w-full rounded-lg bg-white overflow-auto h-60">
            {searh_user.map((item) => (
              <CustomOption
                key={item.id}
                data={item}
                callback={() => {
                  addSigner(item);
                }}
              />
            ))}
          </div>
        )}
      </div>
    );
  };

  if (redirect) {
    return single ? (
      <Redirect to="/dashboard/signature" />
    ) : (
      <Redirect to="/dashboard/collaboration" />
    );
  }

  return (
    <>
      <Tour
        isOpen={single ? tour_signature : tour_collaboration}
        rounded={8}
        showCloseButton={false}
        closeWithMask={false}
        steps={single ? FormSignatureStep : FormCollaborationStep}
        disableDotsNavigation
        lastStepNextButton={
          <div
            className="btn__primary py-1 px-4 rounded-md"
            onClick={single ? handleDoneSignature : handleDoneCollaboration}
          >
            Selesai
          </div>
        }
      />
      {loading && <LoadingBarier />}
      <div className="px-4 py-2 sm:py-6 sm:px-12 relative">
        <div className="signature__header">
          <h3>New Signature</h3>
        </div>

        <div
          className="signature__form_card py-5 px-5 bg-white rounded-lg my-5"
          style={{ height: "100%" }}
        >
          <h6>Tambah data untuk mengajukan paraf ke seseorang</h6>
          <div className="signature__form">
            <div className="grid signature__input">
              <label>
                Sign Number <span>*</span>
              </label>
              <div className="w-full relative">
                <input
                  onChange={handleChange}
                  name="sign_number"
                  className="w-full"
                  type="text"
                  disabled={edit_sign}
                  value={signdata.sign_number}
                />
                <PencilIcon
                  onClick={() => setEditSign(!edit_sign)}
                  width="20px"
                  className="cursor-pointer text-blue-500 absolute right-2 top-3"
                />
              </div>
              <small className="text-red-500">{errorInput.sign_number}</small>
            </div>

            <div className="grid signature__input">
              <label>
                Name <span>*</span>
              </label>
              <div className="w-full relative">
                <input
                  type="text"
                  name="user_name"
                  value={signdata.user_name}
                  onChange={handleChange}
                  placeholder="Enter your Name"
                  autoComplete="off"
                  className={
                    errorInput.user_name === ""
                      ? "w-full"
                      : "w-full input__error"
                  }
                  disabled={edit_name}
                />
                <PencilIcon
                  data-tour="edit-field"
                  onClick={() => setEditName(!edit_name)}
                  width="20px"
                  className="cursor-pointer text-blue-500 absolute right-2 top-3"
                />
              </div>
              <small className="text-red-500">{errorInput.user_name}</small>
            </div>

            <div className="grid signature__input">
              <label>
                E-mail <span>*</span>
              </label>
              <div className="w-full relative">
                <input
                  type="text"
                  name="user_email"
                  value={signdata.user_email}
                  onChange={handleChange}
                  placeholder="Enter your Email address"
                  autoComplete="off"
                  className={
                    errorInput.user_email === ""
                      ? "w-full"
                      : "w-full input__error"
                  }
                  disabled={edit_email}
                />
                <PencilIcon
                  onClick={() => setEditEmail(!edit_email)}
                  width="20px"
                  className="cursor-pointer text-blue-500 absolute right-2 top-3"
                />
              </div>
              <small className="text-red-500">{errorInput.user_email}</small>
            </div>

            <div data-tour="form-add" className="grid signature__input">
              <label>
                Document Name <span className="red">*</span>
              </label>
              <input
                type="text"
                name="name"
                value={signdata.name}
                onChange={handleChange}
                placeholder="Enter your Document Name"
                autoComplete="off"
                className={errorInput.name === "" ? "" : "input__error"}
              />
              <small className="text-red-500">{errorInput.name}</small>
            </div>

            <div className="grid signature__input">
              <label>
                Document Category <span className="red">*</span>
              </label>
              <select
                name="category_id"
                onChange={handleChange}
                id=""
                defaultValue={signdata.category_id}
                className={errorInput.category_id === "" ? "" : "input__error"}
              >
                <option value="0" disabled>
                  Select your document category
                </option>
                {categories.map((item) => (
                  <option
                    key={item.id}
                    selected={signdata.category_id === item.id}
                    value={item.id}
                  >
                    {item.name}
                  </option>
                ))}
              </select>
              <small className="text-red-500">{errorInput.category_id}</small>
            </div>

            <div className="grid signature__input">
              <label>
                Document Type <span className="red">*</span>
              </label>
              <select
                name="type_id"
                onChange={handleChange}
                id=""
                defaultValue={signdata.category_id}
                className={errorInput.type_id === "" ? "" : "input__error"}
              >
                <option value="0" disabled>
                  Select your document type
                </option>
                {types.map((item) => (
                  <option
                    key={item.id}
                    selected={signdata.type_id === item.id}
                    value={item.id}
                  >
                    {item.name}
                  </option>
                ))}
              </select>
              <small className="text-red-500">{errorInput.type_id}</small>
            </div>

            {code && (
              <div className="grid signature__input">
                <label>
                  Document Passcode <span className="red">*</span>
                </label>
                <input
                  type="number"
                  max={6}
                  min={6}
                  name="kode"
                  value={signdata.kode}
                  onChange={handleChange}
                  placeholder="Enter your document code"
                  autoComplete="off"
                  className={
                    errorInput.kode === "" || errorInput.kode === null
                      ? ""
                      : "input__error"
                  }
                />
                <small className="text-red-500">{errorInput.kode}</small>
              </div>
            )}

            <div className="grid signature__input">
              <label>
                Description <span className="optional">(Optional)</span>
              </label>
              <textarea
                name="description"
                value={signdata.description}
                onChange={handleChange}
                cols="30"
                rows="5"
                placeholder="Enter your description"
              ></textarea>
            </div>

            <div data-tour="document-field">
              <div className="flex">
                <span
                  onClick={() => handleChangeAttachment(true)}
                  className={utils.classNames(
                    attachmentType ? "bg-blue-500" : "bg-white",
                    "rounded-sm w-8 h-8 flex justify-center items-center cursor-pointer change__attachment"
                  )}
                >
                  <svg
                    width="12"
                    height="15"
                    viewBox="0 0 12 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.805 4.74755L7.725 0.24763C7.65488 0.169938 7.56927 0.10778 7.47369 0.0651554C7.37811 0.0225307 7.27466 0.000382347 7.17 0.00013498H1.92C1.67081 -0.00283729 1.42348 0.0433042 1.19213 0.135925C0.960771 0.228545 0.749928 0.365831 0.571636 0.539941C0.393344 0.714051 0.251096 0.921576 0.153014 1.15066C0.0549316 1.37975 0.00293728 1.62592 0 1.8751V13.1249C0.00293728 13.3741 0.0549316 13.6202 0.153014 13.8493C0.251096 14.0784 0.393344 14.2859 0.571636 14.4601C0.749928 14.6342 0.960771 14.7715 1.19213 14.8641C1.42348 14.9567 1.67081 15.0028 1.92 14.9999H10.08C10.3292 15.0028 10.5765 14.9567 10.8079 14.8641C11.0392 14.7715 11.2501 14.6342 11.4284 14.4601C11.6067 14.2859 11.7489 14.0784 11.847 13.8493C11.9451 13.6202 11.9971 13.3741 12 13.1249V5.25004C11.9995 5.06417 11.93 4.8851 11.805 4.74755V4.74755ZM10.2375 5.25004H7.2825C7.12788 5.23481 6.98543 5.15947 6.88583 5.04023C6.78623 4.92099 6.73745 4.76741 6.75 4.61255V1.50011H6.8325L10.2375 5.25004ZM10.08 13.4999H1.92C1.86778 13.5029 1.81547 13.4956 1.76608 13.4784C1.71669 13.4612 1.67118 13.4344 1.63216 13.3996C1.59314 13.3647 1.56138 13.3225 1.5387 13.2754C1.51602 13.2283 1.50287 13.1771 1.5 13.1249V1.8751C1.50287 1.82287 1.51602 1.77173 1.5387 1.72459C1.56138 1.67746 1.59314 1.63526 1.63216 1.60042C1.67118 1.56559 1.71669 1.53879 1.76608 1.52158C1.81547 1.50436 1.86778 1.49707 1.92 1.50011H5.25V4.61255C5.23789 5.16501 5.44481 5.69983 5.8256 6.10028C6.20639 6.50073 6.73012 6.7343 7.2825 6.75001H10.5V13.1249C10.4971 13.1771 10.484 13.2283 10.4613 13.2754C10.4386 13.3225 10.4069 13.3647 10.3678 13.3996C10.3288 13.4344 10.2833 13.4612 10.2339 13.4784C10.1845 13.4956 10.1322 13.5029 10.08 13.4999V13.4999Z"
                      fill="#347AE2"
                    />
                  </svg>
                </span>
                <span
                  onClick={() => handleChangeAttachment(false)}
                  className={utils.classNames(
                    !attachmentType ? "bg-blue-500" : "bg-white",
                    "rounded-sm w-8 h-8 flex justify-center items-center cursor-pointer change__attachment"
                  )}
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.26582 14.7342C1.66746 15.1364 2.14463 15.4553 2.66989 15.6725C3.19515 15.8897 3.75817 16.001 4.32658 16C4.89512 16.001 5.45825 15.8897 5.98366 15.6725C6.50906 15.4552 6.98637 15.1364 7.38821 14.7342L9.83681 12.2848L8.61251 11.0606L6.1639 13.5099C5.67597 13.9957 5.01551 14.2684 4.32701 14.2684C3.63852 14.2684 2.97805 13.9957 2.49013 13.5099C2.00396 13.0223 1.73096 12.3617 1.73096 11.6731C1.73096 10.9845 2.00396 10.324 2.49013 9.83634L4.9396 7.38785L3.7153 6.16361L1.26582 8.6121C0.455225 9.42462 0 10.5255 0 11.6731C0 12.8208 0.455225 13.9217 1.26582 14.7342V14.7342ZM14.7349 7.38785C15.5451 6.57511 16 5.47436 16 4.32681C16 3.17926 15.5451 2.0785 14.7349 1.26576C13.9223 0.455203 12.8214 0 11.6737 0C10.526 0 9.42507 0.455203 8.61251 1.26576L6.1639 3.71512L7.38821 4.93936L9.83681 2.49001C10.3247 2.00428 10.9852 1.73159 11.6737 1.73159C12.3622 1.73159 13.0227 2.00428 13.5106 2.49001C13.9968 2.97769 14.2698 3.63821 14.2698 4.32681C14.2698 5.01541 13.9968 5.67593 13.5106 6.16361L11.0611 8.6121L12.2854 9.83634L14.7349 7.38785Z"
                      fill="#347AE2"
                    />
                    <path
                      d="M4.93806 12.2853L3.71289 11.0611L11.0613 3.71387L12.2856 4.93898L4.93806 12.2853Z"
                      fill="#347AE2"
                    />
                  </svg>
                </span>
              </div>
              {attachmentType ? (
                <div className="grid signature__input">
                  <label>
                    Document File <span className="optional">(Optional)</span>
                    <span className="red">**</span>
                  </label>

                  <div className="signature__upload_wrapper">
                    <div
                      className={
                        errorInput.file !== ""
                          ? "video-dotline-err"
                          : "video-dotline"
                      }
                    >
                      {file !== null && !lp ? (
                        <>
                          <img src={logo} alt="unknown" />
                          <span className="font-bold text-sm">{fileName}</span>
                          <button
                            className="px-2 py-1 upl-delete font-bold text-sm text-white bg-red-500 rounded-2xl"
                            onClick={deleteFile}
                          >
                            X
                          </button>
                        </>
                      ) : (
                        !lp && (
                          <>
                            <input
                              type="file"
                              className="video-upload"
                              onChange={getFile}
                            />
                            <img
                              src={UploadImg}
                              alt="upload"
                              className="upl-img"
                            />
                            <span>Upload your file</span>
                            {fileSize === true ? (
                              <span style={{ color: "red" }}>
                                Ukuran file video tidak boleh lebih dari 10mb
                              </span>
                            ) : null}
                          </>
                        )
                      )}
                      <div className="px-4 w-full relative">
                        <div
                          ref={loadingRef1}
                          className="progress__bar absolute bg-gray-300 rounded-md h-0"
                        >
                          <div
                            ref={loadingRef2}
                            className="progress__bar progress__move absolute bg-blue-500 rounded-md h-2"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <span className="signature__input_notes">
                    ** Max. size 10MB | Format (.pdf, .doc)
                  </span>
                  <small className="text-red-500">{errorInput.file}</small>
                </div>
              ) : (
                <div className="grid signature__input">
                  <label>
                    Document Link <span className="optional">(Optional)</span>
                  </label>
                  <input
                    type="text"
                    name="link"
                    value={signdata.link}
                    onChange={handleChange}
                    placeholder="Enter your Document Link"
                    autoComplete="off"
                    className={errorInput.file === "" ? "" : "input__error"}
                  />
                  <small className="text-red-500">{errorInput.file}</small>
                </div>
              )}
            </div>

            {!single && (
              <div
                className="grid grid-cols-2 gap-4"
                data-tour="addition-collaboration"
              >
                <div className="grid signature__input">
                  <label>
                    Due Date<span className="red">*</span>
                  </label>
                  <input
                    type="date"
                    name="expired_date_at"
                    value={signdata.expired_date_at}
                    onChange={handleChange}
                    placeholder="Select Due Date"
                    autoComplete="off"
                    className="2xl:w-96"
                  />
                  <small className="text-red-500">
                    {errorInput.expired_date_at}
                  </small>
                </div>
                <div className="grid signature__input">
                  <label>
                    Due Time<span className="red">*</span>
                  </label>
                  <input
                    type="time"
                    name="expired_time_at"
                    value={signdata.expired_time_at}
                    onChange={handleChange}
                    placeholder="Select Time"
                    autoComplete="off"
                    className="2xl:w-96"
                  />
                  <small className="text-red-500">
                    {errorInput.expired_time_at}
                  </small>
                </div>
              </div>
            )}
          </div>{" "}
        </div>
        {!single && (
          <>
            <h6 className="font-bold">Invite Signers</h6>
            <div
              data-tour="invite-collaboration"
              className="signature__form py-5 px-5 bg-white rounded-lg my-5"
              style={{ height: "100%" }}
            >
              <InputSigner />
              {signers.map((item, idx) => (
                <div
                  key={item.name}
                  className="flex justify-between items-center mb-2 py-1 px-2 cursor-pointer shadow-md rounded-lg hover:bg-gray-100"
                >
                  <div className="flex">
                    <img
                      src={
                        item.photo
                          ? item.photo
                          : "https://icon-library.com/images/windows-user-icon/windows-user-icon-14.jpg"
                      }
                      alt="unknown"
                      className="flex-shrink-0 h-8 w-8 rounded-full"
                    />
                    <div>
                      <span className="font-normal ml-3 block truncate text-base ">
                        {item.name}
                      </span>
                      <span className="font-normal ml-3 block truncate text-gray-400 text-xs">
                        {item.email}
                      </span>
                    </div>
                  </div>
                  <span onClick={() => deleteSigners(idx)}>
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14 14L8 8M14 8L8 14"
                        stroke="#FF3B3B"
                        strokeWidth="1.33333"
                        strokeLinecap="round"
                      />
                      <path
                        d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
                        stroke="#FF3B3B"
                        strokeWidth="1.33333"
                      />
                    </svg>
                  </span>
                </div>
              ))}
            </div>
          </>
        )}

        <div className="signature__form_submit">
          <Link to="/dashboard/signature">
            <button className="back">Back</button>
          </Link>
          <button className="save" onClick={handleAddSignature}>
            Save
          </button>
        </div>
      </div>
    </>
  );
};

export default NewSignature;
