import React, { useState } from "react";
import "./auth.css";
import ornL from "../../assets/ornament/auth-left.svg";
import ornR from "../../assets/ornament/auth-right.svg";
import logo from "../../assets/images/logo-thumb.svg";
import { postData } from "../../utility/network";
import utils, { print } from "../../utility/utils";
import { AlertComponent } from "../../components/alerts/alert-component";
import { useCustomAlert } from "../../components/alerts/alert-provider";
import { LoadingBarier } from "../../components/loading-barrier";
import { EyeIcon, EyeOffIcon } from "@heroicons/react/solid";
import { Redirect, useLocation } from "react-router";

function ForgotPassword(props) {
  const location = useLocation();
  const [setList] = useCustomAlert();
  const [confirm_password, setConfirmPassword] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({ confirm_password: "", password: "" });
  const [state_password, setStatePassword] = useState(false);
  const [state_confirm, setStateConfirm] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleChangePassword = () => {
    let abortController = new AbortController();
    setLoading(true);

    let kosong = false;
    let err = {
      email: "",
      password: "",
    };
    setErrors(err);

    // password validation
    if (password.length === 0) {
      err.password = `Password field can't be empty!`;
      kosong = true;
      setLoading(false);
    }
    if (password.length < 6) {
      err.password = `Minimal 6 character!`;
      kosong = true;
      setLoading(false);
    }

    // password validation
    if (confirm_password.length === 0) {
      err.password = `Confirm password field can't be empty!`;
      kosong = true;
      setLoading(false);
    }
    if (confirm_password.length < 6) {
      err.password = `Minimal 6 character!`;
      kosong = true;
      setLoading(false);
    }

    setErrors(err);

    if (!loading && !kosong) {
      const body = {
        email: location.state.email,
        password: password,
        password_confirmation: confirm_password,
      };

      let key = utils.randomAlphaNumeric();

      postData(
        "reset-password",
        body,
        (data) => {
          setList([
            <AlertComponent key={key} type="success" msg={data.message} />,
          ]);
          setLoading(false);
          setSuccess(true);
        },
        (msg, errors) => {
          print(errors);
          Object.keys(errors.message).forEach((keyword) => {
            key = utils.randomAlphaNumeric();
            setList([
              <AlertComponent
                key={key}
                type="error"
                msg={errors.message[keyword]}
              />,
            ]);
          });
        },
        () => {
          // you can put loading here
          setLoading(false);
          abortController.abort();
          //print("ON FINISHED");
        }
      );
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.currentTarget;

    if ("confirm_password" === name) {
      setConfirmPassword(value);
    }
    if ("password" === name) {
      setPassword(value);
    }
  };

  if (success) {
    return <Redirect to="/login" />;
  }

  return (
    <>
      {loading && <LoadingBarier />}
      <div className="auth__wrapper">
        <img src={ornL} alt="unknown" className="auth__ornL" />
        <img src={ornR} alt="unknown" className="auth__ornR" />

        <div className="auth__form">
          <img src={logo} alt="unknown" />
          <h2 className="text-xl font-bold">Reset Password</h2>
          <p>Reset your password to recovery & login to your account.</p>

          <div className="auth__input">
            <div className="relative flex w-full flex-wrap items-stretch mb-3">
              <span className="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  style={{ opacity: "0.3" }}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                  />
                </svg>
              </span>
              <input
                autoComplete="off"
                type={!state_password ? "password" : "text"}
                placeholder="Password"
                name="password"
                onChange={handleChange}
                className="px-3 py-3 w-full pl-12"
              />
              <div
                className="absolute top-3 right-2 cursor-pointer"
                onClick={() => setStatePassword(!state_password)}
              >
                {!state_password ? (
                  <EyeIcon
                    className="text-gray-400"
                    width="20px"
                    height="20px"
                  />
                ) : (
                  <EyeOffIcon
                    className="text-gray-400"
                    width="20px"
                    height="20px"
                  />
                )}
              </div>
              <small className="text-red-500">{errors.password}</small>
            </div>

            <div className="relative flex w-full flex-wrap items-stretch mb-3">
              <span className="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  style={{ opacity: "0.3" }}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                  />
                </svg>
              </span>
              <input
                type={!state_confirm ? "password" : "text"}
                placeholder="Confirm Password"
                name="confirm_password"
                onChange={handleChange}
                className="px-3 py-3 w-full pl-12"
              />
              <div
                className="absolute top-3 right-2 cursor-pointer"
                onClick={() => setStateConfirm(!state_confirm)}
              >
                {!state_confirm ? (
                  <EyeIcon
                    className="text-gray-400"
                    width="20px"
                    height="20px"
                  />
                ) : (
                  <EyeOffIcon
                    className="text-gray-400"
                    width="20px"
                    height="20px"
                  />
                )}
              </div>
              <small className="text-red-500">{errors.confirm_password}</small>
            </div>
          </div>

          <div className="auth__submit">
            <button onClick={handleChangePassword} className="login__btn">
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;
