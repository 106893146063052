import { Disclosure } from "@headlessui/react";
import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/outline";

export const FaqSection = () => {
  return (
    <div className="pb-4">
      <p className="text-2xl text-black-900 font-extrabold">Frequently Asked</p>
      <div className="w-full sm:pt-5">
        <div className="w-full p-2 mx-auto bg-white rounded-2xl">
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full items-center px-4 py-2 text-base font-medium text-left text-black-800">
                  {open ? (
                    <MinusCircleIcon className="w-6 h-6 sm:w-8 sm:h-8 text-blue-500" />
                  ) : (
                    <PlusCircleIcon className="w-6 h-6 sm:w-8 sm:h-8 text-blue-500" />
                  )}
                  <div className="ml-2 sm:ml-4 sm:text-base text-sm">What is your refund policy?</div>
                </Disclosure.Button>
                <Disclosure.Panel className="px-4 pt-2 sm:pt-4 pb-2 text-sm sm:text-base text-gray-500">
                  If you're unhappy with your purchase for any reason, email us
                  within 90 days and we'll refund you in full, no questions
                  asked.
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full items-center px-4 py-2 text-base font-medium text-left text-black-800">
                  {open ? (
                    <MinusCircleIcon className="w-6 h-6 sm:w-8 sm:h-8 text-blue-500" />
                  ) : (
                    <PlusCircleIcon className="w-6 h-6 sm:w-8 sm:h-8 text-blue-500" />
                  )}
                  <div className="ml-2 sm:ml-4 sm:text-base text-sm">What is your refund policy?</div>
                </Disclosure.Button>
                <Disclosure.Panel className="px-4 pt-4 pb-2 text-base text-gray-500">
                  If you're unhappy with your purchase for any reason, email us
                  within 90 days and we'll refund you in full, no questions
                  asked.
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full items-center px-4 py-2 text-base font-medium text-left text-black-800">
                  {open ? (
                    <MinusCircleIcon className="w-6 h-6 sm:w-8 sm:h-8 text-blue-500" />
                  ) : (
                    <PlusCircleIcon className="w-6 h-6 sm:w-8 sm:h-8 text-blue-500" />
                  )}
                  <div className="ml-2 sm:ml-4 sm:text-base text-sm">What is your refund policy?</div>
                </Disclosure.Button>
                <Disclosure.Panel className="px-4 pt-4 pb-2 text-base text-gray-500">
                  If you're unhappy with your purchase for any reason, email us
                  within 90 days and we'll refund you in full, no questions
                  asked.
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full items-center px-4 py-2 text-base font-medium text-left text-black-800">
                  {open ? (
                    <MinusCircleIcon className="w-6 h-6 sm:w-8 sm:h-8 text-blue-500" />
                  ) : (
                    <PlusCircleIcon className="w-6 h-6 sm:w-8 sm:h-8 text-blue-500" />
                  )}
                  <div className="ml-2 sm:ml-4 sm:text-base text-sm">What is your refund policy?</div>
                </Disclosure.Button>
                <Disclosure.Panel className="px-4 pt-4 pb-2 text-base text-gray-500">
                  If you're unhappy with your purchase for any reason, email us
                  within 90 days and we'll refund you in full, no questions
                  asked.
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full items-center px-4 py-2 text-base font-medium text-left text-black-800">
                  {open ? (
                    <MinusCircleIcon className="w-6 h-6 sm:w-8 sm:h-8 text-blue-500" />
                  ) : (
                    <PlusCircleIcon className="w-6 h-6 sm:w-8 sm:h-8 text-blue-500" />
                  )}
                  <div className="ml-2 sm:ml-4 sm:text-base text-sm">What is your refund policy?</div>
                </Disclosure.Button>
                <Disclosure.Panel className="px-4 pt-4 pb-2 text-base text-gray-500">
                  If you're unhappy with your purchase for any reason, email us
                  within 90 days and we'll refund you in full, no questions
                  asked.
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>
      </div>
    </div>
  );
};
