import React from "react";
import { Alerts } from "./alerts";

const CustomAlertContext = React.createContext(null);

function useCustomAlert() {
  const context = React.useContext(CustomAlertContext);
  return context;
}

function CustomAlertProvider(props) {
  const [custom_alert, setCustomAlert] = React.useState([]);

  return (
    <>
      <CustomAlertContext.Provider value={[setCustomAlert]} {...props} />
      <Alerts toastList={custom_alert} />
    </>
  );
}

export { useCustomAlert, CustomAlertProvider };
