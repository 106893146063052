import { useRef, useState } from "react";
import ornamen_banner from "../../assets/images/ornamen_banner.png";
import { ModalDeck } from "../modal/modal-deck";

const BannerDashboard = () => {
  const [openDeck, setOpenDeck] = useState(false);
  const cancelDeckRef = useRef(null);

  return (
    <>
      <ModalDeck
        open={openDeck}
        setOpen={setOpenDeck}
        cancelButtonRef={cancelDeckRef}
      />
      <div className="container banner__dashboard p-5 sm:p-10 relative">
        <div className="text-2xl sm:text-4xl font-extrabold">
          Professional signature Made Easy.
        </div>
        <div className="text-sm sm:text-base font-light text__color_gray_1 mt-2">
          Get a quick, easy, and secure way to send, sign, and approve
          documents. <br />
          Support all levels of your organization and get documents signed
          faster and more efficiently.
        </div>
        <div className="mt-10">
          <span
            onClick={() => setOpenDeck(!openDeck)}
            className="btn cursor-pointer btn__primary font-semibold rounded-md shadow-sm px-6 py-1 sm:py-3"
          >
            Tutorial
          </span>
        </div>
        <img
          className="absolute right-0 top-0 h-full hidden sm:block"
          src={ornamen_banner}
          alt="unknown"
        />
      </div>
    </>
  );
};

export default BannerDashboard;
