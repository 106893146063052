export const loginAction = (data) => {
  // console.log(data)
  localStorage.setItem("token", JSON.stringify(data));
  return {
    type: "LOGIN_SUCCESS",
    payload: data,
  };
};

export const logoutAction = () => {
  localStorage.removeItem("token");
  return {
    type: "LOGOUT",
  };
};
