import { ParagraphText } from "./paragraph";
import { ParagraphTitleText } from "./paragraph-title";

export const PrivacyPolicy = () => {
  return (
    <div className="pb-4">
      <p className="text-2xl text-black-900 font-extrabold">Privacy Policy</p>
      <p className="mt-2 text-base text-black-900 font-normal">
        Last updated May 24, 2021
      </p>
      <ParagraphText text='Thank you for choosing to be part of our community at PT Yokesen Teknologi Indonesia , doing business as teken (" teken", " we", "us", "our"). We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about this privacy notice, or our practices with regards to your personal information, please contact us at contact@yokesen.com.' />
      <ParagraphText
        text='
        When you visit our website http://teken.yokesen.com (the "Website"), use our mobile application, as the case may be (the "App") and more generally, use any of our services (the "Services", which include theÂ  Website and App), we appreciate that you are trusting us with your personal information. We take your privacy very seriously. In this privacy notice, we seek to explain to you in the clearest way possible what information we collect, how we use it and what rights you have in relation to it. We hope you take some time to read through it carefully, as it is important. If there are any terms in this privacy notice that you do not agree with, please discontinue use of our Services immediately.
        '
      />
      <ParagraphText text="This privacy notice applies to all information collected through our Services (which, as described above, includes our  Website and App ), as well as, any related services, sales, marketing or events." />
      <ParagraphText
        text="
        Please read this privacy notice carefully as it will help you understand what we do with the information that we collect."
      />
      <ParagraphTitleText text="TABLE OF CONTENTS" />
      <ol className="text-base text-black-900 font-normal list-inside list-decimal">
        <li>WHAT INFORMATION DO WE COLLECT?</li>
        <li>HOW DO WE USE YOUR INFORMATION?</li>
        <li>WILL YOUR INFORMATION BE SHARED WITH ANYONE?</li>
        <li>HOW DO WE HANDLE YOUR SOCIAL LOGINS?</li>
        <li>HOW LONG DO WE KEEP YOUR INFORMATION?</li>
        <li>HOW DO WE KEEP YOUR INFORMATION SAFE?</li>
        <li>DO WE COLLECT INFORMATION FROM MINORS?</li>
        <li>WHAT ARE YOUR PRIVACY RIGHTS?</li>
        <li>CONTROLS FOR DO-NOT-TRACK FEATURES</li>
        <li>DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</li>
        <li>DO WE MAKE UPDATES TO THIS NOTICE?</li>
        <li>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</li>
      </ol>
      <ParagraphTitleText text="1. WHAT INFORMATION DO WE COLLECT?" />
      <ParagraphText text="Personal information you disclose to us" />
      <ParagraphText text="In Short: We collect personal information that you provide to us." />
      <ParagraphText text="We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services (such as by posting messages in our online forums or entering competitions, contests or giveaways) or otherwise when you contact us." />
      <ParagraphText text="The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make and the products and features you use. The personal information we collect may include the following:" />
      <ParagraphText text="Personal Information Provided by You. We collect names; phone numbers; email addresses; contact or authentication data; and other similar information." />
      <ParagraphText text="Payment Data. We may collect data necessary to process your payment if you make purchases, such as your payment instrument number (such as a credit card number), and the security code associated with your payment instrument. All payment data is stored by PayPal . You may find their privacy notice link(s) here: https://www.paypal.com/id." />
      <ParagraphText text='Social Media Login Data. We may provide you with the option to register with us using your existing social media account details, like your Facebook, Twitter or other social media account. If you choose to register in this way, we will collect the information described in the section called "HOW DO WE HANDLE YOUR SOCIAL LOGINS?" below.' />
      <ParagraphText text="All personal information that you provide to us must be true, complete and accurate, and you must notify us of any changes to such personal information." />
      <ParagraphText text="Information collected through our App" />
      <ParagraphText text="In Short: We collect information regarding your mobile device, push notifications, when you use our App." />
      <ParagraphText text="If you use our App, we also collect the following information:" />
      <ul className="text-base text-black-900 font-normal list-inside list-disc">
        <li>
          Mobile Device Access. We may request access or permission to certain
          features from your mobile device, including your mobile device's
          calendar, camera, reminders, and other features. If you wish to change
          our access or permissions, you may do so in your device's settings.
        </li>
        <li>
          Mobile Device Data. We automatically collect device information (such
          as your mobile device ID, model and manufacturer), operating system,
          version information and system configuration information, device and
          application identification numbers, browser type and version, hardware
          model Internet service provider and/or mobile carrier, and Internet
          Protocol (IP) address (or proxy server). If you are using our App, we
          may also collect information about the phone network associated with
          your mobile device, your mobile device’s operating system or platform,
          the type of mobile device you use, your mobile device’s unique device
          ID and information about the features of our App you accessed.
        </li>
        <li>
          Push Notifications. We may request to send you push notifications
          regarding your account or certain features of the App. If you wish to
          opt-out from receiving these types of communications, you may turn
          them off in your device's settings.
        </li>
      </ul>
      <ParagraphText text="This information is primarily needed to maintain the security and operation of our App, for troubleshooting and for our internal analytics and reporting purposes." />
      <ParagraphTitleText text="2. HOW DO WE USE YOUR INFORMATION?" />
      <ParagraphText text="In Short:  We process your information for purposes based on legitimate business interests, the fulfillment of our contract with you, compliance with our legal obligations, and/or your consent." />
      <ParagraphText text="We use personal information collected via our Services for a variety of business purposes described below. We process your personal information for these purposes in reliance on our legitimate business interests, in order to enter into or perform a contract with you, with your consent, and/or for compliance with our legal obligations. We indicate the specific processing grounds we rely on next to each purpose listed below." />
      <ParagraphText text="We use the information we collect or receive:" />
      <ol className="text-base text-black-900 font-normal list-inside list-disc">
        <li>
          To facilitate account creation and logon process. If you choose to
          link your account with us to a third-party account (such as your
          Google or Facebook account), we use the information you allowed us to
          collect from those third parties to facilitate account creation and
          logon process for the performance of the contract. See the section
          below headed "HOW DO WE HANDLE YOUR SOCIAL LOGINS?" for further
          information.
        </li>
        <li>
          To post testimonials. We post testimonials on our Services that may
          contain personal information. Prior to posting a testimonial, we will
          obtain your consent to use your name and the content of the
          testimonial. If you wish to update, or delete your testimonial, please
          contact us at contact@yokesen.com and be sure to include your name,
          testimonial location, and contact information.
        </li>
        <li>
          Request feedback. We may use your information to request feedback and
          to contact you about your use of our Services.
        </li>
        <li>
          To enable user-to-user communications. We may use your information in
          order to enable user-to-user communications with each user's consent.
        </li>
        <li>
          To manage user accounts. We may use your information for the purposes
          of managing our account and keeping it in working order.
        </li>
        <li>
          For other business purposes. We may use your information for other
          business purposes, such as data analysis, identifying usage trends,
          determining the effectiveness of our promotional campaigns and to
          evaluate and improve our Services, products, marketing and your
          experience. We may use and store this information in aggregated and
          anonymized form so that it is not associated with individual end users
          and does not include personal information. We will not use
          identifiable personal information without your consent.
        </li>
      </ol>
      <ParagraphTitleText text="3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?" />
      <ParagraphText text="In Short: We only share information with your consent, to comply with laws, to provide you with services, to protect your rights, or to fulfill business obligations." />
      <ParagraphTitleText text="We may process or share your data that we hold based on the following legal basis:" />
      <ol className="text-base text-black-900 font-normal list-inside list-disc">
        <li>
          Consent: We may process your data if you have given us specific
          consent to use your personal information for a specific purpose.
        </li>
        <li>
          Legitimate Interests: We may process your data when it is reasonably
          necessary to achieve our legitimate business interests.
        </li>
        <li>
          Performance of a Contract: Where we have entered into a contract with
          you, we may process your personal information to fulfill the terms of
          our contract.
        </li>
        <li>
          Legal Obligations: We may disclose your information where we are
          legally required to do so in order to comply with applicable law,
          governmental requests, a judicial proceeding, court order, or legal
          process, such as in response to a court order or a subpoena (including
          in response to public authorities to meet national security or law
          enforcement requirements).
        </li>
        <li>
          Vital Interests: We may disclose your information where we believe it
          is necessary to investigate, prevent, or take action regarding
          potential violations of our policies, suspected fraud, situations
          involving potential threats to the safety of any person and illegal
          activities, or as evidence in litigation in which we are involved.
        </li>
      </ol>
      <ParagraphText text="More specifically, we may need to process your data or share your personal information in the following situations:" />
      <ParagraphText text="- Business Transfers. We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company." />
      <ParagraphTitleText text="4. HOW DO WE HANDLE YOUR SOCIAL LOGINS? " />
      <ParagraphText text="In Short: If you choose to register or log in to our services using a social media account, we may have access to certain information about you." />
      <ParagraphText text="Our Services offers you the ability to register and login using your third-party social media account details (like your Facebook or Twitter logins). Where you choose to do this, we will receive certain profile information about you from your social media provider. The profile information we receive may vary depending on the social media provider concerned, but will often include your name, email address, friends list, profile picture as well as other information you choose to make public on such social media platform. " />
      <ParagraphText text="We will use the information we receive only for the purposes that are described in this privacy notice or that are otherwise made clear to you on the relevant Services. Please note that we do not control, and are not responsible for, other uses of your personal information by your third-party social media provider. We recommend that you review their privacy notice to understand how they collect, use and share your personal information, and how you can set your privacy preferences on their sites and apps.We will use the information we receive only for the purposes that are described in this privacy notice or that are otherwise made clear to you on the relevant Services. Please note that we do not control, and are not responsible for, other uses of your personal information by your third-party social media provider. We recommend that you review their privacy notice to understand how they collect, use and share your personal information, and how you can set your privacy preferences on their sites and apps." />
      <ParagraphTitleText text="5. HOW LONG DO WE KEEP YOUR INFORMATION?" />
      <ParagraphText text="In Short: We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law." />
      <ParagraphText text="We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than the period of time in which users have an account with us." />
      <ParagraphText text="When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible." />
      <ParagraphTitleText text="6. HOW DO WE KEEP YOUR INFORMATION SAFE?" />
      <ParagraphText text="In Short: We aim to protect your personal information through a system of organizational and technical security measures." />
      <ParagraphText text="We have implemented appropriate technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security, and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment." />
      <ParagraphTitleText text="7. DO WE COLLECT INFORMATION FROM MINORS?" />
      <ParagraphText text="In Short:  We do not knowingly collect data from or market to children under 18 years of age." />
      <ParagraphText text="We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at contact@yokesen.com." />
      <ParagraphTitleText text="8. WHAT ARE YOUR PRIVACY RIGHTS?" />
      <ParagraphText text="In Short:  You may review, change, or terminate your account at any time." />
      <ParagraphText text="If you are a resident in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here: http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.html." />
      <ParagraphText text="If you are a resident in Switzerland, the contact details for the data protection authorities are available here: https://www.edoeb.admin.ch/edoeb/en/home.html." />
      <ParagraphText text="If you have questions or comments about your privacy rights, you may email us at contact@yokesen.com." />
      <ParagraphText text="Account Information" />
      <ParagraphText text="If you would at any time like to review or change the information in your account or terminate your account, you can:" />
      <ParagraphText text="- Log in to your account settings and update your user account." />
      <ParagraphText text="Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use and/or comply with applicable legal requirements." />
      <ParagraphText text="Opting out of email marketing: You can unsubscribe from our marketing email list at any time by clicking on the unsubscribe link in the emails that we send or by contacting us using the details provided below. You will then be removed from the marketing email list â€” however, we may still communicate with you, for example to send you service-related emails that are necessary for the administration and use of your account, to respond to service requests, or for other non-marketing purposes. To otherwise opt-out, you may:" />
      <ParagraphText text="- Access your account settings and update your preferences." />
      <ParagraphTitleText text="9. CONTROLS FOR DO-NOT-TRACK FEATURES" />
      <ParagraphText text='Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.' />
      <ParagraphTitleText text="10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?" />
      <ParagraphText text="In Short: Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information." />
      <ParagraphText text='California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.' />
      <ParagraphText text="If you are under 18 years of age, reside in California, and have a registered account with a Service, you have the right to request removal of unwanted data that you publicly post on the Services. To request removal of such data, please contact us using the contact information provided below, and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the Services, but please be aware that the data may not be completely or comprehensively removed from all our systems (e.g. backups, etc.)." />
      <ParagraphTitleText text="11. DO WE MAKE UPDATES TO THIS NOTICE?" />
      <ParagraphText text="In Short:  Yes, we will update this notice as necessary to stay compliant with relevant laws." />
      <ParagraphText text='We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.' />
      <ParagraphTitleText text="12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE? " />
      <ParagraphText text="If you have questions or comments about this notice, you may email us at contact@yokesen.com or by post to:" />
      <ParagraphText
        text={
          <>
            <strong>Yokesen Teknologi Indonesia</strong> <br />
            Ruko Crystal 8 no 18, Alam Sutera, Pakualam, North Serpong, South
            Tangerang City, Banten 15320 <br />
            Tangerang, West Java 15320 <br />
            Indonesia <br />
            Phone: (+62)811-1618-818 <br />
            contact@yokesen.com
          </>
        }
      />
      <ParagraphTitleText text="13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?" />
      <ParagraphText text="Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it in some circumstances. To request to review, update, or delete your personal information, please inform us via contact@yokesen.com." />
    </div>
  );
};
