const INITIAL_STATE = {
    name: "",
    token:"",
    login: false,
    error:false
  };

const Auth = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "LOGIN_SUCCESS":
            return {
                ...state,
                ...action.payload,
                login:true,
            }
        case "LOGIN_FAILED":
            return {
                ...state,
                error:true,
            }
        case "LOGOUT":
            return INITIAL_STATE
        default:
            return state
    }
}

export default Auth;