import { useEffect, useRef, useState } from "react";
import { getData, postData, putData } from "../../utility/network";
import utils, { print } from "../../utility/utils";
import edit from "../../assets/images/edit_img.png";
import { useCustomAlert } from "../../components/alerts/alert-provider";
import { AlertComponent } from "../../components/alerts/alert-component";
import { useCustomRefresh } from "../../utility/refresh-provider";
import { VerifyBanner } from "./verify-banner";
// import { VerifyBanner } from "./verify-banner";

const ChangeProfile = () => {
  const [profile, setProfile] = useState({});
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const inputFile = useRef(null);
  const [setList] = useCustomAlert();
  //reload data trigger by the child
  const [refresher, setRefresher] = useCustomRefresh();
  const [readonly, setReadonly] = useState(true);
  const innerWidth = window.innerWidth;

  useEffect(() => {
    // get user data
    let key = utils.randomAlphaNumeric();
    getData(
      "user/my-information",
      {},
      (data) => {
        setProfile(data.data);
        setEmail(data.data.email);
        setName(data.data.name);
      },
      (msg, error) => {
        print(error);
        setList([
          <AlertComponent key={key} type="error" msg={error.message} />,
        ]);
      },
      () => {
        // print("Fetch Profile Finished");
      }
    );
  }, [setList]);

  // handle when user input on form
  const handleChange = (e) => {
    const { name, value } = e.currentTarget;

    if (utils.checkEmptyValue(value)) {
      //print(`${name} KOSONG`);
    } else {
      // parsing the input that user has filled
      // and update into the `profile` state
      const newProfile = utils.parseObject(profile, name, value);
      setProfile(newProfile);
    }
  };

  // handle get user
  const getUser = () => {
    let key = utils.randomAlphaNumeric();
    getData(
      "user/my-information",
      {},
      (data) => {
        setProfile(data.data);
        setEmail(data.data.email);
        setName(data.data.name);
      },
      (msg, error) => {
        print(error);
        setList([
          <AlertComponent key={key} type="error" msg={error.message} />,
        ]);
      },
      () => {
        //print("Fetch Profile Finished");
      }
    );
  };

  // update profile function
  const updateProfile = () => {
    // handle when click the button for many times
    if (!loading) {
      setRefresher(false);
      setLoading(true);
      let key = utils.randomAlphaNumeric();

      let updated = {
        name: profile.name,
        email: profile.email,
        phone: profile.phone,
        initial: profile.initial,
        company: profile.company,
      };

      putData(
        "user/my-information",
        updated,
        (data) => {
          if (data.status === "Success") {
            setList([
              <AlertComponent
                key={key}
                type="success"
                msg="Success to update profile!"
              />,
            ]);
            setRefresher(true);
            setReadonly(true);
          }
        },
        (msg, error) => {
          print(error);
          Object.keys(error.message).forEach((keyword) => {
            key = utils.randomAlphaNumeric();
            setList([
              <AlertComponent
                key={key}
                type="error"
                msg={error.message[keyword]}
              />,
            ]);
          });
        },
        () => {
          setLoading(false);
          //print("Finished");
          print(refresher);
        }
      );
    }
  };

  // on click icon get file
  const addImage = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  const uploadImage = (event) => {
    if (!loading) {
      setRefresher(false);
      setLoading(true);
      event.stopPropagation();
      event.preventDefault();
      var file = event.target.files[0];

      const bodyForm = new FormData();
      bodyForm.append("photo", file);

      let key = utils.randomAlphaNumeric();

      postData(
        "user/upload-photo",
        bodyForm,
        (data) => {
          setList([
            <AlertComponent
              key={key}
              type="success"
              msg={"Picture has been changed!"}
            />,
          ]);
        },
        (msg, error) => {
          print(error);
          Object.keys(error.message).forEach((keyword) => {
            key = utils.randomAlphaNumeric();
            setList([
              <AlertComponent
                key={key}
                type="error"
                msg={error.message[keyword]}
              />,
            ]);
          });
        },
        () => {
          setLoading(false);
          setRefresher(true);
          //print("Finished");
          getUser();
        }
      );
    }
  };

  const ActionButton = () => {
    return (
      <>
        {readonly ? (
          <div
            className={
              innerWidth > 768
                ? "absolute flex right-0"
                : "my-3 flex justify-center"
            }
          >
            <button
              onClick={() => {
                setReadonly(false);
              }}
              className="btn__primary rounded-md flex px-4 py-1 items-center"
            >
              Edit
            </button>
          </div>
        ) : (
          <div
            className={
              innerWidth > 768
                ? "absolute flex right-0"
                : "my-3 flex justify-center"
            }
          >
            <button
              onClick={() => {
                setReadonly(true);
              }}
              className="btn__danger rounded-md flex px-4 py-1 items-center mr-2"
            >
              <span className="text-sm font-bold">Cancel</span>
            </button>
            <button
              onClick={updateProfile}
              className="btn__primary rounded-md flex px-4 py-1 items-center"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H16L21 8V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21Z"
                  stroke="white"
                  strokeWidth="1.3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M17 21V13H7V21"
                  stroke="white"
                  strokeWidth="1.3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7 3V8H15"
                  stroke="white"
                  strokeWidth="1.3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span className="ml-2 text-sm font-bold">Save</span>
            </button>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      {!profile.is_email_verified && <VerifyBanner />}
      <div className="relative">
        {innerWidth > 768 && <ActionButton />}

        <div className="flex items-center">
          <div className="relative">
            <img
              className="h-16 w-16 sm:h-28 sm:w-28 rounded-full object-cover"
              src={
                profile.photo
                  ? profile.photo
                  : "https://icon-library.com/images/windows-user-icon/windows-user-icon-14.jpg"
              }
              alt="unknown"
            />

            <img
              onClick={addImage}
              className="h-5 w-5 sm:w-8 sm:h-8 rounded-full absolute bottom-0 right-0 cursor-pointer"
              src={edit}
              alt="unknown"
            />
            <input
              onChange={uploadImage}
              type="file"
              id="file"
              ref={inputFile}
              hidden
            />
          </div>
          <div className="ml-4">
            <div className="text-base sm:text-xl font-extrabold">{name}</div>
            <div className="text-sm sm:text-base text-gray-500 sm:mt-1">
              {email}
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-x-10 gap-y-6 mt-6 sm:mt-10 pb-4">
          <div>
            <label
              htmlFor="price"
              className="block text-sm sm:text-base font-normal text-black-400"
            >
              Name
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <input
                readOnly={readonly}
                type="text"
                name="name"
                onChange={handleChange}
                defaultValue={profile.name}
                className="block w-full py-2 px-2 sm:py-2 sm:px-4 font-bold text-xs sm:text-base input__profile"
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="price"
              className="block text-sm sm:text-base font-normal text-black-400"
            >
              Initial
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <input
                readOnly={readonly}
                type="text"
                name="initial"
                onChange={handleChange}
                defaultValue={profile.initial ?? ""}
                className="block w-full py-2 px-2 sm:py-2 sm:px-4 font-bold text-xs sm:text-base input__profile"
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="price"
              className="block text-sm sm:text-base font-normal text-black-400"
            >
              Email
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <input
                readOnly={readonly}
                type="text"
                name="email"
                onChange={handleChange}
                defaultValue={profile.email ?? ""}
                className="block w-full py-2 px-2 sm:py-2 sm:px-4 font-bold text-xs sm:text-base input__profile"
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="price"
              className="block text-sm sm:text-base font-normal text-black-400"
            >
              Company
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <input
                readOnly={readonly}
                type="text"
                name="company"
                onChange={handleChange}
                defaultValue={profile.company ?? ""}
                className="block w-full py-2 px-2 sm:py-2 sm:px-4 font-bold text-xs sm:text-base input__profile"
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="price"
              className="block text-sm sm:text-base font-normal text-black-400"
            >
              Phone Number
            </label>
            <div className="mt-1 relative flex items-center">
              <div className="font-bold text-xs sm:text-base absolute pl-2 sm:pl-4">
                +62
              </div>
              <input
                readOnly={readonly}
                type="tel"
                name="phone"
                onChange={handleChange}
                defaultValue={profile.phone ?? ""}
                className="shadow-sm flex-grow h-8 sm:h-12 w-full px-2 sm:px-4 pl-8 sm:pl-12 font-bold text-xs sm:text-base input__profile"
              />
            </div>
          </div>
        </div>

        {innerWidth <= 768 && <ActionButton />}
      </div>
    </>
  );
};

export default ChangeProfile;
