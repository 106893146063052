export const FormSignatureStep = [
  {
    selector: '[data-tour="form-add"]',
    content: "Ini adalah form untuk pembuatan signature",
  },
  {
    selector: '[data-tour="edit-field"]',
    content:
      "Untuk beberapa input, ada yang otomatis terisi. Klik tombol berikut jika anda ingin menggantinya",
  },
  {
    selector: '[data-tour="document-field"]',
    content:
      "Untuk document bersifat wajib salah satu. Bisa memilih upload dokumen via FILE atau LINK, silahkan isi salah satu saja",
  },
];
