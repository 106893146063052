const INITIAL_STATE = {
  req_counter: 0,
};

const RequestCounter = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "REQUEST_COUNTER_SUCCESS":
      return {
        ...state,
        ...action.payload,
      };
    case "REQUEST_COUNTER_FAILED":
      return {
        ...state,
        req_counter: 0,
      };
    case "REQUEST_COUNTER_RESET":
      return INITIAL_STATE;
    default:
      return {
        ...state,
        ...action.payload,
      };
  }
};

export default RequestCounter;
