import { useEffect, useState } from "react";
import { getData, postData } from "../../utility/network";
import utils, { print } from "../../utility/utils";
import { AlertComponent } from "../alerts/alert-component";
import { useCustomAlert } from "../alerts/alert-provider";
import { LoadingBarier } from "../loading-barrier";

export const ContactSupport = () => {
  const [user, setUser] = useState({});
  const [keluhan, setKeluhan] = useState("");
  const [loading, setLoading] = useState(false);
  const [setList] = useCustomAlert();
  const [errorInput, setErrorInput] = useState({ keluhan: "" });

  useEffect(() => {
    const getUser = () => {
      getData(
        "user/my-information",
        {},
        (data) => {
          setUser(data.data);
        },
        (msg, error) => {
          print(error);
        },
        () => {
          //print("Fetch User for Contact Support");
        }
      );
    };

    getUser();
  }, []);

  const handleSupport = () => {
    let key = utils.randomAlphaNumeric();
    let kosong = false;
    setErrorInput({ keluhan: "" });

    if (keluhan.length === 0) {
      kosong = true;
      setErrorInput({ keluhan: "Field can't be empty!" });
    }

    if (!loading && !kosong) {
      setLoading(true);

      postData(
        "contact-support",
        { name: user.name, email: user.email, message: keluhan },
        (data) => {
          setKeluhan("");
          setList([
            <AlertComponent
              key={key}
              type="success"
              msg="Your message has been sent!"
            />,
          ]);
        },
        (msg, error) => {
          print(error);
          setList([
            <AlertComponent key={key} type="error" msg={error.message} />,
          ]);
          setKeluhan(keluhan);
        },
        () => {
          //print("Finish");
          setLoading(false);
        }
      );
    }
  };

  return (
    <>
      {loading && <LoadingBarier />}
      <div className="pb-4">
        <p className="text-2xl text-black-900 font-extrabold">
          Contact Support
        </p>
        <div>
          <div className="mt-4 sm:mt-7">
            <div className="bg-blue-50 rounded-2xl sm:overflow-hidden">
              <div className="px-4 py-5 space-y-6 sm:p-6">
                <div className="sm:mt-6">
                  <label className="block text-sm font-medium text-gray-700">
                    Name
                  </label>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <input
                      type="text"
                      name="name"
                      value={user.name}
                      placeholder="Enter your name"
                      readOnly="true"
                      className="input__profile font-semibold block w-full p-2 sm:p-6 sm:text-base sm:h-12"
                    />
                  </div>
                </div>

                <div className="sm:mt-6">
                  <label className="block text-sm font-medium text-gray-700">
                    Email
                  </label>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <input
                      type="email"
                      name="email"
                      value={user.email}
                      placeholder="Enter your email"
                      readOnly="true"
                      className="input__profile font-semibold block w-full p-2 sm:p-6 sm:text-base sm:h-12"
                    />
                  </div>
                </div>
                <div className="sm:mt-6">
                  <label className="block text-sm font-medium text-gray-700">
                    Message
                  </label>
                  <div className="mt-1">
                    <textarea
                      name="message"
                      rows={4}
                      className={utils.classNames(
                        errorInput.keluhan === "" ? "" : "input__error",
                        "input__profile font-semibold block w-full px-6 py-4 sm:text-base"
                      )}
                      placeholder="Enter your message"
                      onChange={(e) => setKeluhan(e.target.value)}
                    >
                      {keluhan}
                    </textarea>

                    <small className="text-red-500">{errorInput.keluhan}</small>
                  </div>
                </div>
                <div className="flex justify-end items-center pt-5">
                  <button
                    onClick={handleSupport}
                    className="inline-flex justify-center sm:py-2 px-16 btn__primary rounded-md sm:rounded-xl"
                  >
                    SEND
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
