import { StatusButton } from "../shared/status-button";
import { StatusText } from "../shared/status-text";
import { Link } from "react-router-dom";
import SignatureMenu from "../shared/signature-menu";

export const CollaborationTable = ({ data }) => {
  const RowParent = ({ item, idx }) => {
    let clas;

    if (item.rejectCount > 0) {
      clas = "text__danger";
    } else if (item.pendingCount > 0) {
      clas = "text__warning";
    }

    return (
      <tr key={`parent-${idx}`}>
        <td className="px-2 py-6 flex justify-center whitespace-nowrap">
          <input
            id="comments"
            name="comments"
            type="checkbox"
            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
          />
        </td>
        <td className="p-2 sm:px-6 sm:py-4 whitespace-nowrap">
          <Link to={`/dashboard/collaboration/detail/${item.id}`}>
            <div className="text-base font-semibold text__color_primary">
              {item.name}
            </div>
          </Link>
        </td>
        <td className="p-2 sm:px-6 sm:py-4 whitespace-nowrap">
          <div className="text-base font-normal text-black">YOU</div>
        </td>
        <td className="p-2 sm:px-6 sm:py-4 whitespace-nowrap">
          <span className="text-base font-normal text-black">
            {item.created_at}
          </span>
        </td>
        <td className="p-2 sm:px-6 sm:py-4 whitespace-nowrap">
          <StatusText clas={clas} status="signed" />
        </td>
        <td className="relative flex justify-between p-2 sm:px-6 sm:py-4 whitespace-nowrap">
          <StatusButton id={item.id} mainStatus={item.status} />
          <div className="absolute text-right inset-y-0 right-0 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            <SignatureMenu
              pages="collaboration"
              id={item.id}
              axisVertical={true}
              data={item}
            />
          </div>
        </td>
      </tr>
    );
  };

  const ChildRow = ({ user, index, item }) => {
    let clas;

    if (item.rejectCount > 0) {
      clas = "text__danger";
    } else if (item.pendingCount > 0) {
      clas = "text__warning";
    }

    return (
      <tr key={`u-${user.id}-${index}`}>
        <td className="px-2 py-6 flex justify-center whitespace-nowrap">
          <input
            id="comments"
            name="comments"
            type="checkbox"
            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
          />
        </td>
        <td className="p-2 sm:px-6 sm:py-4 whitespace-nowrap">
          <div className="text-base font-semibold text__color_primary"></div>
        </td>
        <td className="p-2 sm:px-6 sm:py-4 whitespace-nowrap">
          <div className="text-base font-normal text-black">{user.email}</div>
        </td>
        <td className="p-2 sm:px-6 sm:py-4 whitespace-nowrap">
          <span className="text-base font-normal text-black">
            {item.created_at}
          </span>
        </td>
        <td className="p-2 sm:px-6 sm:py-4 whitespace-nowrap">
          <StatusText clas={clas} status={user.status} />
        </td>
        <td className="relative flex justify-between p-2 sm:px-6 sm:py-4 whitespace-nowrap">
          <StatusButton
            id={item.id}
            mainStatus={item.status}
            userStatus={user.status}
            docTitle={item.name}
          />
          <div className="absolute text-right inset-y-0 right-0 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            <SignatureMenu
              pages="collaboration"
              id={item.id}
              data={item}
              axisVertical={true}
            />
          </div>
        </td>
      </tr>
    );
  };

  return (
    <table className="min-w-full divide-y divide-white-200">
      <thead className="bg-white">
        <tr>
          <th>
            <input
              id="comments"
              name="comments"
              type="checkbox"
              className="pr-4 focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
            />
          </th>
          <th
            scope="col"
            className="py-6 text-left text-sm font-extrabold text-black-600 tracking-wider"
          >
            <div className="flex items-center">
              <span className="">Document Name</span>
              <span className="ml-2">
                <svg
                  width="8"
                  height="11"
                  viewBox="0 0 8 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.66092 10.9984C3.53874 10.9987 3.42034 10.9561 3.32626 10.878L0.188897 8.26033C0.0821131 8.17146 0.0149607 8.04377 0.00221263 7.90533C-0.0105354 7.76689 0.0321652 7.62905 0.12092 7.52214C0.209676 7.41522 0.337216 7.34799 0.475483 7.33522C0.61375 7.32246 0.751418 7.36521 0.858201 7.45408L3.66092 9.79953L6.46363 7.53784C6.51712 7.49436 6.57866 7.46188 6.64472 7.44228C6.71078 7.42269 6.78006 7.41635 6.84857 7.42365C6.91709 7.43095 6.98349 7.45173 7.04395 7.48481C7.10442 7.51788 7.15776 7.56259 7.20091 7.61637C7.2488 7.67021 7.28506 7.73336 7.30744 7.80188C7.32982 7.87039 7.33782 7.9428 7.33095 8.01456C7.32407 8.08631 7.30247 8.15588 7.2675 8.21889C7.23253 8.2819 7.18494 8.337 7.1277 8.38074L3.99034 10.9094C3.89356 10.9751 3.77757 11.0065 3.66092 10.9984Z"
                    fill="#333333"
                  />
                  <path
                    d="M3.67307 0.00156442C3.79524 0.00132554 3.91365 0.0439286 4.00772 0.121978L7.14509 2.73967C7.25187 2.82854 7.31902 2.95623 7.33177 3.09467C7.34452 3.23311 7.30182 3.37095 7.21306 3.47786C7.12431 3.58478 6.99677 3.65201 6.8585 3.66478C6.72023 3.67754 6.58257 3.63479 6.47578 3.54592L3.67307 1.20047L0.870355 3.46216C0.816869 3.50564 0.755327 3.53812 0.689264 3.55772C0.623202 3.57731 0.553924 3.58365 0.48541 3.57635C0.416896 3.56905 0.350497 3.54827 0.290031 3.51519C0.229566 3.48212 0.176224 3.43741 0.133074 3.38363C0.085187 3.32979 0.0489197 3.26664 0.0265437 3.19812C0.0041676 3.12961 -0.00383419 3.0572 0.00303847 2.98544C0.00991065 2.91368 0.0315095 2.84412 0.0664821 2.78111C0.101455 2.7181 0.149048 2.663 0.20628 2.61926L3.34365 0.090566C3.44042 0.0248536 3.55641 -0.00648269 3.67307 0.00156442Z"
                    fill="#333333"
                  />
                </svg>
              </span>
            </div>
          </th>
          <th
            scope="col"
            className="px-6 py-6 text-left text-sm font-extrabold text-black-600 tracking-wider"
          >
            Signers
          </th>
          <th
            scope="col"
            className="px-6 py-6 text-left text-sm font-extrabold text-black-600 tracking-wider"
          >
            <div className="flex items-center">
              <span className=""> Date Created</span>
              <span className="ml-2">
                <svg
                  width="8"
                  height="11"
                  viewBox="0 0 8 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.66092 10.9984C3.53874 10.9987 3.42034 10.9561 3.32626 10.878L0.188897 8.26033C0.0821131 8.17146 0.0149607 8.04377 0.00221263 7.90533C-0.0105354 7.76689 0.0321652 7.62905 0.12092 7.52214C0.209676 7.41522 0.337216 7.34799 0.475483 7.33522C0.61375 7.32246 0.751418 7.36521 0.858201 7.45408L3.66092 9.79953L6.46363 7.53784C6.51712 7.49436 6.57866 7.46188 6.64472 7.44228C6.71078 7.42269 6.78006 7.41635 6.84857 7.42365C6.91709 7.43095 6.98349 7.45173 7.04395 7.48481C7.10442 7.51788 7.15776 7.56259 7.20091 7.61637C7.2488 7.67021 7.28506 7.73336 7.30744 7.80188C7.32982 7.87039 7.33782 7.9428 7.33095 8.01456C7.32407 8.08631 7.30247 8.15588 7.2675 8.21889C7.23253 8.2819 7.18494 8.337 7.1277 8.38074L3.99034 10.9094C3.89356 10.9751 3.77757 11.0065 3.66092 10.9984Z"
                    fill="#333333"
                  />
                  <path
                    d="M3.67307 0.00156442C3.79524 0.00132554 3.91365 0.0439286 4.00772 0.121978L7.14509 2.73967C7.25187 2.82854 7.31902 2.95623 7.33177 3.09467C7.34452 3.23311 7.30182 3.37095 7.21306 3.47786C7.12431 3.58478 6.99677 3.65201 6.8585 3.66478C6.72023 3.67754 6.58257 3.63479 6.47578 3.54592L3.67307 1.20047L0.870355 3.46216C0.816869 3.50564 0.755327 3.53812 0.689264 3.55772C0.623202 3.57731 0.553924 3.58365 0.48541 3.57635C0.416896 3.56905 0.350497 3.54827 0.290031 3.51519C0.229566 3.48212 0.176224 3.43741 0.133074 3.38363C0.085187 3.32979 0.0489197 3.26664 0.0265437 3.19812C0.0041676 3.12961 -0.00383419 3.0572 0.00303847 2.98544C0.00991065 2.91368 0.0315095 2.84412 0.0664821 2.78111C0.101455 2.7181 0.149048 2.663 0.20628 2.61926L3.34365 0.090566C3.44042 0.0248536 3.55641 -0.00648269 3.67307 0.00156442Z"
                    fill="#333333"
                  />
                </svg>
              </span>
            </div>
          </th>
          <th
            scope="col"
            className="px-6 py-6 text-left text-sm font-extrabold text-black-600 tracking-wider"
          >
            <div className="flex items-center">
              <span className="">Status</span>
              <span className="ml-2">
                <svg
                  width="8"
                  height="11"
                  viewBox="0 0 8 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.66092 10.9984C3.53874 10.9987 3.42034 10.9561 3.32626 10.878L0.188897 8.26033C0.0821131 8.17146 0.0149607 8.04377 0.00221263 7.90533C-0.0105354 7.76689 0.0321652 7.62905 0.12092 7.52214C0.209676 7.41522 0.337216 7.34799 0.475483 7.33522C0.61375 7.32246 0.751418 7.36521 0.858201 7.45408L3.66092 9.79953L6.46363 7.53784C6.51712 7.49436 6.57866 7.46188 6.64472 7.44228C6.71078 7.42269 6.78006 7.41635 6.84857 7.42365C6.91709 7.43095 6.98349 7.45173 7.04395 7.48481C7.10442 7.51788 7.15776 7.56259 7.20091 7.61637C7.2488 7.67021 7.28506 7.73336 7.30744 7.80188C7.32982 7.87039 7.33782 7.9428 7.33095 8.01456C7.32407 8.08631 7.30247 8.15588 7.2675 8.21889C7.23253 8.2819 7.18494 8.337 7.1277 8.38074L3.99034 10.9094C3.89356 10.9751 3.77757 11.0065 3.66092 10.9984Z"
                    fill="#333333"
                  />
                  <path
                    d="M3.67307 0.00156442C3.79524 0.00132554 3.91365 0.0439286 4.00772 0.121978L7.14509 2.73967C7.25187 2.82854 7.31902 2.95623 7.33177 3.09467C7.34452 3.23311 7.30182 3.37095 7.21306 3.47786C7.12431 3.58478 6.99677 3.65201 6.8585 3.66478C6.72023 3.67754 6.58257 3.63479 6.47578 3.54592L3.67307 1.20047L0.870355 3.46216C0.816869 3.50564 0.755327 3.53812 0.689264 3.55772C0.623202 3.57731 0.553924 3.58365 0.48541 3.57635C0.416896 3.56905 0.350497 3.54827 0.290031 3.51519C0.229566 3.48212 0.176224 3.43741 0.133074 3.38363C0.085187 3.32979 0.0489197 3.26664 0.0265437 3.19812C0.0041676 3.12961 -0.00383419 3.0572 0.00303847 2.98544C0.00991065 2.91368 0.0315095 2.84412 0.0664821 2.78111C0.101455 2.7181 0.149048 2.663 0.20628 2.61926L3.34365 0.090566C3.44042 0.0248536 3.55641 -0.00648269 3.67307 0.00156442Z"
                    fill="#333333"
                  />
                </svg>
              </span>
            </div>
          </th>
          <th
            scope="col"
            className="px-6 py-6 text-left text-sm font-extrabold text-black-600 tracking-wider"
          >
            Activation
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {data.map((item, idx) => (
          <>
            <RowParent idx={idx} item={item} key={idx} />
            {item.signer.map((user, index) => (
              <ChildRow key={`c-${index}`} user={user} item={item} />
            ))}
          </>
        ))}
      </tbody>
    </table>
  );
};
