import React, { useEffect, useState } from "react";
import "./auth.css";
import ornL from "../../assets/ornament/auth-left.svg";
import ornR from "../../assets/ornament/auth-right.svg";
import logo from "../../assets/images/logo-thumb.svg";
import { Redirect, useParams } from "react-router";
import { getData } from "../../utility/network";
import { useCustomAlert } from "../../components/alerts/alert-provider";
import utils, { print } from "../../utility/utils";
import { AlertComponent } from "../../components/alerts/alert-component";

function VerifyEmail() {
  const [loading, setLoading] = useState();
  const [setList] = useCustomAlert();
  const { code } = useParams();

  useEffect(() => {
    let key = utils.randomAlphaNumeric();
    print(code);

    getData(
      `verify/${code}`,
      {},
      (data) => {
        setList([
          <AlertComponent key={key} type="success" msg={data.message} />,
        ]);
        setLoading(1);
      },
      (msg, error) => {
        print(error);
        setList([
          <AlertComponent key={key} type="error" msg={error.message} />,
        ]);
        setLoading(2);
      },
      () => {
        //print("Fetch Profile Finished");
      }
    );
  }, [code, setList]);

  if (loading === 1) {
    return <Redirect to="/dashboard" />;
  } else if (loading === 2) {
    return <Redirect to="/login" />;
  }

  return (
    <>
      <div className="auth__wrapper">
        <img src={ornL} alt="unknown" className="auth__ornL" />
        <img src={ornR} alt="unknown" className="auth__ornR" />

        <div className="auth__form">
          <img src={logo} alt="unknown" />
          <h1>Teken</h1>
          <div className="text-gray-500 text-base">
            Email kamu telah diverifikasi, mohon tunggu karena otomatis
            dialihkan ke dashboard
          </div>
        </div>
      </div>
    </>
  );
}

export default VerifyEmail;
