import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { putData } from "../../utility/network";
import { useCustomRefresh } from "../../utility/refresh-provider";
import utils, { print } from "../../utility/utils";
import { AlertComponent } from "../alerts/alert-component";
import { useCustomAlert } from "../alerts/alert-provider";

export const DeclineModal = ({ setOpen, open, cancelButtonRef, id }) => {
  const [isError, setIsError] = useState(false);
  const [feedback, setFeedback] = useState({ feedback: "" });
  const [errorInput, setErrorInput] = useState({
    feedback: "",
  });
  const [loading, setLoading] = useState(false);
  const [setList] = useCustomAlert();
  const [refresher, setRefresher] = useCustomRefresh();

  const handleChange = (e) => {
    const { name, value } = e.currentTarget;
    let err = {
      feedback: "",
    };
    let errName = name.replace("_", " ");

    if (utils.checkEmptyValue(value)) {
      err = utils.parseObject(err, name, `${errName} can't be empty!`);
      setIsError(true);
    } else {
      // parsing the input that user has filled
      if (value.length < 30) {
        err = { feedback: "Min. 30 character" };
        setIsError(true);
      } else {
        feedback.feedback = value;
        setFeedback(feedback);
        setIsError(false);
      }
    }

    setErrorInput(err);
  };

  const handleRequestAction = (act) => {
    // handle when click the button for many times

    if (!loading && !isError) {
      setOpen(false);
      setLoading(true);
      setRefresher(false);

      let key = utils.randomAlphaNumeric();

      putData(
        "sign/my-request/" + id + "/decline",
        feedback,
        (data) => {
          setList([
            <AlertComponent
              key={key}
              type="success"
              msg={`Reason send and signature has been rejected!`}
            />,
          ]);
          setRefresher(true);
        },
        (msg, error) => {
          print(error);
          setList([
            <AlertComponent
              key={key}
              type="error"
              msg={error.message.feedback[0]}
            />,
          ]);
        },
        () => {
          setLoading(false);
          print(refresher);
        }
      );
    }
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:items-start">
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        Rejection Reason
                      </Dialog.Title>
                      <div className="mt-2 signature__form">
                        <div className="grid signature__input">
                          <textarea
                            name="feedback"
                            onChange={handleChange}
                            className="w-full text-base"
                            cols="30"
                            rows="5"
                            placeholder="Type your reason for refusal"
                          ></textarea>
                          <small className="text-red-500 text-right">
                            {errorInput.feedback}
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md shadow-sm px-4 py-1 btn__primary font-semibold sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={handleRequestAction}
                  >
                    Send
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-1 bg-white text-base font-medium text-gray-700 hover:bg-gray-50  sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
