import { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router";
import { AlertComponent } from "../../components/alerts/alert-component";
import { useCustomAlert } from "../../components/alerts/alert-provider";
import { getData, postData } from "../../utility/network";
import utils, { print } from "../../utility/utils";
import UploadImg from "../../assets/images/upload.png";
import { Link } from "react-router-dom";

export const SendFinalDoc = ({ pages }) => {
  const { id } = useParams();
  const [signers, setSigners] = useState([]);
  const [setList] = useCustomAlert();
  const [peoples, setPeoples] = useState([]);
  const [errorInput, setErrorInput] = useState({
    file: "",
    signers: "",
  });
  const [file, setFile] = useState(null);
  const [fileErr, setFileErr] = useState(false);
  const [fileName, setFileName] = useState("");
  const [fileSize, setFileSize] = useState(false);
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    document.title = "Teken | Send Final Document";

    let unmounted = false;

    const getPeoples = (q) => {
      let key = utils.randomAlphaNumeric();
      if (!unmounted) {
        getData(
          "signers",
          { search: q },
          (data) => {
            setPeoples(data.data);
          },
          (msg, error) => {
            print(error);
            setList([
              <AlertComponent key={key} type="error" msg={error.message} />,
            ]);
          },
          () => {
            //print("Fetch peoples Finished");
          }
        );
      }
    };

    getPeoples("");

    return () => {
      unmounted = true;
    };
  }, [setList, id]);

  const handleSendDocument = () => {
    let kosong = false;
    let err = {
      file: "",
      signers: "",
    };

    if (file === null) {
      err.file = `One of document file or document link can't be empty!`;
      kosong = true;
    }

    if (signers.length === 0) {
      err.signers = `Signer should be choose first!`;
      kosong = true;
    }

    setErrorInput(err);

    if (!loading && !kosong) {
      setLoading(true);
      const bodyForm = new FormData();
      bodyForm.append("file", file);

      signers.map((item) => bodyForm.append("email[]", item.email));

      let key = utils.randomAlphaNumeric();
      postData(
        "sign/my-signature/send-document",
        bodyForm,
        (data) => {
          setList([
            <AlertComponent
              key={key}
              type="success"
              msg="Success to submit signature!"
            />,
          ]);
          setRedirect(true);
        },
        (msg, error) => {
          print(error);
          setList([
            <AlertComponent key={key} type="error" msg={error.message} />,
          ]);
        },
        () => {
          //print("Finished");
          setLoading(false);
        }
      );
    }
  };

  // ========== GET FILE FOR PREVIEW ========== //
  const getFile = ({ target: { files } }) => {
    setFileSize(false);
    // setUploading(true);
    // console.log(files[0]);
    // console.log(files[0].size);

    // Jika file lebih dari 35mb
    if (files[0].size > 36700160) {
      setFileSize(true);
      return;
    }

    setFile(files[0]);
    setFileName(files[0].name);
    // let data = new FormData();
    // data.append("user_id", id);
    // data.append("file", files[0]);
    // setDataVideo(data);
    setFileErr(false);
  };

  // ========== DELETE FILE PREVIEW ========== //
  const deleteFile = () => {
    setFile(null);
    // setUploading(false);
  };

  const addSigner = (data) => {
    if (!signers.includes(data)) setSigners([...signers, data]);
  };

  const deleteSigners = (idx) => {
    signers.splice(idx, 1);
    setSigners([...signers]);
  };

  const CustomOption = ({ data, callback }) => {
    return (
      <div
        className="flex items-center py-1 px-2 cursor-pointer hover:bg-blue-500"
        onClick={callback}
      >
        <img
          src={
            data.avatar
              ? data.avatar
              : "https://icon-library.com/images/windows-user-icon/windows-user-icon-14.jpg"
          }
          alt="unknown"
          className="flex-shrink-0 h-8 w-8 rounded-full"
        />
        <div>
          <span className="font-normal ml-3 block truncate text-base ">
            {data.name}
          </span>
          <span className="font-normal ml-3 block truncate text-gray-400 text-xs">
            {data.email}
          </span>
        </div>
      </div>
    );
  };

  const InputSigner = () => {
    const [focus, setFocus] = useState(false);
    const [users, setUsers] = useState(peoples);
    let key = utils.randomAlphaNumeric();

    const handleSearch = (e) => {
      let q = e.target.value;

      if (q.length > 2) {
        getData(
          "signers",
          { search: q },
          (data) => {
            setUsers(data.data);
          },
          (msg, error) => {
            print(error);
            setList([
              <AlertComponent key={key} type="error" msg={error.message} />,
            ]);
          },
          () => {
            //print("Fetch search peoples Finished");
          }
        );
      }
    };

    return (
      <div className="relative">
        <div className="grid signature__input">
          <label>
            Signers Email<span>*</span>
          </label>
          <input
            type="text"
            name="emails"
            onFocus={() => setFocus(true)}
            onBlur={() => {
              setTimeout(() => {
                setFocus(false);
              }, 200);
            }}
            onChange={handleSearch}
            placeholder="Enter your Signer Email"
            autoComplete="off"
          />
        </div>
        {focus && (
          <div className="z-10 absolute left-0 w-full rounded-lg bg-white overflow-auto h-60">
            {users.map((item) => (
              <CustomOption
                key={item.id}
                data={item}
                callback={() => {
                  addSigner(item);
                }}
              />
            ))}
          </div>
        )}
      </div>
    );
  };
  if (redirect) {
    return <Redirect to="/dashboard/" />;
  }

  return (
    <>
      <div className="px-4 py-2 sm:py-6 sm:px-12 relative">
        <div className="signature__header">
          <h3 style={{ textTransform: "capitalize" }}>Send Final Document</h3>
        </div>

        <div className=" bg-white rounded-lg mt-5 py-5 px-5">
          <div
            className="signature__form bg-white rounded-lg mb-5"
            style={{ height: "100%" }}
          >
            <InputSigner />
            <small className="text-red-500">{errorInput.signers}</small>
            {signers.map((item, idx) => (
              <div
                key={item.name}
                className="flex justify-between items-center mb-2 py-1 px-2 cursor-pointer shadow-md rounded-lg hover:bg-gray-100"
              >
                <div className="flex">
                  <img
                    src={
                      item.avatar
                        ? item.avatar
                        : "https://icon-library.com/images/windows-user-icon/windows-user-icon-14.jpg"
                    }
                    alt="unknown"
                    className="flex-shrink-0 h-8 w-8 rounded-full"
                  />
                  <div>
                    <span className="font-normal ml-3 block truncate text-base ">
                      {item.name}
                    </span>
                    <span className="font-normal ml-3 block truncate text-gray-400 text-xs">
                      {item.email}
                    </span>
                  </div>
                </div>
                <span onClick={() => deleteSigners(idx)}>
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14 14L8 8M14 8L8 14"
                      stroke="#FF3B3B"
                      strokeWidth="1.33333"
                      strokeLinecap="round"
                    />
                    <path
                      d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
                      stroke="#FF3B3B"
                      strokeWidth="1.33333"
                    />
                  </svg>
                </span>
              </div>
            ))}
          </div>
          <div className="grid signature__input py-5">
            <label>
              Document File <span className="optional">(Optional)</span>
              <span className="red">**</span>
            </label>

            {/* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= */}

            <div className="signature__upload_wrapper">
              <div
                className={
                  fileErr === true ? "video-dotline-err" : "video-dotline"
                }
              >
                {file !== null ? (
                  <>
                    <img
                      src={file}
                      const
                      alt="unknown"
                      style={{ width: "100px" }}
                    />
                    <span>{fileName}</span>
                    <button className="upl-delete" onClick={deleteFile}>
                      DELETE
                    </button>
                  </>
                ) : (
                  <>
                    <input
                      type="file"
                      className="video-upload"
                      onChange={getFile}
                    />
                    <img src={UploadImg} alt="upload" className="upl-img" />
                    <span>Upload your file</span>
                    {fileSize === true ? (
                      <span style={{ color: "red" }}>
                        Ukuran file video tidak boleh lebih dari 10mb
                      </span>
                    ) : null}
                  </>
                )}
              </div>
            </div>

            {/* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= */}

            <span className="signature__input_notes">
              ** Max. size 10MB | Format (.pdf, .doc, .jpeg, .jpg, .png){" "}
            </span>
            <small className="text-red-500">{errorInput.file}</small>
          </div>

          <div className="signature__form_submit">
            <Link to="/dashboard/signature">
              <button className="back">Back</button>
            </Link>
            <button className="save" onClick={handleSendDocument}>
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
