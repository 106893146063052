import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import { Routes, AuthRoutes } from "./Routes";
import "tailwindcss/tailwind.css";
import { useDispatch } from "react-redux";
import { CustomAlertProvider } from "./components/alerts/alert-provider";
import { RefreshProvider } from "./utility/refresh-provider";
import withClearCache from "./ClearCache";
import "./index.css";

const ClearCacheComponent = withClearCache(MainApp);

function App() {
  return <ClearCacheComponent />;
}

function MainApp(props) {
  const dispatch = useDispatch();

  useEffect(() => {
    const local = JSON.parse(localStorage.getItem("token"));

    if (local) {
      dispatch({
        type: "LOGIN_SUCCESS",
        payload: { token: local.token, login: true },
      });
    }
  }, [dispatch]);

  return (
    <BrowserRouter>
      <CustomAlertProvider>
        <RefreshProvider>
          <Routes />
          <AuthRoutes />
        </RefreshProvider>
      </CustomAlertProvider>
    </BrowserRouter>
  );
}

export default App;
