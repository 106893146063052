const INITIAL_STATE = {
  tour_signature: false,
};

const TourSignature = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "TOUR_SIGNATURE_DONE":
      return {
        ...state,
        ...action.payload, 
      };
    case "TOUR_FAILED":
      return {
        ...state,
        tour_signature: false,
      };
    case "TOUR_SIGNATURE_RESET":
      return INITIAL_STATE;
    default:
      return {
        ...state,
        ...action.payload, 
      };
  }
};

export default TourSignature;
