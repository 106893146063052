import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { AlertComponent } from "../../components/alerts/alert-component";
import { useCustomAlert } from "../../components/alerts/alert-provider";
import { getData } from "../../utility/network";
import utils, { print } from "../../utility/utils";
import "./detail.css";
import bg_detail from "../../assets/images/bg-detail.png";
import ornR from "../../assets/ornament/orn_detail.svg";
import logo from "../../assets/images/logo.png";
import { PublicDetail } from "../../components/public-detail/signature";
import { Passcode } from "../../components/public-detail/passcode";
import { LoginFirst } from "../../components/public-detail/login-first";
import { NoAccess } from "../../components/public-detail/no-acces";

function DetailGlobal() {
  const [signdata, setSignData] = useState(null);
  const [setList] = useCustomAlert();
  const [tabs, setTabs] = useState(0);
  const [msg, setMsg] = useState("");
  const { qr_code } = useParams();

  useEffect(() => {
    // get data
    let key = utils.randomAlphaNumeric();
    getData(
      "c/" + qr_code,
      {},
      (data) => {
        if (data.message != null) {
          setMsg(data.message);
        } else {
          setSignData(data.data);
        }
      },
      (msg, error) => {
        print(error);
        setMsg(error.message);
        setList([
          <AlertComponent key={key} type="error" msg={error.message} />,
        ]);
      },
      () => {
        //print("Fetch Signature Detail Finished");
      }
    );
  }, [setList, qr_code]);

  const ChildComponent = () => {
    if (signdata !== null) {
      return (
        <PublicDetail
          signdata={signdata}
          qr_code={qr_code}
          setTabs={setTabs}
          tabs={tabs}
        />
      );
    } else if (msg.toLowerCase() === "unauthorized") {
      return <LoginFirst />;
    } else if (msg.toLowerCase() === "anda tidak memiliki akses") {
      return <NoAccess />;
    } else if (msg.toLowerCase() === "perlu input code") {
      return <Passcode qr_code={qr_code} setData={setSignData} />;
    } else {
      return <></>;
    }
  };

  return (
    <div className="relative overflow-hidden">
      <img
        src={bg_detail}
        alt="unknown"
        className="absolute right-0 left-0 mx-auto bottom-0"
      />
      <img
        src={ornR}
        alt="unknown"
        className="absolute top-0"
        style={{ right: "-10rem" }}
      />
      <a href="/">
        <img
          src={logo}
          alt="unknown"
          className="absolute right-0 left-0 mx-auto mt-4"
        />
      </a>
      <div className="flex justify-center h-screen items-center px-4">
        <div className="container">
          <div className="bg-white opacity-90 rounded-md shadow-md relative py-2 px-4">
            <ChildComponent />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailGlobal;
