import { useState } from "react";
import { ContactSupport } from "../../components/help/contact-support";
import { Disclaimer } from "../../components/help/disclaimer";
import { FaqSection } from "../../components/help/faq";
import { PrivacyPolicy } from "../../components/help/policy";
import { TermsConditions } from "../../components/help/terms";

import utils from "../../utility/utils";

const navs = [
  { name: "FAQ", index: 0, child: <FaqSection /> },
  { name: "Contact & Support", index: 1, child: <ContactSupport /> },
  { name: "Terms & Conditions", index: 2, child: <TermsConditions /> },
  { name: "Privacy Policy", index: 3, child: <PrivacyPolicy /> },
  { name: "Disclaimer", index: 4, child: <Disclaimer /> },
];

const Help = () => {
  const [navigation, setNavigation] = useState(navs[0]);

  return (
    <>
      <div className="grid grid-cols-3 sm:grid-cols-8">
        {navs.map((item) => (
          <span
            key={item.index}
            onClick={() => setNavigation(item)}
            className={utils.classNames(
              navigation.index === item.index
                ? "btn__primary"
                : "btn__outline_primary",
              "h-8 sm:h-10 mt-1 flex items-center justify-center text-xs sm:text-sm text-center rounded-full mr-2 px-2 border"
            )}
          >
            <div>{item.name}</div>
          </span>
        ))}
      </div>
      <div className="mt-4 sm:mt-12">{navigation.child}</div>
    </>
  );
};

export default Help;
