import { Fragment, useEffect, useState } from "react";
import { Disclosure } from "@headlessui/react";
import { Link, useLocation } from "react-router-dom";
import { ProfileRoutes } from "../../Routes";
import "./profile.css";
import utils from "../../utility/utils";
import { LoadingBarier } from "../../components/loading-barrier";

const navigation = [
  { name: "My Information", href: "/dashboard/profile", current: 0 },
  {
    name: "Change Password",
    href: "/dashboard/profile/change-password",
    current: 1,
  },
  { name: "Help", href: "/dashboard/profile/help", current: 2 },
];

const Profile = () => {
  const [tab, setTab] = useState(0);
  let location = useLocation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    document.title = "Teken | Profile";

    function checkPath(url) {
      return location.pathname.includes(url);
    }

    if (checkPath("change-password")) {
      setTab(1);
    } else if (checkPath("help")) {
      setTab(2);
    }

    let to = setTimeout(() => {
      setLoading(false);
      clearInterval(to);
    }, 300);
  }, [location.pathname]);

  return (
    <>
      {loading && <LoadingBarier />}
      <div className="px-4 py-2 sm:py-6 sm:px-12 relative">
        <div className="text-2xl sm:text-3xl font-extrabold">Settings</div>
        <div className="px-3 mt-4 sm:mt-10 box__profile rounded-md bg-white pb-2 sm:py-5">
          <div>
            <Disclosure as="nav" className="bg-white rounded-lg">
              {({ open }) => (
                <>
                  <div className="w-full sm:px-10">
                    <div className="relative flex items-center justify-between h-16">
                      <div className="flex-1 flex items-center sm:items-stretch justify-start">
                        <div className="block">
                          <div className="flex space-x-6">
                            {navigation.map((item) => (
                              <Link
                                key={item.name}
                                to={item.href}
                                onClick={() => {
                                  setTab(item.current);
                                }}
                                className={utils.classNames(
                                  tab === item.current ? "tab__active" : "",
                                  "py-2 rounded-md text-xs sm:text-sm text-gray-500 font-medium tab__profile relative transition duration-150 ease-in-out"
                                )}
                                aria-current={item.current ? "page" : undefined}
                              >
                                {item.name}
                              </Link>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <Disclosure.Panel className="sm:hidden">
                    <div className="px-2 pt-2 pb-3 space-y-1">
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={utils.classNames(
                            item.current
                              ? "bg-gray-900 text-white"
                              : "text-gray-300 hover:bg-gray-700 hover:text-white",
                            "block px-3 py-2 rounded-md text-base font-medium"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          {item.name}
                        </a>
                      ))}
                    </div>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          </div>
          <div className="sm:px-10">
            <ProfileRoutes />
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
