import utils from "../../utility/utils";
import SignatureMenu from "../shared/signature-menu";

export const PublicDetail = ({ signdata, qr_code, tabs, setTabs }) => {
  return (
    <>
      <div>
        <SignatureMenu id={qr_code} pages="request" data={signdata} />
      </div>

      <div className="py-5 px-5 text-center">
        <img
          src={`data:image/png;base64,${signdata.qr_code}`}
          alt="unknown"
          width="130px"
          height="130px"
          className="mx-auto"
        />

        <h6>{signdata.sign_number}</h6>
      </div>

      <div className="py-5 px-5">
        <div className="signature__inner_menu flex items-center justify-between">
          <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
            <div>
              <div className="flex space-x-4">
                <div
                  className={utils.classNames(
                    tabs === 0 ? "tab__active" : "",
                    "py-2 rounded-md text-xs sm:text-sm text-gray-500 font-medium tab__profile relative transition duration-150 ease-in-out"
                  )}
                  aria-current="page"
                  onClick={() => setTabs(0)}
                >
                  Signer Info
                </div>
                <div
                  className={utils.classNames(
                    tabs === 1 ? "tab__active" : "",
                    "py-2 rounded-md text-xs sm:text-sm text-gray-500 font-medium tab__profile relative transition duration-150 ease-in-out"
                  )}
                  aria-current="page"
                  onClick={() => setTabs(1)}
                >
                  Document Info
                </div>
                <div
                  className={utils.classNames(
                    tabs === 2 ? "tab__active" : "",
                    "py-2 rounded-md text-xs sm:text-sm text-gray-500 font-medium tab__profile relative transition duration-150 ease-in-out"
                  )}
                  aria-current="page"
                  onClick={() => setTabs(2)}
                >
                  Document File
                </div>
              </div>
            </div>
          </div>
        </div>

        {tabs === 0 ? (
          <div className="signer__info">
            <div className="grid lg:grid-cols-2 sm:grid-cols-2 grid-cols-1 gap-3">
              <div>
                <div className="signer__info_data">
                  <p>Sign number</p>
                  <h6>{signdata.sign_number}</h6>
                </div>
                <div className="signer__info_data">
                  <p>Verified By</p>
                  <h6>{signdata.verification}</h6>
                </div>
                <div className="signer__info_data">
                  <p>Sign Email</p>
                  <h6>{signdata.email}</h6>
                </div>
              </div>
              <div>
                <div className="signer__info_data">
                  <p>Date Created</p>
                  <h6>{signdata.date_created}</h6>
                </div>
                <div className="signer__info_data">
                  <p>Time Created</p>
                  <h6>{signdata.time}</h6>
                </div>
              </div>
            </div>
          </div>
        ) : tabs === 1 ? (
          <div className="signer__document">
            <div className="signer__info_data">
              <p>Document Name</p>
              <h6>{signdata.document_name}</h6>
            </div>
            <div className="signer__info_data">
              <p>Description</p>
              <h6>{signdata.description}</h6>
            </div>
            <div className="grid lg:grid-cols-2 grid-cols-1">
              <div className="signer__info_data">
                <p>Document Category</p>
                <h6>{signdata.category}</h6>
              </div>
              <div className="signer__info_data">
                <p>Document Type</p>
                <h6>{signdata.type}</h6>
              </div>
            </div>
          </div>
        ) : tabs === 2 ? (
          <div className="signer__file">
            <div className="signer__info_data">
              <p>Document Link</p>
              <h6>
                <a target="_blank" href={signdata.file} rel="noreferrer">
                  {signdata.file}
                </a>
              </h6>
            </div>
            <div className="signer__info_data">
              <p>Document File</p>
              <a
                download="true"
                target="_blank"
                rel="noreferrer"
                href={signdata.file}
              >
                <div className="document__file">{signdata.name}</div>
              </a>
            </div>
          </div>
        ) : (
          "null"
        )}
      </div>
    </>
  );
};
