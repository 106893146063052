/* eslint-disable no-unused-vars */
// import imageCompression from "browser-image-compression";

export const BASE_URL = "https://teken-api.yokesen.com/api/v1/";

export const twoDigit = (numberStr) => {
  const temp = `0${numberStr}`;
  return temp.slice(-2);
};

export const enumSignature = {
  my_signature: "my signature",
  my_request: "my request",
  signature: "signature",
  request: "request",
  collaboration: "collaboration",
  detail_colllaboration: "detail collaboration",
  detail_signature: "detail signature",
  detail_request: "detail request",
};

export const print = (text) => {
  console.log(text);
};

const role = "";

const utils = {
  role,

  replaceAll(str, find, replace) {
    return str.replace(new RegExp(find, "g"), replace);
  },

  randomAlphaNumeric() {
    return (Math.random() + 1).toString(36).substring(7);
  },

  saveToken(token) {
    localStorage.set("token", token);
  },

  getToken() {
    const local = JSON.parse(localStorage.getItem("token"));
    if (local) {
      return local.token;
    } else {
      return null;
    }
  },

  saveRole(role) {
    this.role = role;
    localStorage.set("user_role", role);
  },

  getRole() {
    if (this.isEmpty(this.role)) {
      const userRole = localStorage.getItem("user_role");
      if (userRole != null && userRole !== "") {
        this.role = userRole;
        return userRole;
      }
      return null;
    } else {
      return this.role;
    }
  },

  getCRMOnlyRole() {
    return [
      "superadmin",
      "hrd",
      "development",
      "finance_and_accounting",
      "operation",
      "weaver",
    ];
  },

  getAllCRMRole() {
    return [
      "superadmin",
      "hrd",
      "development",
      "finance_and_accounting",
      "operation",
      "weaver",
    ];
  },

  checkRole(roles) {
    return roles.indexOf(this.getRole()) > -1;
  },

  saveUser(user) {
    user.role_name = user.role.name.split("_").join(" ");
    localStorage.set("user", JSON.stringify(user));
  },

  getAdminToken() {
    const token = localStorage.getItem("admin_token");
    if (token != null && token !== "") {
      return token;
    }
    return null;
  },

  saveAdminUser(user) {
    localStorage.set("admin_user", JSON.stringify(user));
  },

  getUser() {
    const user = localStorage.getItem("user");
    if (user) {
      return JSON.parse(user);
    } else {
      return null;
    }
  },

  saveProfile(profile) {
    localStorage.set("profile", JSON.stringify(profile));
  },

  getProfile() {
    const profile = localStorage.getItem("profile");
    if (profile) {
      return JSON.parse(profile);
    } else {
      return null;
    }
  },

  logout() {
    localStorage.clear();
  },

  logoutAdmin() {
    localStorage.clear();
  },

  getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  },

  getFullDate(date) {
    // Minggu, 6 Mei 2020, 17 : 30 WIB
    const days = [
      "Minggu",
      "Senin",
      "Selasa",
      "Rabu",
      "Kamis",
      "Jumat",
      "Sabtu",
    ];
    const months = [
      "Januari",
      "Februari",
      "Maret",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Agustus",
      "September",
      "Oktober",
      "November",
      "Desember",
    ];
    const day = days[date.getDay()];
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    const d = date.getDate();
    const hour = twoDigit(date.getHours());
    const minute = twoDigit(date.getMinutes());
    return `${day}, ${d} ${month} ${year}, ${hour} : ${minute}`;
  },

  getDatabaseDate(dateStr) {
    // database dateStr -> 2020-05-28 17:56:15
    const months = [
      "",
      "Januari",
      "Februari",
      "Maret",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Agustus",
      "September",
      "Oktober",
      "November",
      "Desember",
    ];
    const d = dateStr.substring(8, 10);
    const month =
      dateStr[5] === "0"
        ? months[dateStr.substring(6, 7)]
        : months[dateStr.substring(5, 7)];
    const year = dateStr.substring(0, 4);
    const hour = dateStr.substring(11, 13);
    const minute = dateStr.substring(14, 16);
    return `${d} ${month} ${year}, ${hour} : ${minute}`;
  },

  getDatabaseTime(dateStr) {
    // database date -> 2020-05-28 17:56:15
    const hour = dateStr.substring(11, 13);
    const minute = dateStr.substring(14, 16);
    const second = dateStr.substring(17, 19);
    return `${hour} : ${minute} : ${second}`;
  },

  getISODate(date) {
    // YYYY-MM-dd
    const YYYY = date.getFullYear();
    const dd = twoDigit(date.getDate());
    const MM = twoDigit(date.getMonth() + 1);
    return `${YYYY}-${MM}-${dd}`;
  },

  getISODateFromStr(date) {
    if (date == null || date.length < 10) {
      return null;
    }
    const YYYY = date.substring(6);
    const dd = date.substring(0, 2);
    const MM = date.substring(3, 5);
    return `${YYYY}-${MM}-${dd}`;
  },

  getIndoDateFromISODateStr(date) {
    if (date == null || date.length < 10) {
      return null;
    }
    const YYYY = date.substring(0, 4);
    const dd = date.substring(8, 10);
    const MM = date.substring(5, 7);
    return `${dd}-${MM}-${YYYY}`;
  },

  isEmpty(string) {
    return !(string != null && string !== "" && string !== undefined);
  },

  getDateFromStr(dateStr) {
    const date = new Date(dateStr);
    if (date.getTime()) {
      return date;
    } else {
      console.log("date.getTime()", date.getTime());
      return null;
    }
  },

  clearErrors(errors) {
    Object.keys(errors).forEach((key) => {
      errors[key] = null;
    });
  },

  parseErrors(fromServer, inLocal) {
    Object.keys(inLocal).forEach((key) => {
      if (key in fromServer) {
        inLocal[key] = fromServer[key];
      }
    });
    console.log(inLocal);
  },

  parseObject(data, keyIndex, value) {
    Object.keys(data).forEach((key) => {
      if (keyIndex.toLowerCase() === key.toLowerCase()) {
        data[key] = value;
        return;
      }
    });
    return data;
  },

  checkEmptyValue(value) {
    return value === "" || value === null || value.length === 0;
  },

  //   compressImage(file, onComplete, onFailed, options = null) {
  //     // you should provide one of maxSizeMB, maxWidthOrHeight in the options
  //     const tempOptions =
  //       options == null
  //         ? {
  //             maxSizeMB: 0.5,
  //             maxWidthOrHeight: 256,
  //             fileType: "png",
  //           }
  //         : options;
  //     imageCompression(file, tempOptions)
  //       .then(function (compressedFile) {
  //         onComplete(compressedFile);
  //       })
  //       .catch(function (error) {
  //         onFailed(error.message);
  //       });
  //   },

  moneyFormat(nominal, useRp = false) {
    if (useRp) {
      return "Rp " + (nominal ? nominal.toLocaleString() : "0");
    }
    return nominal ? nominal.toLocaleString() : "";
  },

  validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  },

  classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  },

  formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  },

  generateSignNumber(uid, counter) {
    var d = new Date(),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return ["YTI", uid, counter, day, month, year].join("/");
  },

  svgString2Image(svgData, callback) {
    // create canvas in memory(not in DOM)
    var canvas = document.createElement("canvas");
    // get canvas context for drawing on canvas
    var context = canvas.getContext("2d");
    // set canvas size
    canvas.width = 300;
    canvas.height = 300;
    // create image in memory(not in DOM)
    var image = new Image();
    // later when image loads run this
    image.onload = function () {
      // async (happens later)
      // clear canvas
      context.clearRect(0, 0, 300, 300);
      // draw image with SVG data to canvas
      context.drawImage(image, 0, 0, 300, 300);
      // snapshot canvas as png
      var pngData = canvas.toDataURL("image/png");
      // pass png data URL to callback
      callback(pngData);
    }; // end async
    // start loading SVG data into in memory image
    image.src = svgData;
  },
};

export default utils;
