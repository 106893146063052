const INITIAL_STATE = {
  tour_sidebar: false,
};

const TourSidebar = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "TOUR_SIDEBAR_DONE":
      return {
        ...state,
        ...action.payload,
      };
    case "TOUR_FAILED":
      return {
        ...state,
        tour_sidebar: false,
      };
    case "TOUR_SIDEBAR_RESET":
      return INITIAL_STATE;
    default:
      return {
        ...state,
        ...action.payload,
      };
  }
};

export default TourSidebar;
