// import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import { CollaborationTable } from "../collaboration/collaboration-table";
import SignatureTable from "../my-signature/signature-table";
import { RequestTable } from "../request/request-table";

export const ListView = ({ page, data }) => {
  const RenderTable = () => {
    switch (page) {
      case "collaboration":
        return <CollaborationTable key={`tc`} data={data} />;
      case "request":
        return <RequestTable key={`tr`} data={data} />;
      case "signature":
        return <SignatureTable key={`ts`} data={data} />;
      default:
        break;
    }
  };
  return (
    <>
      {data.length > 0 && (
        <div className="container mt-4">
          <div className="flex flex-col sm:rounded-lg bg-white p-4">
            <div className="-my-2 overflow-x-auto table__custom sm:-px-6 lg:-px-8">
              <div className="py-2 align-middle inline-block min-w-full">
                <div className="border-b">
                  <RenderTable />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
