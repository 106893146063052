import { useState } from "react";
import { Redirect } from "react-router";
import logo from "../../assets/images/logo-thumb.svg";
import { postData } from "../../utility/network";
import utils, { print } from "../../utility/utils";
import { AlertComponent } from "../alerts/alert-component";
import { useCustomAlert } from "../alerts/alert-provider";
import OtpInput from "../otp/reader";

export const InputPinForgot = ({ email, loading, setLoading }) => {
  const [passcode, setPasscode] = useState("");
  const [setList] = useCustomAlert();
  const [errors, setErrors] = useState({ passcode: "" });
  const [success, setSuccess] = useState(false);

  const handleSendCode = () => {
    let abortController = new AbortController();
    setLoading(true);

    let kosong = false;
    let err = {
      email: "",
      password: "",
    };
    setErrors(err);

    // email validation
    if (passcode.length === 0) {
      err.passcode = `passcode field can't be empty!`;
      kosong = true;
      setLoading(false);
    }

    setErrors(err);

    if (!loading && !kosong) {
      const body = {
        token: passcode,
        email: email,
      };

      let key = utils.randomAlphaNumeric();

      postData(
        "confirm-token",
        body,
        (data) => {
          setList([
            <AlertComponent key={key} type="success" msg={data.message} />,
          ]);
          setLoading(false);
          setSuccess(true);
        },
        (msg, errors) => {
          print(errors);
          setList([
            <AlertComponent key={key} type="error" msg={errors.message} />,
          ]);
        },
        () => {
          // you can put loading here
          setLoading(false);
          abortController.abort();
          //print("ON FINISHED");
        }
      );
    }
  };

  if (success) {
    return (
      <Redirect
        to={{
          pathname: "/forgot-password",
          state: { email: email },
        }}
      />
    );
  }

  return (
    <>
      <img src={logo} alt="unknown" />
      <h2 className="text-xl font-bold">Verify Identity</h2>
      <p>Please check your email to take 4 digit code for continue</p>

      <OtpInput
        className="my-2 justify-around"
        value={passcode}
        onChange={setPasscode}
        autoFocus
        OTPLength={4}
        otpType="number"
        disabled={false}
      />
      <small className="text-red-500">{errors.passcode}</small>

      <div className="cursor-pointer text-xs font-bold mb-3 mt-6 text-blue-500">
        Resend code
      </div>

      <div className="auth__submit">
        <button onClick={handleSendCode} className="login__btn">
          Submit
        </button>
      </div>
    </>
  );
};
