import React, { useState } from "react";
import "./auth.css";
import ornL from "../../assets/ornament/auth-left.svg";
import ornR from "../../assets/ornament/auth-right.svg";
import logo from "../../assets/images/logo-thumb.svg";
import { Link, Redirect } from "react-router-dom";
import { postData } from "../../utility/network";
import { LoadingBarier } from "../../components/loading-barrier";
import { useCustomAlert } from "../../components/alerts/alert-provider";
import { AlertComponent } from "../../components/alerts/alert-component";
import { useDispatch } from "react-redux";
import { loginAction } from "../../redux/action";
import utils, { print } from "../../utility/utils";

function Register() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [setList] = useCustomAlert();
  const dispatch = useDispatch();
  const [login, setLogin] = useState(false);
  const [errors, setErrors] = useState({ name: "", email: "", password: "" });

  const handleChange = (e) => {
    const { name, value } = e.currentTarget;
    if (name === "email") {
      setEmail(value);
    } else if (name === "name") {
      setName(value);
    } else {
      setPassword(value);
    }
  };

  const handleRegister = () => {
    let abortController = new AbortController();
    setLoading(true);

    let kosong = false;
    let err = {
      email: "",
      password: "",
      name: "",
    };
    setErrors(err);

    // email validation
    if (email.length === 0) {
      err.email = `Email field can't be empty!`;
      kosong = true;
      setLoading(false);
    }
    if (!utils.validateEmail(email)) {
      err.email = `Email doesn't valid!`;
      kosong = true;
      setLoading(false);
    }

    // password validation
    if (password.length === 0) {
      err.password = `Password field can't be empty!`;
      kosong = true;
      setLoading(false);
    }
    if (password.length < 6) {
      err.password = `Minimal 6 character!`;
      kosong = true;
      setLoading(false);
    }

    // name validation
    if (name.length === 0) {
      err.name = `Name field can't be empty!`;
      kosong = true;
      setLoading(false);
    }

    setErrors(err);

    if (!loading && !kosong) {
      const body = {
        name: name,
        email: email,
        password: password,
        platform: "web",
      };

      let key = utils.randomAlphaNumeric();

      postData(
        "register",
        body,
        (data) => {
          setList([
            <AlertComponent
              key={key}
              type="success"
              msg="Success mendaftar! Silahkan cek email untuk verifikasi akun"
            />,
          ]);
          // set token to redux and local storage
          dispatch(loginAction({ token: data.access_token, login: true }));

          setLogin(true);
        },
        (msg, errors) => {
          print(errors);
          if (typeof errors.message === "object") {
            Object.keys(errors.message).forEach((key) => {
              let kunci = utils.randomAlphaNumeric();
              let errText = errors.message[key][0];

              setList([
                <AlertComponent key={kunci} type="error" msg={errText} />,
              ]);
            });
          } else {
            setList([
              <AlertComponent
                key={key}
                type="error"
                msg={
                  errors.message === "Unauthorized"
                    ? "Wrong email or password"
                    : errors.message
                }
              />,
            ]);
          }
        },
        () => {
          // you can put loading here
          abortController.abort();
          setLoading(false);
        }
      );
    }
  };

  if (login) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <>
      {loading && <LoadingBarier />}
      <div className="auth__wrapper">
        <img src={ornL} alt="unknown" className="auth__ornL" />
        <img src={ornR} alt="unknown" className="auth__ornR" />

        <div className="auth__form">
          <img src={logo} alt="unknown" />
          <h1>Registration</h1>
          <p>
            Sudah memiliki akun?{" "}
            <Link to="/login">
              <b>Masuk Disini</b>
            </Link>
          </p>

          <div className="auth__input">
            <div className="relative flex w-full flex-wrap items-stretch mb-3">
              <span className="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  style={{ opacity: "0.3" }}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                  />
                </svg>
              </span>
              <input
                type="text"
                name="name"
                onChange={handleChange}
                placeholder="Nama Lengkap"
                className={utils.classNames(
                  errors.name !== "" ? "input__error" : "",
                  "px-3 py-3 w-full pl-12"
                )}
              />
              <small className="text-red-500">{errors.name}</small>
            </div>

            <div className="relative flex w-full flex-wrap items-stretch mb-3">
              <span className="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  style={{ opacity: "0.3" }}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                  />
                </svg>
              </span>
              <input
                type="email"
                name="email"
                onChange={handleChange}
                placeholder="Email"
                className={utils.classNames(
                  errors.email !== "" ? "input__error" : "",
                  "px-3 py-3 w-full pl-12"
                )}
              />
              <small className="text-red-500">{errors.email}</small>
            </div>

            {/* Input Password */}
            <div className="relative flex w-full flex-wrap items-stretch mb-3">
              <span className="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  style={{ opacity: "0.3" }}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                  />
                </svg>
              </span>
              <input
                autoComplete="off"
                type="password"
                name="password"
                onChange={handleChange}
                placeholder="Password"
                className={utils.classNames(
                  errors.password !== "" ? "input__error" : "",
                  "px-3 py-3 w-full pl-12"
                )}
              />
              <small className="text-red-500">{errors.password}</small>
            </div>
          </div>

          <div className="auth__submit">
            <button className="login__btn" onClick={handleRegister}>
              Register
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Register;
