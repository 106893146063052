import React, { useState } from "react";
import "./auth.css";
import ornL from "../../assets/ornament/auth-left.svg";
import ornR from "../../assets/ornament/auth-right.svg";
import { LoadingBarier } from "../../components/loading-barrier";
import { InputEmailForgot } from "../../components/forgot-password/input-email-forgot";
import { InputPinForgot } from "../../components/forgot-password/input-pin-forgot";

function SendForgetEmail() {
  const [comp, setComponent] = useState(0);
  const [email_user, setEmailUser] = useState("");
  const [loading, setLoading] = useState(false);

  return (
    <>
      {loading && <LoadingBarier />}
      <div className="auth__wrapper">
        <img src={ornL} alt="unknown" className="auth__ornL" />
        <img src={ornR} alt="unknown" className="auth__ornR" />

        <div className="auth__form">
          {comp === 0 ? (
            <InputEmailForgot
              loading={loading}
              setLoading={setLoading}
              changeComponent={setComponent}
              setUser={setEmailUser}
            />
          ) : (
            <InputPinForgot
              oading={loading}
              setLoading={setLoading}
              email={email_user}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default SendForgetEmail;
