import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { AlertComponent } from "../../components/alerts/alert-component";
import { useCustomAlert } from "../../components/alerts/alert-provider";
import SignatureMenu from "../../components/shared/signature-menu";
import { AvatarStatus } from "../../components/shared/avatar-with-status";
import { StatusButton } from "../../components/shared/status-button";
import { StatusText } from "../../components/shared/status-text";
import { deleteData, getData } from "../../utility/network";
import utils, { enumSignature, print } from "../../utility/utils";
import deleteIMG from "../../assets/images/delete.png";
import "./detail.css";
import { ModalConfirm } from "../../components/modal/modal-confirm";

export const CollaborationDetail = ({ pages }) => {
  const [signdata, setSignData] = useState([]);
  const [histories, setHistory] = useState([]);
  const [setList] = useCustomAlert();
  const [tabs, setTabs] = useState(0);
  const [reload, setReload] = useState(true);
  const { id } = useParams();

  const deleteRef = useRef(null);
  const [deleteOpen, setDeleteOpen] = useState(false);

  useEffect(() => {
    document.title = "Teken | Collaboration";

    let uri = "collab/" + id + "/detail";

    // get user data
    let key = utils.randomAlphaNumeric();
    if (reload) {
      getData(
        uri,
        {},
        (data) => {
          data.data.date = data.data.initiated_on.split("|")[0];
          data.data.time = data.data.initiated_on.split("|")[1];

          setSignData(data.data);
          setHistory(data.history);
        },
        (msg, error) => {
          print(error);

          setList([
            <AlertComponent key={key} type="error" msg={error.message} />,
          ]);
        },
        () => {
          setReload(false);
          //print("Fetch Collaboration Detail Finished");
        }
      );
    }
  }, [setList, id, pages, reload]);

  const handleDeleteSigner = (uid) => {
    let key = utils.randomAlphaNumeric();
    deleteData(
      `collab/${id}/detail/${uid}/delete`,
      (data) => {
        setList([
          <AlertComponent key={key} type="success" msg={data.message} />,
        ]);

        setReload(true);
      },
      (msg, error) => {
        print(error);
        setList([
          <AlertComponent key={key} type="error" msg={error.message} />,
        ]);
      },
      () => {
        //print("Fetch Collaboration Detail Finished");
      }
    );
  };

  return (
    <>
      <div className="px-4 py-2 sm:py-6 sm:px-12 relative">
        <div className="signature__header">
          <h3 style={{ textTransform: "capitalize" }}>{pages}</h3>
        </div>

        <div className=" bg-white rounded-lg my-5 sm:flex ">
          <div className="signature__qr py-5 px-5">
            <img
              src={`data:image/png;base64,${signdata.qr_code}`}
              alt="unknown"
              width="130px"
              height="130px"
              className="mx-auto"
            />

            <h6>{signdata.sign_number}</h6>
          </div>

          <div className="signature__inner__card w-full py-5 px-5 bg-white rounded-lg">
            <div className="signature__inner_menu flex items-center justify-between">
              <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                <div className="block">
                  <div className="flex space-x-4">
                    <div
                      className={utils.classNames(
                        tabs === 0 ? "tab__active" : "",
                        "py-2 rounded-md text-xs sm:text-sm text-gray-500 font-medium tab__profile relative transition duration-150 ease-in-out"
                      )}
                      aria-current="page"
                      onClick={() => setTabs(0)}
                    >
                      Signer Info
                    </div>
                    <div
                      className={utils.classNames(
                        tabs === 1 ? "tab__active" : "",
                        "py-2 rounded-md text-xs sm:text-sm text-gray-500 font-medium tab__profile relative transition duration-150 ease-in-out"
                      )}
                      aria-current="page"
                      onClick={() => setTabs(1)}
                    >
                      Document Info
                    </div>
                    <div
                      className={utils.classNames(
                        tabs === 2 ? "tab__active" : "",
                        "py-2 rounded-md text-xs sm:text-sm text-gray-500 font-medium tab__profile relative transition duration-150 ease-in-out"
                      )}
                      aria-current="page"
                      onClick={() => setTabs(2)}
                    >
                      Document File
                    </div>
                    <div
                      className={utils.classNames(
                        tabs === 3 ? "tab__active" : "",
                        "py-2 rounded-md text-xs sm:text-sm text-gray-500 font-medium tab__profile relative transition duration-150 ease-in-out"
                      )}
                      aria-current="page"
                      onClick={() => setTabs(3)}
                    >
                      Sign History
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <SignatureMenu
                  pages={enumSignature.detail_colllaboration}
                  id={signdata.id}
                  data={signdata}
                />
              </div>
            </div>

            {tabs === 0 ? (
              <div className="signer__info">
                <div className="container mt-4">
                  <div className="flex flex-col">
                    <div className="-my-2 overflow-x-auto table__custom sm:-px-6 lg:-px-8">
                      <div className="py-2 align-middle inline-block min-w-full">
                        <table className="min-w-full divide-y divide-white-200">
                          <thead className="bg-white">
                            <tr>
                              <th
                                scope="col"
                                className="px-6 py-2 text-left text-sm font-extrabold text-gray-400 tracking-wider"
                              >
                                Signers
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-2 text-left text-sm font-extrabold text-gray-400 tracking-wider"
                              >
                                Status
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-2 text-left text-sm font-extrabold text-gray-400 tracking-wider"
                              >
                                Date | Time
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-2 text-left text-sm font-extrabold text-gray-400 tracking-wider"
                              >
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody className="bg-white divide-y divide-gray-200">
                            {signdata.signers && signdata.signers.length > 0 ? (
                              signdata.signers.map((item, idx) => (
                                <tr key={idx}>
                                  <td className="p-2 sm:px-6 sm:py-4 whitespace-nowrap">
                                    <div className="flex items-center">
                                      <AvatarStatus key={item.id} data={item} />
                                      <div className="ml-2 text-base font-bold text-gray-500">
                                        {item.name}
                                      </div>
                                    </div>
                                  </td>
                                  <td className="p-2 sm:px-6 sm:py-4 whitespace-nowrap">
                                    <StatusText status={item.status} />
                                  </td>
                                  <td className="p-2 sm:px-6 sm:py-4 whitespace-nowrap">
                                    <span className="text-base font-normal text-black">
                                      {signdata.initiated_on}
                                    </span>
                                  </td>
                                  <td className="p-2 sm:px-6 sm:py-4 whitespace-nowrap flex justify-between items-center">
                                    <StatusButton
                                      id={signdata.id}
                                      mainStatus={signdata.status}
                                      userStatus={item.status}
                                      reqSendFinal={true}
                                      data={item}
                                      docTitle={signdata.name}
                                    />
                                    <img
                                      onClick={() => setDeleteOpen(!deleteOpen)}
                                      className="ml-2 cursor-pointer"
                                      src={deleteIMG}
                                      alt="unknown"
                                    />

                                    <ModalConfirm
                                      open={deleteOpen}
                                      setOpen={setDeleteOpen}
                                      cancelButtonRef={deleteRef}
                                      text="Are you sure want to delete this signer?"
                                      callback={() =>
                                        handleDeleteSigner(item.id)
                                      }
                                    />
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <></>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : tabs === 1 ? (
              <div className="signer__document">
                <div className="signer__info_data">
                  <p>Document Name</p>
                  <h6>{signdata.name}</h6>
                </div>
                <div className="signer__info_data">
                  <p>Description</p>
                  <h6>{signdata.description}</h6>
                </div>
                <div className="grid lg:grid-cols-3 grid-cols-1">
                  <div className="signer__info_data">
                    <p>Document Category</p>
                    <h6>{signdata.category}</h6>
                  </div>
                  <div className="signer__info_data">
                    <p>Document Type</p>
                    <h6>{signdata.type}</h6>
                  </div>
                  <div className="signer__info_data">
                    <p>Status</p>
                    <h6>{signdata.information}</h6>
                  </div>
                </div>
              </div>
            ) : tabs === 2 ? (
              <div className="signer__file">
                <div className="signer__info_data">
                  <p>Document Link</p>
                  <h6>
                    <a target="_blank" href={signdata.file} rel="noreferrer">
                      {signdata.file}
                    </a>
                  </h6>
                </div>
                <div className="signer__info_data">
                  <p>Document File</p>
                  <a
                    download="true"
                    target="_blank"
                    rel="noreferrer"
                    href={signdata.file}
                  >
                    <div className="document__file">{signdata.name}</div>
                  </a>
                </div>
              </div>
            ) : tabs === 3 ? (
              <div className="sign__history">
                <div className="container mx-auto w-full h-full">
                  <div className="relative wrap overflow-hidden p-2 sm:p-6 h-full">
                    <div className="border-2-2 absolute border-opacity-20 border-gray-700 h-full border time__line"></div>

                    {histories.map((item, idx) => (
                      <div
                        className="mb-8 flex w-full right-timeline"
                        key={idx}
                      >
                        <div
                          className={utils.classNames(
                            idx === 0
                              ? "bg-blue-500"
                              : "bg-white border border-blue-500",
                            "absolute z-20 flex items-center order-1 shadow-xl w-3 h-3 rounded-full"
                          )}
                        ></div>
                        <div className="ml-3 order-1 px-6">
                          <h3 className="mb-1 font-bold text-gray-800 text-base sm:text-lg">
                            {item.message}
                          </h3>
                          <p className="text-xs sm:text-sm leading-snug tracking-wide text-gray-400 text-opacity-100">
                            {item.date}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        {signdata.rejected_reason !== null &&
          signdata.rejected_reason !== "" &&
          signdata.rejected_reason !== undefined && (
            <>
              <div className="signature__header">
                <h4 style={{ textTransform: "capitalize" }}>Rejected Reason</h4>
              </div>
              <div className="container-fluid bg-red-100 rounded-md p-4 mt-4 text-sm">
                <span className="text-blue-500 font-bold mr-1">Halo</span>
                <span>{signdata.rejected_reason}</span>
              </div>
            </>
          )}
      </div>
    </>
  );
};
