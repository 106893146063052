export const Start_ReqCounter_Action = (data) => {
  // console.log(data)
  localStorage.setItem("req_counter", JSON.stringify(data));
  return {
    type: "REQUEST_COUNTER_SUCCESS",
    payload: data,
  };
};

export const Done_ReqCounter_Action = () => {
  localStorage.removeItem("req_counter");
  return {
    type: "REQUEST_COUNTER_RESET",
  };
};
