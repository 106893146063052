import empty from "../../assets/images/empty.png";

export const NoAccess = () => {
  return (
    <div className="signature__empty">
      <img src={empty} alt="unknown" />
      <div className="text-lg font-bold text-center">
        You don't have any access for this document!
      </div>
    </div>
  );
};
