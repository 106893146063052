import { useEffect, useState } from "react"; 

export const Alerts = ({ toastList }) => {
  const [list, setList] = useState(toastList);
  useEffect(() => {
    setList(toastList);
  }, [toastList, list]);
  return (
    <div className="notification-container top-center">
      {list.length > 0 ? list.map((toast, i) => toast) : <></>}
    </div>
  );
};
