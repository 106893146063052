import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Start_TourSignatureAction } from "../../redux/action/signature";
import { CreateSignatureStep } from "../../tour-step/create-signature";
import { enumSignature } from "../../utility/utils";
import SignatureMenu from "../shared/signature-menu";
import Tour from "reactour";
import tutorial from "../../assets/images/tutorial.png";

const SignatureCard = ({ data }) => {
  const dispatch = useDispatch();
  const [tour, setTour] = useState(false);

  useEffect(() => {
    const local_tour_signature = JSON.parse(
      localStorage.getItem("tour_signature")
    );
    setTour(local_tour_signature === null && !local_tour_signature);
  }, []);

  const handleDone = () => {
    dispatch(Start_TourSignatureAction({ tour_signature: true }));
    setTour(false);
  };

  return (
    <>
      <Tour
        isOpen={tour}
        rounded={8}
        showCloseButton={false}
        closeWithMask={false}
        steps={CreateSignatureStep}
        disableDotsNavigation
        lastStepNextButton={
          <div
            className="btn__primary py-1 px-4 rounded-md"
            onClick={handleDone}
          >
            Selesai
          </div>
        }
      />
      <div className="signature__card_wrapper">
        <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3">
          <Link to="/dashboard/signature/add">
            <div
              data-tour="add-signature"
              className="signature__card py-5 px-5 bg-white rounded-lg flex flex-col justify-center items-center"
              style={{ height: "287px" }}
            >
              <svg
                width="84"
                height="84"
                viewBox="0 0 84 84"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M42 82C64.0914 82 82 64.0914 82 42C82 19.9086 64.0914 2 42 2C19.9086 2 2 19.9086 2 42C2 64.0914 19.9086 82 42 82Z"
                  stroke="#347AE2"
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M42 26V58"
                  stroke="#347AE2"
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M26 42H58"
                  stroke="#347AE2"
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <h6 className="mt-2">Add New Signature</h6>
            </div>
          </Link>

          {tour && (
            <img data-tour="menu-signature" src={tutorial} alt="unknown" />
          )}

          {data.map((item, idx) => (
            <Link to={`/dashboard/signature/detail/${item.id}`} key={idx}>
              <div key={item.id}>
                <div className="signature__card py-5 px-5 bg-white rounded-lg">
                  <SignatureMenu
                    pages={enumSignature.signature}
                    id={item.id}
                    data={item}
                  />
                  <img
                    src={`data:image/png;base64,${item.qr_code}`}
                    alt="unknown"
                    width="130px"
                    height="130px"
                  />
                  <h6>{item.name}</h6>
                  <span>{item.description}</span>

                  <div className="signature__card_footer">
                    <span className="label">{item.category}</span>
                    <span>{item.sign_number}</span>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </>
  );
};

export default SignatureCard;
