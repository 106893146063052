import React from "react";
import "./auth.css";
import ornL from "../../assets/ornament/auth-left.svg";
import ornR from "../../assets/ornament/auth-right.svg";
import logo from "../../assets/images/logo-thumb.svg";
import { Link } from "react-router-dom";

function Forgot() {
  return (
    <>
      <div className="auth__wrapper">
        <img src={ornL} alt="unknown" className="auth__ornL" />
        <img src={ornR} alt="unknown" className="auth__ornR" />

        <div className="auth__form">
          <img src={logo} alt="unknown" />
          <h1>Lupa Password?</h1>
          <p>
            Sudah memiliki akun? <Link to="/login">Masuk disini</Link>
          </p>

          <div className="auth__input">
            {/* Input Nama */}
            <div className="relative flex w-full flex-wrap items-stretch mb-3">
              <span className="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3">
                <i className="fas fa-lock"></i>
              </span>
              <input
                type="text"
                placeholder="Nama Lengkap"
                className="px-3 py-3 w-full pl-10"
              />
            </div>

            {/* Input Email */}
            <div className="relative flex w-full flex-wrap items-stretch mb-3">
              <span className="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3">
                <i className="fas fa-lock"></i>
              </span>
              <input
                type="text"
                placeholder="Email"
                className="px-3 py-3 w-full pl-10"
              />
            </div>

            {/* Input Password */}
            <div className="relative flex w-full flex-wrap items-stretch mb-3">
              <span className="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3">
                <i className="fas fa-lock"></i>
              </span>
              <input
                type="password"
                placeholder="Password"
                className="px-3 py-3 w-full pl-10"
              />
            </div>
          </div>

          <div className="auth__submit">
            <button className="login__btn">Register</button>
          </div>

          {/* <div className="auth__divider">
            <span>Atau</span>
          </div>

          <div className="auth__google">
            <button>
              <img src={google} alt="unknown" /> Sign in with Google
            </button>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default Forgot;
