export const AvatarStatus = ({ data }) => {
  const IconStatus = ({ status }) => {
    if (status === "accept") {
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            margin: "0px",
          }}
        >
          <circle cx="6" cy="6.5" r="5.5" fill="white" stroke="white" />
          <path
            d="M6 1.5C6.45898 1.5 6.90006 1.56022 7.32324 1.68066C7.74642 1.80111 8.14355 1.97038 8.51465 2.18848C8.88574 2.40658 9.22266 2.66862 9.52539 2.97461C9.83138 3.27734 10.0934 3.61426 10.3115 3.98535C10.5296 4.35645 10.6989 4.75358 10.8193 5.17676C10.9398 5.59994 11 6.04102 11 6.5C11 6.95573 10.9398 7.39681 10.8193 7.82324C10.6989 8.24642 10.5296 8.64355 10.3115 9.01465C10.0934 9.38574 9.83138 9.72428 9.52539 10.0303C9.22266 10.333 8.88574 10.5934 8.51465 10.8115C8.14355 11.0296 7.74479 11.1989 7.31836 11.3193C6.89518 11.4398 6.45573 11.5 6 11.5C5.54427 11.5 5.10319 11.4398 4.67676 11.3193C4.25358 11.1989 3.85645 11.0296 3.48535 10.8115C3.11426 10.5934 2.77572 10.333 2.46973 10.0303C2.16699 9.72428 1.90658 9.38574 1.68848 9.01465C1.47038 8.64355 1.30111 8.24642 1.18066 7.82324C1.06022 7.39681 1 6.95573 1 6.5C1 6.04427 1.06022 5.60482 1.18066 5.18164C1.30111 4.75521 1.47038 4.35645 1.68848 3.98535C1.90658 3.61426 2.16699 3.27734 2.46973 2.97461C2.77572 2.66862 3.11426 2.40658 3.48535 2.18848C3.85645 1.97038 4.25358 1.80111 4.67676 1.68066C5.10319 1.56022 5.54427 1.5 6 1.5ZM7.64551 5.05469C7.5153 5.05469 7.40462 5.10026 7.31348 5.19141L5.5459 6.96875L4.91602 6.34375C4.82487 6.2526 4.71582 6.20703 4.58887 6.20703C4.52702 6.20703 4.46842 6.22005 4.41309 6.24609C4.35775 6.27214 4.30729 6.30794 4.26172 6.35352C4.2194 6.39583 4.18522 6.44466 4.15918 6.5C4.13314 6.55534 4.12012 6.61393 4.12012 6.67578C4.12012 6.80599 4.16569 6.91667 4.25684 7.00781L5.21387 7.96484C5.30501 8.05599 5.41569 8.10156 5.5459 8.10156C5.67611 8.10156 5.78678 8.05599 5.87793 7.96484L7.97754 5.85547C8.06869 5.76432 8.11426 5.65365 8.11426 5.52344C8.11426 5.46159 8.10124 5.40299 8.0752 5.34766C8.04915 5.29232 8.01335 5.24349 7.96777 5.20117C7.92546 5.1556 7.87663 5.11979 7.82129 5.09375C7.76595 5.06771 7.70736 5.05469 7.64551 5.05469Z"
            fill="#05A660"
          />
        </svg>
      );
    } else if (
      status === "reject" ||
      status === "rejected" ||
      status === "decline"
    ) {
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            margin: "0px",
          }}
        >
          <circle cx="6.5" cy="6.5" r="5.5" fill="white" stroke="white" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.5 1.5C3.73864 1.5 1.5 3.73864 1.5 6.5C1.5 9.26136 3.73864 11.5 6.5 11.5C9.26136 11.5 11.5 9.26136 11.5 6.5C11.5 3.73864 9.26136 1.5 6.5 1.5ZM8.185 5.45773C8.2678 5.372 8.31361 5.25718 8.31258 5.138C8.31154 5.01882 8.26374 4.90481 8.17946 4.82054C8.09519 4.73626 7.98118 4.68846 7.862 4.68742C7.74282 4.68639 7.628 4.7322 7.54227 4.815L6.5 5.85727L5.45773 4.815C5.4158 4.77159 5.36564 4.73696 5.31018 4.71314C5.25473 4.68931 5.19508 4.67677 5.13473 4.67625C5.07437 4.67573 5.01452 4.68723 4.95866 4.71008C4.9028 4.73294 4.85204 4.76669 4.80937 4.80937C4.76669 4.85204 4.73294 4.9028 4.71008 4.95866C4.68723 5.01452 4.67573 5.07437 4.67625 5.13473C4.67677 5.19508 4.68931 5.25473 4.71314 5.31018C4.73696 5.36564 4.77159 5.4158 4.815 5.45773L5.85727 6.5L4.815 7.54227C4.77159 7.5842 4.73696 7.63436 4.71314 7.68982C4.68931 7.74527 4.67677 7.80492 4.67625 7.86527C4.67573 7.92563 4.68723 7.98548 4.71008 8.04134C4.73294 8.0972 4.76669 8.14796 4.80937 8.19063C4.85204 8.23331 4.9028 8.26706 4.95866 8.28992C5.01452 8.31277 5.07437 8.32428 5.13473 8.32375C5.19508 8.32323 5.25473 8.31069 5.31018 8.28686C5.36564 8.26304 5.4158 8.22841 5.45773 8.185L6.5 7.14273L7.54227 8.185C7.628 8.2678 7.74282 8.31361 7.862 8.31258C7.98118 8.31154 8.09519 8.26374 8.17946 8.17946C8.26374 8.09519 8.31154 7.98118 8.31258 7.862C8.31361 7.74282 8.2678 7.628 8.185 7.54227L7.14273 6.5L8.185 5.45773Z"
            fill="#E63535"
          />
        </svg>
      );
    } else {
      return (
        <svg
          width="13"
          height="12"
          viewBox="0 0 13 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            margin: "0px",
          }}
        >
          <path
            d="M7 2.5C8.32608 2.5 9.59785 3.02678 10.5355 3.96447C11.4732 4.90215 12 6.17392 12 7.5C12 8.82608 11.4732 10.0979 10.5355 11.0355C9.59785 11.9732 8.32608 12.5 7 12.5C5.67392 12.5 4.40215 11.9732 3.46447 11.0355C2.52678 10.0979 2 8.82608 2 7.5C2 6.17392 2.52678 4.90215 3.46447 3.96447C4.40215 3.02678 5.67392 2.5 7 2.5ZM6.5 5C6.36739 5 6.24021 5.05268 6.14645 5.14645C6.05268 5.24021 6 5.36739 6 5.5V8C6 8.13261 6.05268 8.25979 6.14645 8.35355C6.24021 8.44732 6.36739 8.5 6.5 8.5H8.5C8.63261 8.5 8.75979 8.44732 8.85355 8.35355C8.94732 8.25979 9 8.13261 9 8C9 7.86739 8.94732 7.74021 8.85355 7.64645C8.75979 7.55268 8.63261 7.5 8.5 7.5H7V5.5C7 5.36739 6.94732 5.24021 6.85355 5.14645C6.75979 5.05268 6.63261 5 6.5 5Z"
            fill="black"
          />
          <circle cx="6.5" cy="6.5" r="5.5" fill="white" stroke="white" />
          <path
            d="M6.5 1.5C7.82608 1.5 9.09785 2.02678 10.0355 2.96447C10.9732 3.90215 11.5 5.17392 11.5 6.5C11.5 7.82608 10.9732 9.09785 10.0355 10.0355C9.09785 10.9732 7.82608 11.5 6.5 11.5C5.17392 11.5 3.90215 10.9732 2.96447 10.0355C2.02678 9.09785 1.5 7.82608 1.5 6.5C1.5 5.17392 2.02678 3.90215 2.96447 2.96447C3.90215 2.02678 5.17392 1.5 6.5 1.5ZM6 4C5.86739 4 5.74021 4.05268 5.64645 4.14645C5.55268 4.24021 5.5 4.36739 5.5 4.5V7C5.5 7.13261 5.55268 7.25979 5.64645 7.35355C5.74021 7.44732 5.86739 7.5 6 7.5H8C8.13261 7.5 8.25979 7.44732 8.35355 7.35355C8.44732 7.25979 8.5 7.13261 8.5 7C8.5 6.86739 8.44732 6.74021 8.35355 6.64645C8.25979 6.55268 8.13261 6.5 8 6.5H6.5V4.5C6.5 4.36739 6.44732 4.24021 6.35355 4.14645C6.25979 4.05268 6.13261 4 6 4Z"
            fill="#FFAE00"
          />
        </svg>
      );
    }
  };

  return (
    <div className="h-8 w-8 sm:h-10 sm:w-10 relative ml-2">
      <img
        className="rounded-full w-full h-full object-cover"
        style={{
          margin: "0px",
        }}
        src={
          data.photo
            ? data.photo
            : "https://icon-library.com/images/windows-user-icon/windows-user-icon-14.jpg"
        }
        alt="unknown"
      />
      <div className="absolute right-0 bottom-0">
        <IconStatus status={data.status} />
      </div>
    </div>
  );
};
