import { useState } from "react";
import Navbar from "../components/navbar";
import Sidebar from "../components/sidebar";
import { ChildRoutes } from "../Routes";
import "./index.css";

const Layout = () => {
  const innerWidth = window.innerWidth;
  const [isOpen, setIsOpen] = useState(innerWidth > 753);
  const [isVerified, setVerified] = useState(false);

  return (
    <div id="layout" className="h-screen">
      {isOpen && (
        <Sidebar
          setOpenSidebar={setIsOpen}
          sidebarOpen={isOpen}
          verified={isVerified}
        />
      )}
      <div className="absolute right-0 right-section">
        <Navbar
          setOpenSidebar={setIsOpen}
          sidebarOpen={isOpen}
          setVerified={setVerified}
        />
        <ChildRoutes />
      </div>
    </div>
  );
};

export default Layout;
