export const Start_TourSignatureAction = (data) => {
  // console.log(data)
  localStorage.setItem("tour_signature", JSON.stringify(data));
  return {
    type: "TOUR_SIGNATURE_DONE",
    payload: data,
  };
};

export const Done_TourSignatureAction = () => {
  localStorage.removeItem("tour_signature");
  return {
    type: "TOUR_SIGNATURE_RESET",
  };
};
