import { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo-thumb.svg";
import { postData } from "../../utility/network";
import utils, { print } from "../../utility/utils";
import { AlertComponent } from "../alerts/alert-component";
import { useCustomAlert } from "../alerts/alert-provider";

export const InputEmailForgot = ({
  loading,
  setLoading,
  changeComponent,
  setUser,
}) => {
  const [email, setEmail] = useState("");
  const [setList] = useCustomAlert();
  const [errors, setErrors] = useState({ email: "" });

  const handleSendEmail = () => {
    let abortController = new AbortController();
    setLoading(true);

    let kosong = false;
    let err = {
      email: "",
    };
    setErrors(err);

    // email validation
    if (email.length === 0) {
      err.email = `Email field can't be empty!`;
      kosong = true;
      setLoading(false);
    }
    if (!utils.validateEmail(email)) {
      err.email = `Email doesn't valid!`;
      kosong = true;
      setLoading(false);
    }

    setErrors(err);

    if (!loading && !kosong) {
      const body = {
        email: email,
      };

      let key = utils.randomAlphaNumeric();

      postData(
        "forgot-password",
        body,
        (data) => {
          setList([
            <AlertComponent
              key={key}
              type="success"
              msg="Tolong cek berkala pada email anda untuk mendapatkan kode verifikasi!"
            />,
          ]);
          setLoading(false);
          changeComponent(1);
          setUser(email);
        },
        (msg, errors) => {
          print(errors);
          setList([
            <AlertComponent key={key} type="error" msg={errors.message} />,
          ]);
        },
        () => {
          // you can put loading here
          setLoading(false);
          abortController.abort();
          //print("ON FINISHED");
        }
      );
    }
  };

  const handleChange = (e) => {
    const value = e.currentTarget.value;

    setEmail(value);
  };

  return (
    <>
      <img src={logo} alt="unknown" />
      <h2 className="text-xl font-bold">Forgot Password</h2>
      <p>
        Kembali? <Link to="/login">Klik di sini</Link>
      </p>

      <div className="auth__input">
        <div className="relative flex w-full flex-wrap items-stretch mb-3">
          <span className="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              style={{ opacity: "0.3" }}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
              />
            </svg>
          </span>
          <input
            type="email"
            placeholder="Email"
            name="email"
            onChange={handleChange}
            className="px-3 py-3 w-full pl-12"
          />
          <small className="text-red-500">{errors.email}</small>
        </div>
      </div>

      <div className="auth__submit">
        <button onClick={handleSendEmail} className="login__btn">
          Send
        </button>
      </div>
    </>
  );
};
