import { Listbox, Transition } from "@headlessui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import { CheckIcon, ChevronDownIcon, SearchIcon } from "@heroicons/react/solid";
import { Fragment, useEffect, useState } from "react";
import CardContact from "../../components/contact/card-contact";
import utils, { print } from "../../utility/utils";
import emptyState from "../../assets/images/empty-contact.png";
import { getData } from "../../utility/network";
import { useCustomAlert } from "../../components/alerts/alert-provider";
import { AlertComponent } from "../../components/alerts/alert-component";
import { LoadingBarier } from "../../components/loading-barrier";

const data = [
  { id: 1, name: "Latest", unavailable: false },
  { id: 2, name: "Oldest", unavailable: false },
  { id: 3, name: "A-Z", unavailable: false },
  { id: 4, name: "Z-A", unavailable: true },
];

const Contact = () => {
  const [selected, setSelectedPerson] = useState(data[0]);
  const [contacts, setContacts] = useState([]);
  const [currpage, setCurrentPage] = useState(1);
  const [totalpage, setTotalPage] = useState(0);
  const [totalItem, setTotalItem] = useState(1);
  const [currentItem, setCurrentItem] = useState(1);
  const [setList] = useCustomAlert();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    document.title = "Teken | My Contact";

    let key = utils.randomAlphaNumeric();
    let qParams = { page: currpage };

    setLoading(true);

    getData(
      "user/my-contact-web",
      qParams,
      (data) => {
        setContacts(data.data);
        setCurrentPage(data.from);
        setTotalItem(data.per_page);
        setTotalPage(data.last_page);
        setCurrentItem(data.to);
      },
      (msg, error) => {
        print(error);
        setList([
          <AlertComponent key={key} type="error" msg={error.message} />,
        ]);
      },
      () => {
        //print("Fetch Contacts Finished");
        setLoading(false);
      }
    );
  }, [setList, currpage]);

  const PageNumberItem = () => {
    let child = [];
    for (var i = 1; i <= totalpage; i++) {
      child.push(
        <div
          key={i}
          // eslint-disable-next-line no-loop-func
          onClick={() => setCurrentPage(i)}
          className={utils.classNames(
            currpage === i ? "btn__primary" : "btn__outline_gray1",
            "mx-1 rounded-md w-10 h-10 shadow-md flex justify-center items-center"
          )}
        >
          {i}
        </div>
      );
    }

    return <>{child}</>;
  };

  const handlePageArrow = (condition) => {
    if (condition) {
      if (currpage < totalpage) {
        setCurrentPage(currpage + 1);
      }
    } else {
      if (currpage > 1) {
        setCurrentPage(currpage - 1);
      }
    }
  };

  const handleSearch = (e) => {
    let q = e.target.value;
    let key = utils.randomAlphaNumeric();

    if (q.length % 3 === 0) {
      let qParams = { page: 1, search: q };

      getData(
        "user/my-contact-web",
        qParams,
        (data) => {
          setContacts(data.data);
          setCurrentPage(data.from);
          setTotalItem(data.per_page);
          setTotalPage(data.last_page);
          setCurrentItem(data.to);
        },
        (msg, error) => {
          print(error);
          setList([
            <AlertComponent key={key} type="error" msg={error.message} />,
          ]);
        },
        () => {
          //print("Fetch Contacts Finished");
        }
      );
    }
  };

  return (
    <>
      {loading && <LoadingBarier />}
      <div className="px-4 py-2 sm:py-6 sm:px-12 relative">
        <div className="text-2xl sm:text-3xl font-extrabold">Contact</div>
        <div className="sm:flex justify-between align-center mt-2 sm:mt-10">
          <div className="flex rounded-md h-8 sm:h-12 bg-white custom__shadow_1 w-full sm:w-min">
            <span className="inline-flex items-center px-3 rounded-l-md border-r-0 text-gray-500 text-sm">
              <SearchIcon width="18px" />
            </span>
            <input
              type="text"
              name="search"
              onChange={handleSearch}
              className="flex-1 block w-80 h-8 sm:h-12 rounded-r-md sm:text-sm focus:outline-none px-2"
              placeholder="Search"
            />
          </div>

          <div className="flex items-center sm:mt-0 mt-2">
            <div>
              <Listbox value={selected} onChange={setSelectedPerson}>
                {({ open }) => (
                  <>
                    <div className="relative text__color_primary">
                      <Listbox.Button className="relative h-8 sm:h-10 bg-blue-100 border rounded-md pl-3 pr-10 py-1 sm:py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-blue-400 sm:text-sm">
                        <span className="flex items-center">
                          <span className="text-sm sm:text-base ml-3 block truncate">
                            {selected.name}
                          </span>
                        </span>
                        <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                          <ChevronDownIcon
                            className="h-5 w-5 text__color_primary"
                            aria-hidden="true"
                          />
                        </span>
                      </Listbox.Button>

                      <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute z-10 w-full checkbox__dashboard max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                          {data.map((person) => (
                            <Listbox.Option
                              key={person.id}
                              className={({ active }) =>
                                utils.classNames(
                                  active
                                    ? "text-white bg-blue-500"
                                    : "text__color_primary",
                                  "cursor-default select-none relative py-2 pl-3 pr-9"
                                )
                              }
                              value={person}
                            >
                              {({ selected, active }) => (
                                <>
                                  <div className="flex items-center">
                                    <span
                                      className={utils.classNames(
                                        selected
                                          ? "font-semibold"
                                          : "font-normal",
                                        "text-sm sm:text-base ml-3 block truncate"
                                      )}
                                    >
                                      {person.name}
                                    </span>
                                  </div>

                                  {selected ? (
                                    <span
                                      className={utils.classNames(
                                        active ? "text-white" : "text-blue-500",
                                        "absolute inset-y-0 right-0 flex items-center pr-4"
                                      )}
                                    >
                                      <CheckIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </>
                )}
              </Listbox>
            </div>
          </div>
        </div>
        {contacts.length === 0 ? (
          <div className="signature__empty">
            <img src={emptyState} alt="unknown" />
            <h6>You do not have any contact</h6>
          </div>
        ) : (
          <>
            <div className="grid sm:grid-cols-3 gap-4 mt-8">
              {contacts.map((item, idx) => (
                <CardContact data={item} key={idx} />
              ))}
            </div>
            <div className="mt-10 flex justify-between align-center">
              <div className="flex">
                <div className="text-sm text-gray-400">
                  Showing <span className="text-black">{currentItem}</span> from
                  <span className="text-black">{totalItem}</span> data
                </div>
              </div>
              <div className="flex">
                <div
                  onClick={() => currpage > 1 && handlePageArrow(false)}
                  className={utils.classNames(
                    currpage > 1
                      ? "btn__outline_gray1"
                      : "btn__outline_disable",
                    "mx-1 rounded-md w-10 h-10 flex justify-center items-center"
                  )}
                >
                  <ChevronLeftIcon width="20px" />
                </div>
                <PageNumberItem />
                <div
                  onClick={() => currpage < totalpage && handlePageArrow(true)}
                  className={utils.classNames(
                    currpage < totalpage
                      ? "btn__outline_gray1"
                      : "btn__outline_disable",
                    "mx-1 rounded-md w-10 h-10 flex justify-center items-center"
                  )}
                >
                  <ChevronRightIcon width="20px" />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Contact;
