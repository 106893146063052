export const SidebarStep = [
  {
    selector: '[data-tour="signature"]',
    content: (
      <>
        Menu <i>signature</i> untuk melihat daftar <i>signature</i> kamu dan
        membuat <i>signature</i> baru
      </>
    ),
  },
  {
    selector: '[data-tour="collaboration"]',
    content: (
      <>
        Menu <i>signature collaboration</i> untuk melihat daftar{" "}
        <i>signature collaboration</i> kamu dan membuat{" "}
        <i>signature collaboration</i> baru dengan orang lain
      </>
    ),
  },
];
