import "./dashboard.css";
import { useEffect, useState } from "react";
import BannerDashboard from "../../components/dashboard/banner";
import MySignatureSection from "../../components/dashboard/my-signature";
import MyRequestSection from "../../components/dashboard/my-request";
import { useCustomAlert } from "../../components/alerts/alert-provider";
import { getData } from "../../utility/network";
import utils, { print } from "../../utility/utils";
import { AlertComponent } from "../../components/alerts/alert-component";
import emptyState from "../../assets/images/empty-request.png";
import { LoadingBarier } from "../../components/loading-barrier";
import { useCustomRefresh } from "../../utility/refresh-provider";
import { useDispatch } from "react-redux";
import { Start_ReqCounter_Action } from "../../redux/action/request-counter";

const people = [
  { id: 1, name: "Durward Reynolds", unavailable: false },
  { id: 2, name: "Kenton Towne", unavailable: false },
  { id: 3, name: "Therese Wunsch", unavailable: false },
  { id: 4, name: "Benedict Kessler", unavailable: true },
  { id: 5, name: "Katelyn Rohan", unavailable: false },
];

const Dashboard = () => {
  const [selectedPerson, setSelectedPerson] = useState(people[0]);
  const [setList] = useCustomAlert();
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refresher] = useCustomRefresh();
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = "Teken | Dashboard";
    // get user data
    let key = utils.randomAlphaNumeric();
    setLoading(true);

    if (refresher) {
      getData(
        "sign/my-request",
        {},
        (data) => {
          let res =
            data.data.length > 3
              ? data.data.slice(0, 3)
              : data.data.slice(0, data.data.length);
          setRequests(res);
          dispatch(Start_ReqCounter_Action({ req_counter: data.data.length }));
        },
        (msg, error) => {
          print(error);
          setList([
            <AlertComponent key={key} type="error" msg={error.message} />,
          ]);
        },
        () => {
          //print("Fetch counter in Home Finished");
          setLoading(false);
        }
      );
    }
  }, [setList, refresher, dispatch]);

  return (
    <>
      {loading && <LoadingBarier />}
      <div className="py-5 px-6 sm:py-6 sm:px-12">
        <BannerDashboard />
        <MySignatureSection
          selected={selectedPerson}
          data={people}
          callbackSelect={setSelectedPerson}
        />
        {requests.length === 0 ? (
          <div className="signature__empty py-16 sm:py-32">
            <img src={emptyState} alt="unknown" />
            <h6>You do not have any request</h6>
          </div>
        ) : (
          <MyRequestSection data={requests} />
        )}
      </div>
    </>
  );
};

export default Dashboard;
