import { useRef, useState } from "react";
import { putData } from "../../utility/network";
import { useCustomRefresh } from "../../utility/refresh-provider";
import utils, { print } from "../../utility/utils";
import { AlertComponent } from "../alerts/alert-component";
import { useCustomAlert } from "../alerts/alert-provider";
import { LoadingBarier } from "../loading-barrier";
import { StatusSignBox } from "../shared/status-sign-box";
import { DeclineModal } from "./modal-decline";

export const FooterRequest = ({ data }) => {
  const [loading, setLoading] = useState(false);
  const [setList] = useCustomAlert();
  const [open, setOpen] = useState(false);
  //reload data trigger by the child
  const [refresher, setRefresher] = useCustomRefresh();
  const cancelButtonRef = useRef(null);

  const handleRequestAction = (act) => {
    // handle when click the button for many times
    if (act === "decline") {
      return;
    }

    setRefresher(false);

    if (!loading) {
      setLoading(true);

      const body = {};
      let key = utils.randomAlphaNumeric();

      putData(
        "sign/my-request/" + data.id + "/" + act,
        body,
        (data) => {
          setList([
            <AlertComponent
              key={key}
              type="success"
              msg={`Signature has been ${act}!`}
            />,
          ]);

          print(refresher);
        },
        (msg, error) => {
          print(error);
          setList([
            <AlertComponent
              key={key}
              type="error"
              msg={error.message.feedback[0]}
            />,
          ]);
        },
        () => {
          setLoading(false);
          setRefresher(true);
        }
      );
    }
  };

  return (
    <>
      {data.status != null ? (
        <StatusSignBox status={data.status} msg={data.status} />
      ) : (
        data.can_sign && (
          <>
            {loading && <LoadingBarier />}

            <DeclineModal
              cancelButtonRef={cancelButtonRef}
              open={open}
              setOpen={setOpen}
              id={data.id}
            />

            <div className="flex">
              <div
                onClick={(event) => {
                  event.preventDefault();
                  handleRequestAction("accept");
                }}
                className="mr-1 btn__primary font-semibold flex items-center justify-center text-sm rounded-md shadow-sm w-full py-1 "
              >
                Accept
              </div>
              <div
                onClick={(event) => {
                  event.preventDefault();
                  setOpen(true);
                }}
                className="ml-1 btn__outline_red font-semibold flex items-center justify-center text-sm rounded-md shadow-sm w-full py-1 "
              >
                Decline
              </div>
            </div>
          </>
        )
      )}
    </>
  );
};
