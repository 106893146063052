import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline";
import { useState } from "react";
import { AlertComponent } from "../../components/alerts/alert-component";
import { useCustomAlert } from "../../components/alerts/alert-provider";
import { putData } from "../../utility/network";
import utils, { print } from "../../utility/utils";

const ChangePassword = () => {
  const [cur_password, setCurrent] = useState(false);
  const [new_password, setNew] = useState(false);
  const [re_password, setReEnter] = useState(false);
  const [val_curpasw, setValueCurpass] = useState("");
  const [val_newpasw, setValueNewpass] = useState("");
  const [val_repasw, setValueRepass] = useState("");
  const [loading, setLoading] = useState(false);
  const [setList] = useCustomAlert();

  const handleChangePassword = () => {
    // handle when click the button for many times
    if (!loading) {
      setLoading(true);

      const body = {
        current_password: val_curpasw,
        password: val_newpasw,
        password_confirmation: val_repasw,
      };

      let key = utils.randomAlphaNumeric();
      putData(
        "user/change-password",
        body,
        (data) => {
          setList([
            <AlertComponent
              key={key}
              type="success"
              msg={"Password has been changed!"}
            />,
          ]);
        },
        (msg, error) => {
          print(error);
          setList([
            <AlertComponent key={key} type="error" msg={error.message} />,
          ]);
        },
        () => {
          setLoading(false);
          setValueCurpass("");
          setValueNewpass("");
          setValueRepass("");
        }
      );
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.currentTarget;
    switch (name) {
      case "cur_password":
        setValueCurpass(value);
        break;

      case "new_password":
        setValueNewpass(value);
        break;

      case "re_password":
        setValueRepass(value);
        break;

      default:
        break;
    }
  };

  return (
    <div className="sm:mt-10">
      <div className="mt-6">
        <label
          htmlFor="price"
          className="block text-sm font-medium text-gray-700"
        >
          Current Password
        </label>
        <div className="mt-1 relative rounded-md shadow-sm">
          <input
            type={cur_password ? "text" : "password"}
            name="cur_password"
            onChange={handleChange}
            className="input__profile font-bold block px-2 py-1 sm:py-2 sm:px-4 w-full focus:bg-white sm:text-base"
          />
          <div
            onClick={() => setCurrent(!cur_password)}
            className="absolute inset-y-0 right-2 cursor-pointer flex items-center"
          >
            {cur_password ? (
              <EyeIcon className="text-gray-400" width="20px" height="20px" />
            ) : (
              <EyeOffIcon
                className="text-gray-400"
                width="20px"
                height="20px"
              />
            )}
          </div>
        </div>
      </div>
      <div className="mt-6">
        <label
          htmlFor="price"
          className="block text-sm font-medium text-gray-700"
        >
          New Password
        </label>
        <div className="mt-1 relative rounded-md shadow-sm">
          <input
            type={new_password ? "text" : "password"}
            name="new_password"
            onChange={handleChange}
            className="input__profile font-bold block px-2 py-1 sm:py-2 sm:px-4 w-full focus:bg-white sm:text-base"
          />
          <div
            onClick={() => setNew(!new_password)}
            className="absolute inset-y-0 right-2 cursor-pointer flex items-center"
          >
            {new_password ? (
              <EyeIcon className="text-gray-400" width="20px" height="20px" />
            ) : (
              <EyeOffIcon
                className="text-gray-400"
                width="20px"
                height="20px"
              />
            )}
          </div>
        </div>
      </div>
      <div className="mt-6">
        <label
          htmlFor="price"
          className="block text-sm font-medium text-gray-700"
        >
          Re-enter New Password
        </label>
        <div className="mt-1 relative rounded-md shadow-sm">
          <input
            type={re_password ? "text" : "password"}
            name="re_password"
            onChange={handleChange}
            className="input__profile font-bold block px-2 py-1 sm:py-2 sm:px-4 w-full focus:bg-white sm:text-base"
          />
          <div
            onClick={() => setReEnter(!re_password)}
            className="absolute inset-y-0 right-2 cursor-pointer flex items-center"
          >
            {re_password ? (
              <EyeIcon className="text-gray-400" width="20px" height="20px" />
            ) : (
              <EyeOffIcon
                className="text-gray-400"
                width="20px"
                height="20px"
              />
            )}
          </div>
        </div>
      </div>
      <div className="mt-10 flex justify-end">
        <button
          onClick={handleChangePassword}
          className="btn__primary rounded-md flex px-4 py-1 items-center"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H16L21 8V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21Z"
              stroke="white"
              strokeWidth="1.3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17 21V13H7V21"
              stroke="white"
              strokeWidth="1.3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7 3V8H15"
              stroke="white"
              strokeWidth="1.3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span className="ml-2 text-sm font-bold">Save</span>
        </button>
      </div>
    </div>
  );
};

export default ChangePassword;
