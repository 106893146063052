const SignatureComponent = ({ count, name, child }) => {
  return (
    <div className="mb-2 sm:mb-0 flex px-2 sm:px-3 justify-between items-center bg-white sm:bg-transparent rounded-md sm:rounded-none py-1">
      <div>
        <div className="text-md sm:text-xl font-extrabold">{count}</div>
        <div className="text-xs sm:text-base font-normal">{name}</div>
      </div>
      {child}
    </div>
  );
};

export default SignatureComponent;
