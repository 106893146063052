import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { getData, postData } from "../../utility/network";
import { useCustomRefresh } from "../../utility/refresh-provider";
import utils, { print } from "../../utility/utils";
import { AlertComponent } from "../alerts/alert-component";
import { useCustomAlert } from "../alerts/alert-provider";

export const AddSignerModal = ({ setOpen, open, cancelButtonRef, id }) => {
  const [setList] = useCustomAlert();
  const [errorInput, setErrorInput] = useState({
    expired_date_at: "",
    expired_time_at: "",
  });
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [signers, setSigners] = useState([]);
  const [users, setUsers] = useState([]);
  const [signdata, setSignData] = useState({
    expired_date_at: "",
    expired_time_at: "",
  });
  const [focus, setFocus] = useState(false);
  const [refresher, setRefresher] = useCustomRefresh();

  useEffect(() => {
    let key = utils.randomAlphaNumeric();

    if (open) {
      getData(
        "signers",
        {},
        (data) => {
          setUsers(data.data);
        },
        (msg, error) => {
          print(error);
          setList([
            <AlertComponent key={key} type="error" msg={error.message} />,
          ]);
        },
        () => {
          //print("Fetch search peoples Finished");
        }
      );
    }
  }, [setList, open]);

  const handleInviteSigner = () => {
    let kosong = false;
    let err = {
      file: "",
    };

    setErrorInput(err);

    if (!loading && !kosong && !isError) {
      setRefresher(false);
      setLoading(true);

      const bodyForm = new FormData();
      signers.map((item) => bodyForm.append("email[]", item.email));
      bodyForm.append("expired_date_at", signdata.expired_date_at);
      bodyForm.append("expired_time_at", signdata.expired_time_at);

      let key = utils.randomAlphaNumeric();

      postData(
        `document/${id}/invite-signer`,
        bodyForm,
        (data) => {
          setList([
            <AlertComponent
              key={key}
              type="success"
              msg="Success to invite the signer!"
            />,
          ]);
          setOpen(false);
        },
        (msg, error) => {
          print(error);
          setList([
            <AlertComponent key={key} type="error" msg={error.message} />,
          ]);
        },
        () => {
          //print("Finished");
          setLoading(false);
          setRefresher(true);
          print(refresher);
        }
      );
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.currentTarget;
    let err = {
      expired_date_at: "",
      expired_time_at: "",
    };

    if (name === "user_email") {
      if (!utils.validateEmail(value)) {
        err = utils.parseObject(err, name, `Invalid email address!`);
        setErrorInput(err);
        setIsError(true);
      }
    }

    if (utils.checkEmptyValue(value)) {
      let errName = name.replace("_", " ");
      err = utils.parseObject(err, name, `${errName} can't be empty!`);
      setIsError(true);
    } else {
      // parsing the input that user has filled
      const newData = utils.parseObject(signdata, name, value);
      setSignData(newData);
      setIsError(false);
    }

    setErrorInput(err);
  };

  const addSigner = (data) => {
    if (!signers.includes(data)) setSigners([...signers, data]);
    setFocus(false);
  };

  const deleteSigners = (idx) => {
    signers.splice(idx, 1);
    setSigners([...signers]);
  };

  const CustomOption = ({ data, callback }) => {
    return (
      <div
        className="flex items-center py-1 px-2 cursor-pointer hover:bg-blue-500"
        onClick={callback}
      >
        <img
          src={
            data.photo
              ? data.photo
              : "https://icon-library.com/images/windows-user-icon/windows-user-icon-14.jpg"
          }
          alt="unknown"
          className="flex-shrink-0 h-8 w-8 rounded-full"
        />
        <div className="text-left">
          <span className="font-normal ml-3 block truncate text-base ">
            {data.name}
          </span>
          <span className="font-normal ml-3 block truncate text-gray-400 text-xs">
            {data.email}
          </span>
        </div>
      </div>
    );
  };

  // TODO : set state reset the focus
  const InputSigner = () => {
    const [search, setSearch] = useState(users);

    const handleSearch = (e) => {
      let q = e.target.value;

      if (q.length > 2) {
        let res = users.filter((item) => {
          return item.name.includes(q) || item.email.includes(q);
        });

        setSearch(res);

        // getData(
        //   "signers",
        //   { search: q },
        //   (data) => {
        //     setUsers(data.data);
        //     setFocus(true);
        //   },
        //   (msg, error) => {
        //     print(error);
        //     setList([
        //       <AlertComponent key={key} type="error" msg={error.message} />,
        //     ]);
        //   },
        //   () => {
        //     print("Fetch search peoples Finished");
        //   }
        // );
      }
    };

    return (
      <div className="relative">
        <div className="grid signature__input">
          <label>
            Signers Email<span>*</span>
          </label>
          <input
            autoFocus={false}
            type="text"
            name="emails"
            onFocus={() => {
              setFocus(true);
            }}
            onBlur={() => {
              setTimeout(() => {
                setFocus(false);
              }, 200);
            }}
            onChange={handleSearch}
            placeholder="Enter the Signer Email"
            autoComplete="off"
          />
        </div>
        {focus && (
          <div className="z-10 absolute left-0 w-full rounded-lg bg-white overflow-auto max-h-60">
            {search.map((item) => (
              <CustomOption
                key={item.id}
                data={item}
                callback={() => {
                  addSigner(item);
                }}
              />
            ))}
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="px-4 py-2 sm:py-10 sm:px-12 relative">
                <div className=" bg-white rounded-lg mt-5 py-5 px-2 sm:px-5 w-screen sm:w-auto">
                  <h6 className="font-bold">Invite Signers</h6>
                  <div
                    className="signature__form py-5 px-5 bg-white rounded-lg my-5"
                    style={{ height: "100%" }}
                  >
                    <InputSigner />
                    {signers.map((item, idx) => (
                      <div
                        key={item.name}
                        className="flex justify-between items-center mb-2 py-1 px-2 cursor-pointer shadow-md rounded-lg hover:bg-gray-100"
                      >
                        <div className="flex">
                          <img
                            src={
                              item.photo
                                ? item.photo
                                : "https://icon-library.com/images/windows-user-icon/windows-user-icon-14.jpg"
                            }
                            alt="unknown"
                            className="flex-shrink-0 h-8 w-8 rounded-full"
                          />
                          <div>
                            <span className="font-normal ml-3 block truncate text-base ">
                              {item.name}
                            </span>
                            <span className="font-normal ml-3 block truncate text-gray-400 text-xs">
                              {item.email}
                            </span>
                          </div>
                        </div>

                        <span onClick={() => deleteSigners(idx)}>
                          <svg
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M14 14L8 8M14 8L8 14"
                              stroke="#FF3B3B"
                              strokeWidth="1.33333"
                              strokeLinecap="round"
                            />
                            <path
                              d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
                              stroke="#FF3B3B"
                              strokeWidth="1.33333"
                            />
                          </svg>
                        </span>
                      </div>
                    ))}
                  </div>
                  <div className="signature__form sm:grid sm:grid-cols-2 sm:gap-4 px-5">
                    <div className="grid signature__input">
                      <label style={{ textAlign: "left" }}>
                        Due Date<span className="red">*</span>
                      </label>
                      <input
                        type="date"
                        name="expired_date_at"
                        value={signdata.expired_date_at}
                        onChange={handleChange}
                        placeholder="Select Due Date"
                        autoComplete="off"
                        className="w-full sm:w-96"
                      />
                      <small className="text-red-500">
                        {errorInput.expired_date_at}
                      </small>
                    </div>
                    <div className="grid signature__input">
                      <label style={{ textAlign: "left" }}>
                        Due Date<span className="red">*</span>
                      </label>
                      <input
                        type="time"
                        name="expired_time_at"
                        value={signdata.expired_time_at}
                        onChange={handleChange}
                        placeholder="Select Time"
                        autoComplete="off"
                        className="w-full sm:w-96"
                      />
                      <small className="text-red-500">
                        {errorInput.expired_time_at}
                      </small>
                    </div>
                  </div>

                  <div className="signature__form_submit">
                    <button className="back" onClick={() => setOpen(false)}>
                      Back
                    </button>
                    <button className="save" onClick={handleInviteSigner}>
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
