export const images_d = [
  {
    original: "/deck/desktop/welcome.svg",
    thumbnail: "/deck/desktop/welcome.svg",
  },
  {
    original: "/deck/desktop/create.svg",
    thumbnail: "/deck/desktop/create.svg",
  },
  {
    original: "/deck/desktop/form.svg",
    thumbnail: "/deck/desktop/form.svg",
  },
  {
    original: "/deck/desktop/detail.svg",
    thumbnail: "/deck/desktop/detail.svg",
  },
  {
    original: "/deck/desktop/request.svg",
    thumbnail: "/deck/desktop/request.svg",
  },
  {
    original: "/deck/desktop/remind.svg",
    thumbnail: "/deck/desktop/remind.svg",
  },
  {
    original: "/deck/desktop/delete.svg",
    thumbnail: "/deck/desktop/delete.svg",
  },
];

export const images_m = [
  {
    original: "/deck/mobile/welcome.svg",
    thumbnail: "/deck/mobile/welcome.svg",
  },
  {
    original: "/deck/mobile/create.svg",
    thumbnail: "/deck/mobile/create.svg",
  },
  {
    original: "/deck/mobile/form.svg",
    thumbnail: "/deck/mobile/form.svg",
  },
  {
    original: "/deck/mobile/detail.svg",
    thumbnail: "/deck/mobile/detail.svg",
  },
  {
    original: "/deck/mobile/request.svg",
    thumbnail: "/deck/mobile/request.svg",
  },
  {
    original: "/deck/mobile/remind.svg",
    thumbnail: "/deck/mobile/remind.svg",
  },
  {
    original: "/deck/mobile/delete.svg",
    thumbnail: "/deck/mobile/delete.svg",
  },
];
