import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { AlertComponent } from "../../components/alerts/alert-component";
import { useCustomAlert } from "../../components/alerts/alert-provider";
import SignatureMenu from "../../components/shared/signature-menu";
import { DeclineModal } from "../../components/request/modal-decline";
import { AvatarStatus } from "../../components/shared/avatar-with-status";
import { getData, postData, putData } from "../../utility/network";
import utils, { enumSignature, print } from "../../utility/utils";
import "./detail.css";
import { useCustomRefresh } from "../../utility/refresh-provider";

export const SignatureRequest = ({ pages }) => {
  const [signdata, setSignData] = useState([]);
  const [setList] = useCustomAlert();
  const [tabs, setTabs] = useState(0);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);
  const [refresher, setRefresher] = useCustomRefresh();

  useEffect(() => {
    document.title = "Teken | My Request";

    let uri = "sign/my-request/" + id;

    if (refresher) {
      // get detail data
      let key = utils.randomAlphaNumeric();
      getData(
        uri,
        {},
        (data) => {
          data.data.date = data.data.initiated_on.split("|")[0];
          data.data.time = data.data.initiated_on.split("|")[1];
          setSignData(data.data);
        },
        (msg, error) => {
          print(error);
          setList([
            <AlertComponent key={key} type="error" msg={error.message} />,
          ]);
        },
        () => {
          //print("Fetch Request Detail Finished");
        }
      );
    }
  }, [setList, id, pages, refresher]);

  const handleRequestAction = (act) => {
    // handle when click the button for many times
    if (act === "decline") {
      return;
    }

    if (!loading) {
      setLoading(true);

      const body = {};
      let key = utils.randomAlphaNumeric();

      putData(
        "sign/my-request/" + id + "/" + act,
        body,
        (data) => {
          setList([
            <AlertComponent
              key={key}
              type="success"
              msg={`Signature has been ${act}!`}
            />,
          ]);
        },
        (msg, error) => {
          print(error);
          setList([
            <AlertComponent
              key={key}
              type="error"
              msg={error.message.feedback[0]}
            />,
          ]);
        },
        () => {
          setLoading(false);
          setRefresher(true);
        }
      );
    }
  };

  const handleReqFinalDocument = () => {
    if (!loading) {
      setLoading(true);

      const body = {};
      let key = utils.randomAlphaNumeric();

      postData(
        "sign/my-request/" + id + "/request-document",
        body,
        (data) => {
          setList([
            <AlertComponent
              key={key}
              type="success"
              msg={`Signature has been requested!`}
            />,
          ]);
        },
        (msg, error) => {
          print(error);
          setList([
            <AlertComponent
              key={key}
              type="error"
              msg={error.message.feedback[0]}
            />,
          ]);
        },
        () => {
          setLoading(false);
          setRefresher(true);
        }
      );
    }
  };

  const ActionListStatus = () => {
    switch (signdata.status) {
      case "Waiting":
        if (!signdata.can_sign) {
          return <></>;
        }
        return (
          <>
            <DeclineModal
              cancelButtonRef={cancelButtonRef}
              open={open}
              setOpen={setOpen}
              id={id}
            />
            <div className="w-60">
              <div
                onClick={(event) => {
                  event.preventDefault();
                  handleRequestAction("accept");
                }}
                className="btn__primary font-semibold flex items-center justify-center text-sm rounded-md shadow-sm w-full py-1 "
              >
                Accept
              </div>
              <div
                onClick={(event) => {
                  event.preventDefault();
                  setOpen(true);
                }}
                className="mt-2 btn__outline_red font-semibold flex items-center justify-center text-sm rounded-md shadow-sm w-full py-1 "
              >
                Decline
              </div>
            </div>
          </>
        );

      default:
        return (
          <div className="w-60">
            <div
              onClick={(event) => {
                event.preventDefault();
                handleReqFinalDocument();
              }}
              className="btn__primary font-semibold flex items-center justify-center text-sm rounded-md shadow-sm w-full py-1 "
            >
              Request Final Document
            </div>
          </div>
        );
    }
  };

  return (
    <>
      <div className="px-4 py-2 sm:py-6 sm:px-12 relative">
        <div className="signature__header">
          <h3 style={{ textTransform: "capitalize" }}>{pages}</h3>
        </div>

        <div className=" bg-white rounded-lg my-5 sm:flex ">
          <div className="signature__qr py-5 px-5">
            <img
              src={`data:image/png;base64,${signdata.qr_code}`}
              alt="unknown"
              width="130px"
              height="130px"
              className="mx-auto"
            />

            <h6>{signdata.sign_number}</h6>
          </div>

          <div className="signature__inner__card w-full py-5 px-5 bg-white rounded-lg">
            <div className="signature__inner_menu flex items-center justify-between">
              <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                <div className="block">
                  <div className="flex space-x-4">
                    <div
                      className={utils.classNames(
                        tabs === 0 ? "tab__active" : "",
                        "py-2 rounded-md text-xs sm:text-sm text-gray-500 font-medium tab__profile relative transition duration-150 ease-in-out"
                      )}
                      aria-current="page"
                      onClick={() => setTabs(0)}
                    >
                      Signer Info
                    </div>
                    <div
                      className={utils.classNames(
                        tabs === 1 ? "tab__active" : "",
                        "py-2 rounded-md text-xs sm:text-sm text-gray-500 font-medium tab__profile relative transition duration-150 ease-in-out"
                      )}
                      aria-current="page"
                      onClick={() => setTabs(1)}
                    >
                      Document Info
                    </div>
                    <div
                      className={utils.classNames(
                        tabs === 2 ? "tab__active" : "",
                        "py-2 rounded-md text-xs sm:text-sm text-gray-500 font-medium tab__profile relative transition duration-150 ease-in-out"
                      )}
                      aria-current="page"
                      onClick={() => setTabs(2)}
                    >
                      Document File
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <SignatureMenu
                  pages={enumSignature.detail_request}
                  id={signdata.id}
                  data={signdata}
                />
              </div>
            </div>

            {tabs === 0 ? (
              <div className="signer__info">
                <div className="grid lg:grid-cols-3 sm:grid-cols-3 grid-cols-1 gap-3">
                  <div>
                    <div className="signer__info_data">
                      <p>Sign number</p>
                      <h6>{signdata.sign_number}</h6>
                    </div>
                    <div className="signer__info_data">
                      <p>Sign Name</p>
                      <h6>{signdata.request_by}</h6>
                    </div>
                    <div className="signer__info_data">
                      <p>Sign Email</p>
                      <h6>{signdata.email}</h6>
                    </div>
                  </div>
                  <div>
                    <div className="signer__info_data">
                      <p>Signers</p>
                      {signdata.signers != null &&
                      signdata.signers.length > 0 ? (
                        signdata.signers.map((item, idx) => (
                          <div className="flex items-center mt-2" key={idx}>
                            <AvatarStatus key={idx} data={item} />
                            <div className="ml-2 text-base font-semibold text-gray-500">
                              {item.name}
                            </div>
                          </div>
                        ))
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div>
                    <div className="signer__info_data">
                      <p>Date Created</p>
                      <h6>{signdata.date}</h6>
                    </div>
                    <div className="signer__info_data">
                      <p>Time Created</p>
                      <h6>{signdata.time}</h6>
                    </div>
                  </div>
                </div>
              </div>
            ) : tabs === 1 ? (
              <div className="signer__document">
                <div className="signer__info_data">
                  <p>Document Name</p>
                  <h6>{signdata.name}</h6>
                </div>
                <div className="signer__info_data">
                  <p>Description</p>
                  <h6>{signdata.description}</h6>
                </div>
                <div className="grid lg:grid-cols-2 grid-cols-1">
                  <div className="signer__info_data">
                    <p>Document Category</p>
                    <h6>{signdata.category}</h6>
                  </div>
                  <div className="signer__info_data">
                    <p>Document Type</p>
                    <h6>{signdata.type}</h6>
                  </div>
                </div>
              </div>
            ) : tabs === 2 ? (
              <div className="signer__file">
                <div className="signer__info_data">
                  <p>Document Link</p>
                  <h6>
                    <a target="_blank" href={signdata.file} rel="noreferrer">
                      {signdata.file}
                    </a>
                  </h6>
                </div>
                <div className="signer__info_data">
                  <p>Document File</p>
                  <a
                    download="true"
                    target="_blank"
                    rel="noreferrer"
                    href={signdata.file}
                  >
                    <div className="document__file">{signdata.name}</div>
                  </a>
                </div>
              </div>
            ) : (
              "null"
            )}
          </div>
        </div>
        <ActionListStatus />
      </div>
    </>
  );
};
