import utils from "../../utility/utils";

export const StatusText = ({ status, clas }) => {
  switch (status) {
    case "accept":
      clas = clas !== null && clas !== undefined ? clas : "text__success";

      return (
        <div className={utils.classNames(clas, "ml-2 text-sm sm:text-base font-bold")}>
          Accepted
        </div>
      );

    case "reject":
      clas = clas !== null && clas !== undefined ? clas : "text__danger";

      return (
        <div className={utils.classNames(clas, "ml-2 text-sm sm:text-base font-bold")}>
          Rejected
        </div>
      );

    case "waiting":
      clas = clas !== null && clas !== undefined ? clas : "text__warning";

      return (
        <div className={utils.classNames(clas, "ml-2 text-sm sm:text-base font-bold")}>
          Pending
        </div>
      );

    default:
      clas = clas !== null && clas !== undefined ? clas : "text__success";

      return (
        <div className={utils.classNames(clas, "ml-2 text-sm sm:text-base font-bold")}>
          Signed
        </div>
      );
  }
};
