import axios from "axios";
import utils, { BASE_URL } from "./utils";

export const instance = axios.create({
  baseURL: BASE_URL,
  crossDomain: true,
});

function handleSuccess(response, onSuccess, onFailed, onFinish) {
  var data = "";
  var message = "";
  var error = {};

  if (typeof response.message === "string") {
    message = response.message;
  }
  if (typeof response.error === "object") {
    error = response.error;
  }
  if (typeof response.data === "object") {
    data = response.data;
  } else {
    data = message;
  }
  if (
    response !== undefined &&
    response.status > 399 &&
    response.status < 200
  ) {
    onFailed(message, error);
    return typeof onFinish === "function" ? onFinish() : null;
  }
  onSuccess(data, message);
  return typeof onFinish === "function" ? onFinish() : null;
}

function handleError(error, onSuccess, onFailed, onFinish) {
  if (
    typeof error.response === "object" &&
    typeof error.response.data === "object"
  ) {
    const data = error.response;
    onFailed(data.status, data.data, data.message);
  } else {
    const data = error.response;
    let status =
      data !== null
        ? "error"
        : data.status !== undefined
        ? data.status
        : "error";
    onFailed(status, {}, "Internal server error");
  }
  return typeof onFinish === "function" ? onFinish() : null;
}

export const getData = (url, params, onSuccess, onFailed, onFinish) => {
  const token = utils.getToken();

  instance
    .get(url, {
      params: params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return handleSuccess(response, onSuccess, onFailed, onFinish);
    })
    .catch((error) => {
      console.error(error);
      return handleError(error, onSuccess, onFailed, onFinish);
    });
};

export const postData = (url, data, onSuccess, onFailed, onFinish) => {
  const token = utils.getToken();
  instance
    .post(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return handleSuccess(response, onSuccess, onFailed, onFinish);
    })
    .catch((error) => {
      console.error(error);
      return handleError(error, onSuccess, onFailed, onFinish);
    });
};

export const putData = (url, data, onSuccess, onFailed, onFinish) => {
  const token = utils.getToken();
  instance
    .put(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return handleSuccess(response, onSuccess, onFailed, onFinish);
    })
    .catch((error) => {
      console.error(error);
      return handleError(error, onSuccess, onFailed, onFinish);
    });
};

export const deleteData = (url, onSuccess, onFailed, onFinish) => {
  const token = utils.getToken();
  instance
    .delete(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return handleSuccess(response, onSuccess, onFailed, onFinish);
    })
    .catch((error) => {
      console.error(error);
      return handleError(error, onSuccess, onFailed, onFinish);
    });
};

export const cleanHtml = (str, enter = "<br>") => {
  let content = str.replace(/<div>/g, enter);
  content = content.replace(/<\/div>/g, "");
  return content;
};

export const getLocationData = (url, onSuccess, onFailed, onFinish) => {
  const instance = axios.create({
    baseURL: "https://nominatim.openstreetmap.org/",
    crossDomain: true,
  });
  instance
    .get(url)
    .then((response) => {
      return onSuccess(response);
    })
    .catch((error) => {
      console.error(error);
      return onFailed(error);
    });
};

export const dataURLtoFile = (dataurl, filename) => {
  console.log(dataurl);
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  const file = new File([u8arr], filename, { type: mime });
  console.log(file);
  return file;
};
