import { Link } from "react-router-dom";
import SignatureStatusCard from "./signature-card-with-status";

export const Grid = ({ page, data }) => {
  return (
    <>
      <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3">
        {page === "collaboration" && (
          <Link to="/dashboard/collaboration/add">
            <div
              className="add__card signature__card py-5 px-5 bg-white rounded-lg flex flex-col justify-center items-center"
              style={{ height: "100%" }}
            >
              <svg
                width="84"
                height="84"
                viewBox="0 0 84 84"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M42 82C64.0914 82 82 64.0914 82 42C82 19.9086 64.0914 2 42 2C19.9086 2 2 19.9086 2 42C2 64.0914 19.9086 82 42 82Z"
                  stroke="#347AE2"
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M42 26V58"
                  stroke="#347AE2"
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M26 42H58"
                  stroke="#347AE2"
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <h6 className="mt-2">Add New Signature</h6>
            </div>
          </Link>
        )}
        {data ? (
          data.map((item, idx) => (
            <SignatureStatusCard
              key={`ssc-${idx}`}
              page={page}
              status={item.status}
              data={item}
            />
          ))
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
