export const FormCollaborationStep = [
  {
    selector: '[data-tour="addition-collaboration"]',
    content: (
      <>
        Pasang <i>deadline</i> bagi orang yang akan diundang untuk kolaborasi
        dalam penandatanganan dokumen
      </>
    ),
  },
  {
    selector: '[data-tour="invite-collaboration"]',
    content: (
      <>
        Undang seseorang yang dibutuhkan <i>signature</i> -nya
      </>
    ),
  },
];
