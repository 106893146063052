import { Fragment, useEffect, useState } from "react";
import { AlertComponent } from "../../components/alerts/alert-component";
import { useCustomAlert } from "../../components/alerts/alert-provider";
import { Grid } from "../../components/shared/grid";
import { ListView } from "../../components/shared/list";
import { getData } from "../../utility/network";
import utils, { enumSignature, print } from "../../utility/utils";
import "./collaboration.css";
import emptyState from "../../assets/images/empty.png";
import { useCustomRefresh } from "../../utility/refresh-provider";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon, SearchIcon } from "@heroicons/react/solid";
import { LoadingBarier } from "../../components/loading-barrier";

const sort = [
  { id: 2, name: "Newest", unavailable: false },
  { id: 3, name: "Oldest", unavailable: false },
  { id: 4, name: "A-Z", unavailable: false },
  { id: 5, name: "Z-A", unavailable: false },
];

export const Collaboration = () => {
  const [collabs, setCollabData] = useState([]);
  const [selectedSort, setSelectedSort] = useState(sort[0]);
  const [setList] = useCustomAlert();
  const [view, setView] = useState(true);
  const handleView = () => {
    setView(!view);
  };
  const [loading, setLoading] = useState(true);
  //reload data trigger by the child
  const [refresher] = useCustomRefresh();

  useEffect(() => {
    document.title = "Teken | Collaboration";
    // get user data
    if (refresher) {
      let key = utils.randomAlphaNumeric();
      let qParams = {};
      setLoading(true);

      if (selectedSort.id === 2) {
        qParams = { sort: "latest" };
      } else if (selectedSort.id === 3) {
        qParams = { sort: "oldest" };
      } else if (selectedSort.id === 4) {
        qParams = { sort: "asc" };
      } else if (selectedSort.id === 5) {
        qParams = { sort: "desc" };
      }

      getData(
        "collab",
        qParams,
        (data) => {
          data.data.forEach((item) => {
            let reject = 0;
            let pending = 0;

            if (item.signer.length > 0) {
              item.signer.forEach((user) => {
                if (
                  user.status === "rejected" ||
                  user.status === "reject" ||
                  user.status === "decline"
                ) {
                  reject++;
                } else if (user.status === "waiting") {
                  pending++;
                }
              });
            }

            item.rejectCount = reject;
            item.pendingCount = pending;
          });

          setCollabData(data.data);
        },
        (msg, error) => {
          print(error);
          setList([
            <AlertComponent key={key} type="error" msg={error.message} />,
          ]);
        },
        () => {
          //print("Fetch Collaboration Finished");
          setLoading(false);
        }
      );
    }
  }, [setList, refresher, selectedSort]);

  const handleSort = (val) => {
    setSelectedSort(val);
  };

  const handleSearch = (e) => {
    let q = e.target.value;
    let qParams = { search: q };

    if (q.length % 3 === 0) {
      let key = utils.randomAlphaNumeric();
      getData(
        "collab",
        qParams,
        (data) => {
          data.data.forEach((item) => {
            let reject = 0;
            let pending = 0;

            if (item.signer.length > 0) {
              item.signer.forEach((user) => {
                if (
                  user.status === "rejected" ||
                  user.status === "reject" ||
                  user.status === "decline"
                ) {
                  reject++;
                } else if (user.status === "waiting") {
                  pending++;
                }
              });
            }

            item.rejectCount = reject;
            item.pendingCount = pending;
          });

          setCollabData(data.data);
        },
        (msg, error) => {
          print(error);

          setList([
            <AlertComponent key={key} type="error" msg={error.message} />,
          ]);
        },
        () => {
          //print("Fetch Collaboration Finished");
          setLoading(false);
        }
      );
    }
  };

  return (
    <>
      {loading && <LoadingBarier />}
      <div className="px-4 py-2 sm:py-6 sm:px-12 relative">
        <div className="flex items-center justify-between">
          <div className="text-2xl sm:text-3xl text-gray-800 font-extrabold">
            Collaboration
          </div>
          <div className="flex">
            <span onClick={handleView} className="cursor-pointer mr-5">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0.941176 0C0.421379 0 0 0.421378 0 0.941176V4.70588C0 5.22568 0.421378 5.64706 0.941176 5.64706H4.70588C5.22568 5.64706 5.64706 5.22568 5.64706 4.70588V0.941176C5.64706 0.421379 5.22568 0 4.70588 0H0.941176ZM0.941176 10.3529C0.421379 10.3529 0 10.7743 0 11.2941V15.0588C0 15.5786 0.421378 16 0.941176 16H4.70588C5.22568 16 5.64706 15.5786 5.64706 15.0588V11.2941C5.64706 10.7743 5.22568 10.3529 4.70588 10.3529H0.941176ZM10.3529 0.941176C10.3529 0.421378 10.7743 0 11.2941 0H15.0588C15.5786 0 16 0.421379 16 0.941176V4.70588C16 5.22568 15.5786 5.64706 15.0588 5.64706H11.2941C10.7743 5.64706 10.3529 5.22568 10.3529 4.70588V0.941176ZM11.2941 10.3529C10.7743 10.3529 10.3529 10.7743 10.3529 11.2941V15.0588C10.3529 15.5786 10.7743 16 11.2941 16H15.0588C15.5786 16 16 15.5786 16 15.0588V11.2941C16 10.7743 15.5786 10.3529 15.0588 10.3529H11.2941Z"
                  fill={view ? "#347AE2" : "#BDBDBD"}
                />
              </svg>
            </span>
            <span onClick={handleView} className="cursor-pointer ">
              <svg
                width="19"
                height="16"
                viewBox="0 0 19 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0.888889 0C0.397969 0 0 0.397969 0 0.888889C0 1.37981 0.397969 1.77778 0.888889 1.77778H1.86716C2.35807 1.77778 2.75604 1.37981 2.75604 0.888889C2.75604 0.397969 2.35807 0 1.86715 0H0.888889ZM5.4823 0C4.99138 0 4.59341 0.397969 4.59341 0.888889C4.59341 1.37981 4.99138 1.77778 5.4823 1.77778H18.1111C18.602 1.77778 19 1.37981 19 0.888889C19 0.397969 18.602 0 18.1111 0H5.4823ZM4.59341 8C4.59341 7.50908 4.99138 7.11111 5.4823 7.11111H18.1111C18.602 7.11111 19 7.50908 19 8C19 8.49092 18.602 8.88889 18.1111 8.88889H5.4823C4.99138 8.88889 4.59341 8.49092 4.59341 8ZM0.888889 7.11111C0.397969 7.11111 0 7.50908 0 8C0 8.49092 0.397969 8.88889 0.888889 8.88889H1.86716C2.35807 8.88889 2.75604 8.49092 2.75604 8C2.75604 7.50908 2.35807 7.11111 1.86715 7.11111H0.888889ZM4.59341 15.1111C4.59341 14.6202 4.99138 14.2222 5.4823 14.2222H18.1111C18.602 14.2222 19 14.6202 19 15.1111C19 15.602 18.602 16 18.1111 16H5.4823C4.99138 16 4.59341 15.602 4.59341 15.1111ZM0.888889 14.2222C0.397969 14.2222 0 14.6202 0 15.1111C0 15.602 0.397969 16 0.888889 16H1.86716C2.35807 16 2.75604 15.602 2.75604 15.1111C2.75604 14.6202 2.35807 14.2222 1.86715 14.2222H0.888889Z"
                  fill={view ? "#BDBDBD" : "#347AE2"}
                />
              </svg>
            </span>
          </div>
        </div>

        <div className="signature__filter justify-between">
          <div className="flex rounded-md h-8 sm:h-12 bg-white custom__shadow_1 w-full sm:w-min">
            <span className="inline-flex items-center px-3 rounded-l-md border-r-0 text-gray-500 text-sm">
              <SearchIcon width="18px" />
            </span>
            <input
              type="text"
              name="search"
              onChange={handleSearch}
              className="flex-1 block w-80 h-8 sm:h-12 rounded-r-md sm:text-sm focus:outline-none px-2"
              placeholder="Search"
            />
          </div>

          <Listbox value={selectedSort} onChange={(val) => handleSort(val)}>
            {({ open }) => (
              <>
                <div className="relative text__color_primary">
                  <Listbox.Button className="relative h-8 sm:h-10 bg-blue-100 border rounded-md pl-3 pr-10 py-1 sm:py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-blue-400 sm:text-sm">
                    <span className="flex items-center">
                      <span className="text-sm sm:text-base ml-3 block truncate">
                        {selectedSort.name}
                      </span>
                    </span>
                    <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                      <ChevronDownIcon
                        className="h-5 w-5 text__color_primary"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 w-full checkbox__dashboard max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                      {sort.map((sortData) => (
                        <Listbox.Option
                          key={sortData.id}
                          className={({ active }) =>
                            utils.classNames(
                              active
                                ? "text-white bg-blue-500"
                                : "text__color_primary",
                              "cursor-default select-none relative py-2 pl-3 pr-9"
                            )
                          }
                          value={sortData}
                        >
                          {({ selectedSort, active }) => (
                            <>
                              <div className="flex items-center">
                                <span
                                  className={utils.classNames(
                                    selectedSort
                                      ? "font-semibold"
                                      : "font-normal",
                                    "text-sm sm:text-base ml-3 block truncate"
                                  )}
                                >
                                  {sortData.name}
                                </span>
                              </div>

                              {selectedSort ? (
                                <span
                                  className={utils.classNames(
                                    active ? "text-white" : "text-blue-500",
                                    "absolute inset-y-0 right-0 flex items-center pr-4"
                                  )}
                                >
                                  <CheckIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
        </div>

        <div className="mt-10">
          {view ? (
            <Grid data={collabs} page={enumSignature.collaboration} />
          ) : (
            <ListView page={enumSignature.collaboration} data={collabs} />
          )}
        </div>

        {collabs.length === 0 && (
          <div className="signature__empty">
            <img src={emptyState} alt="unknown" />
            <h6>You do not have any collaboration</h6>
          </div>
        )}
      </div>
    </>
  );
};
