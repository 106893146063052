import { Switch, Route } from "react-router-dom";
import Contact from "./pages/contact/contact";
import Layout from "./pages/layout";
import Dashboard from "./pages/dashboard/dashboard";
import Profile from "./pages/profile/profile";
import ChangeProfile from "./components/profile/change-profile";
import ChangePassword from "./components/profile/change-password";
import Help from "./components/profile/help";
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import Forgot from "./pages/auth/Forgot";
import Signature from "./pages/my-signature/signature";
import NewSignature from "./pages/my-signature/signature-new";
import { Collaboration } from "./pages/collaboration/collaboration";
import { Request } from "./pages/request/request";
import { SignatureDetail } from "./pages/detail-signature/detail-signature";
import { enumSignature } from "./utility/utils";
import { CollaborationDetail } from "./pages/detail-signature/detail-collaboration";
import { SignatureRequest } from "./pages/detail-signature/detail-request";
import { SendFinalDoc } from "./pages/final-document/send-final-doc";
import DetailGlobal from "./pages/global/detail";
import SendForgetEmail from "./pages/forgot-password/send-email-forgot";
import ForgotPassword from "./pages/forgot-password/forgot-password";
import VerifyEmail from "./pages/auth/Verify-Email";

const Routes = () => {
  const routeList = [
    {
      path: "/",
      exact: true,
      children: <Login />,
    },
    {
      path: "/verify/:code",
      exact: true,
      children: <VerifyEmail />,
    },
    {
      path: "/dashboard",
      exact: false,
      children: <Layout />,
    },
    {
      path: "/c/:qr_code",
      exact: true,
      children: <DetailGlobal />,
    },
    {
      path: "/email-forget",
      exact: true,
      children: <SendForgetEmail />,
    },
    {
      path: "/forgot-password",
      exact: true,
      children: <ForgotPassword />,
    },
  ];

  return (
    <Switch>
      {routeList.map(({ ...data }, idx) => (
        <Route {...data} key={idx} />
      ))}
    </Switch>
  );
};

const ChildRoutes = () => {
  const routeList = [
    {
      path: "/dashboard/",
      exact: true,
      children: <Dashboard />,
    },
    {
      path: "/dashboard/contact",
      exact: true,
      children: <Contact />,
    },
    {
      path: "/dashboard/profile",
      exact: false,
      children: <Profile />,
    },
    {
      path: "/dashboard/signature",
      exact: true,
      children: <Signature />,
    },
    {
      path: "/dashboard/collaboration",
      exact: true,
      children: <Collaboration />,
    },
    {
      path: "/dashboard/signature/add",
      exact: true,
      children: <NewSignature single={true} />,
    },
    {
      path: "/dashboard/signature/detail/:id",
      exact: true,
      children: <SignatureDetail pages={enumSignature.my_signature} />,
    },
    {
      path: "/dashboard/request/detail/:id",
      exact: true,
      children: <SignatureRequest pages={enumSignature.my_request} />,
    },
    {
      path: "/dashboard/collaboration/detail/:id",
      exact: true,
      children: <CollaborationDetail pages={enumSignature.collaboration} />,
    },
    {
      path: "/dashboard/request",
      exact: true,
      children: <Request />,
    },
    {
      path: "/dashboard/collaboration/add",
      exact: true,
      children: <NewSignature single={false} />,
    },
    {
      path: "/dashboard/collaboration/edit/:id",
      exact: true,
      children: <NewSignature single={false} />,
    },
    {
      path: "/dashboard/signature/edit/:id",
      exact: true,
      children: <NewSignature single={true} />,
    },
    {
      path: "/dashboard/send-final-document/:id",
      exact: true,
      children: <SendFinalDoc />,
    },
  ];

  return (
    <>
      {routeList.map(({ ...data }, idx) => (
        <Route {...data} key={idx} />
      ))}
    </>
  );
};

const ProfileRoutes = () => {
  const routeList = [
    {
      path: "/dashboard/profile",
      exact: true,
      children: <ChangeProfile />,
    },
    {
      path: "/dashboard/profile/change-password",
      exact: false,
      children: <ChangePassword />,
    },
    {
      path: "/dashboard/profile/help",
      exact: false,
      children: <Help />,
    },
  ];

  return (
    <Switch>
      {routeList.map(({ ...data }, idx) => (
        <Route {...data} key={idx} />
      ))}
    </Switch>
  );
};

const AuthRoutes = () => {
  const routeList = [
    {
      path: "/login",
      exact: true,
      children: <Login />,
    },
    {
      path: "/register",
      exact: true,
      children: <Register />,
    },
    {
      path: "/forgot-password",
      exact: true,
      children: <Forgot />,
    },
  ];

  return (
    <Switch>
      {routeList.map(({ ...data }, idx) => (
        <Route {...data} key={idx} />
      ))}
    </Switch>
  );
};

export { Routes, ChildRoutes, ProfileRoutes, AuthRoutes };
