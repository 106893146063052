import { useEffect, useState } from "react";
import SignatureComponent from "./signature-component";
import x_circle from "../../assets/images/x_circle.png";
import time_circle from "../../assets/images/time_circle.png";
import check_circle from "../../assets/images/check_circle.png";
import mail_circle from "../../assets/images/mail_circle.png";

import utils, { print, twoDigit } from "../../utility/utils";
import { useCustomAlert } from "../alerts/alert-provider";
import { AlertComponent } from "../alerts/alert-component";
import { getData } from "../../utility/network";

const MySignatureSection = ({ selected, callbackSelect, data }) => {
  const [setList] = useCustomAlert();
  const [countData, setCountData] = useState({
    accepted: "0",
    rejected: "0",
    request: "0",
    sent: "0",
  });
  useEffect(() => {
    // get user data
    let key = utils.randomAlphaNumeric();
    getData(
      "home",
      {},
      (data) => {
        data.data.sent = 0;
        setCountData(data.data);
      },
      (msg, error) => {
        print(error);
        setList([
          <AlertComponent key={key} type="error" msg={error.message} />,
        ]);
      },
      () => {
        //print("Fetch counter in Home Finished");
      }
    );
  }, [setList]);

  return (
    <div className="container pt-10 relative">
      <div className="flex justify-between align-center">
        <div className="text-2xl font-extrabold">My Signature</div>
      </div>
      <div className="container mt-4 sm:p-4 rounded-lg sm:grid sm:grid-cols-4 sm:divide-x sm:bg-white">
        <SignatureComponent
          count={twoDigit(countData.request)}
          name="My Request"
          child={
            <img className="w-8 sm:w-10" src={time_circle} alt="unknown" />
          }
        />
        <SignatureComponent
          count={twoDigit(countData.sent)}
          name="Signature Sent"
          child={
            <img className="w-8 sm:w-10" src={mail_circle} alt="unknown" />
          }
        />
        <SignatureComponent
          count={twoDigit(countData.accepted)}
          name="Signature Accepted"
          child={
            <img className="w-8 sm:w-10" src={check_circle} alt="unknown" />
          }
        />
        <SignatureComponent
          count={twoDigit(countData.rejected)}
          name="Signature Rejected"
          child={<img className="w-8 sm:w-10" src={x_circle} alt="unknown" />}
        />
      </div>
    </div>
  );
};

export default MySignatureSection;
