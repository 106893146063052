import { combineReducers } from "redux";
import Auth from "./auth";
import TourSidebar from "./sidebar";
import TourSignature from "./signature";
import RequestCounter from "./request-counter";

const RootReducer = combineReducers({
  Auth,
  TourSidebar,
  TourSignature,
  RequestCounter,
});

export default RootReducer;
