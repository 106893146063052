import { useState } from "react";
import { postData } from "../../utility/network";
import utils, { print } from "../../utility/utils";
import { AlertComponent } from "../alerts/alert-component";
import { useCustomAlert } from "../alerts/alert-provider";
import OtpInput from "../otp/reader";

export const Passcode = ({ qr_code, setData }) => {
  const [passcode, setPasscode] = useState("");
  const [setList] = useCustomAlert();

  const handleSendPasscode = () => {
    let key = utils.randomAlphaNumeric();

    postData(
      "check-code",
      { qr_code: qr_code, code: passcode },
      (data) => {
        setData(data.data);
      },
      (msg, error) => {
        print(error);
        setList([
          <AlertComponent key={key} type="error" msg={error.message} />,
        ]);
      },
      () => {
        //print("Fetch Signature Detail Finished");
      }
    );
  };

  return (
    <>
      <div className="grid signature__input auth__input text-center">
        <label className="font-bold">Enter Passcode</label>
        <OtpInput
          className="my-2 justify-between"
          value={passcode}
          onChange={setPasscode}
          autoFocus
          OTPLength={6}
          otpType="number"
          disabled={false}
          //   secure
        />
        <div
          onClick={handleSendPasscode}
          className="font-bold btn__primary rounded-md px-4 py-1"
        >
          Submit
        </div>
      </div>
    </>
  );
};
