const CardContact = ({ data }) => {
  if (data.photo) {
    data.photo = data.photo.includes("https")
      ? data.photo
      : "https://teken-api.yokesen.com/storage/photos/" + data.photo;
  }

  return (
    <div className="custom__shadow_1 bg-white rounded-md p-2 sm:p-5">
      <div className="flex items-center">
        <div className="flex-shrink-0 h-10 w-10">
          <img
            className="h-10 w-10 rounded-full"
            src={
              data.photo
                ? data.photo
                : "https://icon-library.com/images/windows-user-icon/windows-user-icon-14.jpg"
            } 
            alt="unknown"
          />
        </div>
        <div className="ml-4">
          <div className="text-sm sm:text-base font-semibold name__contact">{data.name}</div>
          <div className="text-xs sm:text-sm text-gray-600 name__contact">{data.email}</div>
        </div>
      </div>
    </div>
  );
};

export default CardContact;
