export const Start_TourSidebarAction = (data) => {
  // console.log(data)
  localStorage.setItem("tour_sidebar", JSON.stringify(data));
  return {
    type: "TOUR_SIDEBAR_DONE",
    payload: data,
  };
};

export const Done_TourSidebarAction = () => {
  localStorage.removeItem("tour_sidebar");
  return {
    type: "TOUR_SIDEBAR_RESET",
  };
};
