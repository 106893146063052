/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useState } from "react";
import { Listbox, Menu, Popover, Transition } from "@headlessui/react";
import { MenuIcon } from "@heroicons/react/outline";
import "./navbar.css";
import acc from "../assets/images/acc_box.png";
import reject from "../assets/images/reject_box.png";
import sign from "../assets/images/sign_box.png";
import { useDispatch } from "react-redux";
import { logoutAction } from "../redux/action";
import { getData } from "../utility/network";
import utils, { print } from "../utility/utils";
import { Link, Redirect, useHistory } from "react-router-dom";
import logo from "../assets/images/logo.png";
import emptyState from "../assets/images/empty_notif.png";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/solid";
import { useCustomAlert } from "./alerts/alert-provider";
import { AlertComponent } from "./alerts/alert-component";
import { useCustomRefresh } from "../utility/refresh-provider";

const data = [
  { id: "", name: "All Notification", unavailable: false },
  { id: "unread", name: "Unread", unavailable: false },
  { id: "read", name: "Readed", unavailable: false },
  { id: "my-request", name: "My Request", unavailable: false },
  { id: "accepted", name: "Accept", unavailable: false },
  { id: "rejected", name: "Reject", unavailable: false },
];

const UserSection = ({ setVerified }) => {
  const [user, setUser] = useState({});
  const dispatch = useDispatch();
  const [login, setLogin] = useState(true);
  const [setList] = useCustomAlert();
  //reload data trigger by the child
  const [refresher] = useCustomRefresh();

  // logout function
  const handleLogout = () => {
    dispatch(logoutAction());
    setLogin(false);
  };

  useEffect(() => {
    // get user data for navbar
    let unmounted = false;
    if (!unmounted) {
      if (refresher) {
        let key = utils.randomAlphaNumeric();

        getData(
          "user/my-information",
          {},
          (data) => {
            setUser(data.data);
            setVerified(data.data.is_email_verified);
          },
          (msg, error) => {
            print(error);
            setList([
              <AlertComponent key={key} type="error" msg={error.message} />,
            ]);
          },
          () => {
            //print("Fetch User for Navbar Finished");
          }
        );
      }
    }
    return () => {
      unmounted = true;
    };
  }, [setList, setVerified, refresher]);

  if (!login) {
    return <Redirect to="/login" />;
  }

  return (
    <>
      <Menu as="div" className="md:ml-3 relative">
        <div>
          <Menu.Button className="flex items-center">
            <div className="flex-shrink-0">
              <img
                className="h-8 w-8 md:h-10 md:w-10 rounded-full object-cover"
                src={
                  user.photo
                    ? user.photo
                    : "https://icon-library.com/images/windows-user-icon/windows-user-icon-14.jpg"
                }
                alt="unknown"
              />
            </div>
            <div className="hidden sm:block ml-2 text-left">
              <div className="text-xs md:text-base font-extrabold text__color_primary">
                {user.name}
              </div>
              <div className="text-xs text-gray-400">Member</div>
            </div>
            <div className="ml-1 md:ml-5">
              <svg
                width="20"
                height="32"
                viewBox="0 0 20 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  opacity="0.05"
                  x="20"
                  width="32"
                  height="20"
                  rx="10"
                  transform="rotate(90 20 0)"
                  fill="#347AE2"
                />
                <path
                  d="M9.99997 22.1972L6.53516 18.7324L13.4648 18.7324L9.99997 22.1972Z"
                  fill="#347AE2"
                />
                <path
                  d="M10 10.8028L13.4648 14.2676L6.53522 14.2676L10 10.8028Z"
                  fill="#347AE2"
                />
              </svg>
            </div>
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="z-10 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            <Menu.Item>
              {({ active }) => (
                <Link to="/dashboard/profile">
                  <div
                    className={utils.classNames(
                      active ? "bg-gray-100" : "",
                      "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                    )} 
                  >
                    Profile
                  </div>
                </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <div
                  className={utils.classNames(
                    active ? "bg-gray-100" : "",
                    "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                  )}
                  onClick={handleLogout}
                >
                  Logout
                </div>
              )}
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
};

const Navbar = ({ setOpenSidebar, sidebarOpen, setVerified }) => {
  const [notifications, setNotifications] = useState([]);
  const [login, setLogin] = useState(true);
  const [notif_status, setNotificationStatus] = useState(false);
  const [filter, setFilter] = useState(data[0]);
  const dispatch = useDispatch();
  const history = useHistory();
  const [setList] = useCustomAlert();

  const fetchNotification = (filter) => {
    let qParams = { filter: filter.id };
    let key = utils.randomAlphaNumeric();

    getData(
      "notifications",
      qParams,
      (data) => {
        setNotifications(data.data);
        setNotificationStatus(data.notification_status !== 0);
      },
      (msg, error) => {
        print(error);
        // check if the token was expired
        setList([
          <AlertComponent key={key} type="error" msg={error.message} />,
        ]);
        if (error.message === "Unauthenticated.") {
          print("error");
          dispatch(logoutAction());
          setLogin(false);
        }
      },
      () => {
        //print("Fetch Notifications for Navbar Finished");
      }
    );
  };

  useEffect(() => {
    let key = utils.randomAlphaNumeric();

    getData(
      "notifications",
      {},
      (data) => {
        setNotifications(data.data);
        setNotificationStatus(data.notification_status !== 0);
      },
      (msg, error) => {
        print(error);
        setList([
          <AlertComponent key={key} type="error" msg={error.message} />,
        ]);
        // check if the token was expired
        if (error.message === "Unauthenticated.") {
          print("error");
          dispatch(logoutAction());
          setLogin(false);
        }
      },
      () => {
        //print("Fetch Notifications for Navbar Finished");
      }
    );
  }, [dispatch, setList]);

  const handleReadNotification = (notif) => {
    if (notif.status === "unread") {
      let key = utils.randomAlphaNumeric();

      getData(
        "notifications/" + notif.id,
        {},
        (data) => {
          fetchNotification(filter);
        },
        (msg, error) => {
          print(error);
          setList([
            <AlertComponent key={key} type="error" msg={error.message} />,
          ]);
          // check if the token was expired
          if (error.message === "Unauthenticated.") {
            print("error");
            dispatch(logoutAction());
            setLogin(false);
          }
        },
        () => {
          //print("Fetch Notifications for Navbar Finished");
        }
      );
    }

    if (
      notif.mesage.toLowerCase() === "you have new request" ||
      notif.mesage.toLowerCase() === "dont forget to sign!" ||
      notif.mesage.toLowerCase() === "sign invitation" ||
      notif.mesage.toLowerCase() === "don't forget to sign!"
    ) {
      history.push("/dashboard/request/detail/" + notif.document_id);
    } else {
      history.push("/dashboard/collaboration/detail/" + notif.document_id);
    }
  };

  const handleReadAll = () => {
    let key = utils.randomAlphaNumeric();

    getData(
      "notifications/mark-read",
      {},
      (data) => {
        if (data.status.toLowerCase() === "success") fetchNotification(filter);
        else {
          setList([
            <AlertComponent key={key} type="error" msg={data.message} />,
          ]);
        }
      },
      (msg, error) => {
        print(error);
        setList([
          <AlertComponent key={key} type="error" msg={error.message} />,
        ]);
        // check if the token was expired
        if (error.message === "Unauthenticated.") {
          print("error");
          dispatch(logoutAction());
          setLogin(false);
        }
      },
      () => {
        //print("Fetch Notifications for Navbar Finished");
      }
    );
  };

  const handleFilter = (val) => {
    setFilter(val);
    fetchNotification(val);
  };

  if (!login) {
    return <Redirect to="/login" />;
  }

  const NotificationImage = ({ msg }) => {
    if (msg.includes("Accepted")) {
      return <img src={acc} alt="unknown" />;
    } else if (msg.includes("Rejected")) {
      return <img src={reject} alt="unknown" />;
    } else {
      return <img src={sign} alt="unknown" />;
    }
  };

  const DropdownNotif = () => {
    return (
      <Listbox value={filter} onChange={(val) => handleFilter(val)}>
        {({ open }) => (
          <>
            <div className="relative text__color_primary">
              <Listbox.Button className="relative bg-blue-100 border rounded-md pl-3 pr-10 py-1 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-blue-400 sm:text-sm">
                <span className="flex items-center">
                  <span className="ml-3 block text-xs sm:text-sm truncate">
                    {filter.name}
                  </span>
                </span>
                <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <ChevronDownIcon
                    className="h-5 w-5 text__color_primary"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 w-full checkbox__dashboard max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                  {data.map((person) => (
                    <Listbox.Option
                      key={person.id}
                      className={({ active }) =>
                        utils.classNames(
                          active
                            ? "text-white bg-blue-500"
                            : "text__color_primary",
                          "cursor-default select-none text-sm relative py-2 pl-3 pr-9"
                        )
                      }
                      value={person}
                    >
                      {({ selected, active }) => (
                        <>
                          <div className="flex items-center">
                            <span
                              className={utils.classNames(
                                selected ? "font-semibold" : "font-normal",
                                "ml-3 block truncate"
                              )}
                            >
                              {person.name}
                            </span>
                          </div>

                          {selected ? (
                            <span
                              className={utils.classNames(
                                active ? "text-white" : "text-blue-500",
                                "absolute inset-y-0 right-0 flex items-center pr-4"
                              )}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    );
  };

  return (
    <>
      <div className="navbar__logo relative flex md:hidden mt-2 w-full justify-center">
        <MenuIcon
          onClick={() => setOpenSidebar(!sidebarOpen)}
          className="text-blue-500 absolute left-2.5 top-2.5 cursor-pointer"
          width="18px"
        />
        <a href="/dashboard">
          <img src={logo} alt="unknown" className="h-10 md:h-full" />
        </a>
      </div>
      <Popover className="custom__navbar m-3 md:mx-12 relative flex items-center">
        <div className="flex justify-end w-full items-center md:space-x-10">
          <div className="flex items-center justify-end">
            <Popover.Group as="nav" className="flex">
              <Popover className="relative flex">
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={utils.classNames(
                        open ? "text-gray-900" : "text-gray-500",
                        "relative mr-2 md:mr-4 group rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      )}
                    >
                      {notif_status && (
                        <div className="absolute right-1 top-0 p-1 bg-red-500 rounded-md"></div>
                      )}
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.5138 2.99609C13.2905 2.99622 14.9943 3.70203 16.2505 4.9583C17.5068 6.21456 18.2126 7.91838 18.2127 9.695V17.4144H4.81445V9.695C4.81458 7.9183 5.52046 6.2144 6.77682 4.95813C8.03318 3.70185 9.73712 2.99609 11.5138 2.99609V2.99609Z"
                          stroke="#347AE2"
                          strokeWidth="1.39024"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M3.0918 17.4141H19.9345"
                          stroke="#347AE2"
                          strokeWidth="1.39024"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M14.7012 17.4141C14.7012 19.0144 13.2734 20.3114 11.5137 20.3114C9.75398 20.3114 8.32617 19.0144 8.32617 17.4141"
                          stroke="#347AE2"
                          strokeWidth="1.39024"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </Popover.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel className="absolute z-10 -left-20 sm:left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-md sm:px-0">
                        <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                          <div className="relative gap-8 bg-white sm:gap-8 p-3 sm:p-8 overflow-auto h-96">
                            <div className="flex mb-5 items-center justify-between">
                              <div className="text-base sm:text-xl font-bold">
                                Notification
                              </div>
                              <div className="flex items-center">
                                <DropdownNotif />
                                <div
                                  onClick={handleReadAll}
                                  className="ml-1 text-xs font-bold text-blue-500 cursor-pointer"
                                >
                                  Read All
                                </div>
                              </div>
                            </div>
                            {notifications.length === 0 ? (
                              <div className="signature__empty p-0">
                                <img
                                  src={emptyState}
                                  width="50%"
                                  alt="unknown"
                                />
                                <h6 className="text-lg font-semibold">
                                  You do not have any notification
                                </h6>
                              </div>
                            ) : (
                              notifications.map((item, idx) => (
                                <div
                                  key={idx}
                                  onClick={() => handleReadNotification(item)}
                                  className={utils.classNames(
                                    item.status === "unread" && "bg-blue-50",
                                    "-m-3 p-3 mb-4 flex items-start sm:rounded-lg hover:bg-gray-50 cursor-pointer"
                                  )}
                                >
                                  <NotificationImage msg={item.mesage} />
                                  <div className="ml-4 w-full">
                                    <div className="flex items-center justify-between">
                                      <p className="text-sm font-semibold text-gray-900">
                                        {item.mesage}
                                      </p>
                                      <p
                                        style={{ fontSize: "10px" }}
                                        className="text-gray-500"
                                      >
                                        {item.time}
                                      </p>
                                    </div>
                                    <p className="mt-1 text-xs text-gray-500">
                                      <span className="black-notif">
                                        {item.sender}
                                      </span>{" "}
                                      {item.information}
                                    </p>
                                  </div>
                                </div>
                              ))
                            )}
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
            </Popover.Group>
            <UserSection setVerified={setVerified} />
          </div>
        </div>
      </Popover>
    </>
  );
};

export default Navbar;
