import { Link } from "react-router-dom";
import { FooterRequest } from "../request/footer-request";

const MyRequestSection = ({ data }) => {
  return (
    <div className="container pt-10 relative">
      <div className="flex justify-between align-center">
        <div className="text-2xl font-extrabold">My Request</div>
        <Link to="/dashboard/request">
          <span className="text__color_primary text-xs sm:text-sm font-semibold px-6 py-3">
            View All
          </span>
        </Link>
      </div>
      <div className="container mt-4">
        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto table__custom sm:-px-6 lg:-px-8">
            <div className="py-2 align-middle inline-block min-w-full">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-white-200">
                  <thead className="bg-white">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-2 text-left text-xs sm:text-sm font-extrabold text-gray-400 tracking-wider"
                      >
                        Document Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-2 text-left text-xs sm:text-sm font-extrabold text-gray-400 tracking-wider"
                      >
                        Action
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-2 text-left text-xs sm:text-sm font-extrabold text-gray-400 tracking-wider"
                      >
                        Date Created
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-2 text-left text-xs sm:text-sm font-extrabold text-gray-400 tracking-wider"
                      >
                        Owner
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-2 text-left text-xs sm:text-sm font-extrabold text-gray-400 tracking-wider"
                      >
                        Signers
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {data !== undefined &&
                      data.map((item, idx) => (
                        <tr key={`${item.id}-${idx}`}>
                          <td className="p-2 sm:px-6 sm:py-4 whitespace-nowrap">
                            <Link to={`/dashboard/request/detail/${item.id}`}>
                              <div className="text-base sm:text-lg font-semibold text__color_primary">
                                {item.name}
                              </div>
                            </Link>
                          </td>
                          <td className="p-2 sm:px-6 sm:py-4 whitespace-nowrap">
                            <FooterRequest data={item} />
                          </td>
                          <td className="p-2 sm:px-6 sm:py-4 whitespace-nowrap">
                            <span className="text-sm sm:text-base font-normal text-black">
                              {item.created_at}
                            </span>
                          </td>
                          <td className="p-2 sm:px-6 sm:py-4 whitespace-nowrap">
                            <div className="flex items-center">
                              <div className="flex-shrink-0 h-10 w-10">
                                <img
                                  className="h-10 w-10 rounded-full"
                                  src="https://icon-library.com/images/windows-user-icon/windows-user-icon-14.jpg"
                                  alt="unknown"
                                />
                              </div>
                              <div className="ml-4">
                                <div className="text-sm font-medium text-gray-900">
                                  {item.request_by}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="p-2 sm:px-6 sm:py-4 whitespace-nowrap">
                            <div className="text-sm font-bold text__color_primary">
                              {item.total_signer}
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyRequestSection;
