import React from "react";

const RefreshContext = React.createContext(null);

function useCustomRefresh() {
  const context = React.useContext(RefreshContext);
  return context;
}

function RefreshProvider(props) {
  const [refresh, setRefresher] = React.useState(true);

  return (
    <>
      <RefreshContext.Provider value={[refresh, setRefresher]} {...props} />
    </>
  );
}

export { useCustomRefresh, RefreshProvider };
