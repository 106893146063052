import React, { useState } from "react";
import "./auth.css";
import ornL from "../../assets/ornament/auth-left.svg";
import ornR from "../../assets/ornament/auth-right.svg";
import logo from "../../assets/images/logo-thumb.svg";
import { Link, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { postData } from "../../utility/network";
import { loginAction } from "../../redux/action";
import utils, { print } from "../../utility/utils";
import { AlertComponent } from "../../components/alerts/alert-component";
import { useCustomAlert } from "../../components/alerts/alert-provider";
import { LoadingBarier } from "../../components/loading-barrier";
import GoogleLogin from "react-google-login"; 

function Login() {
  const [setList] = useCustomAlert();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({ email: "", password: "" });
  const login = useSelector((state) => state.Auth.login);

  const handleLogin = () => {
    let abortController = new AbortController();
    setLoading(true);

    let kosong = false;
    let err = {
      email: "",
      password: "",
    };
    setErrors(err);

    // email validation
    if (email.length === 0) {
      err.email = `Email field can't be empty!`;
      kosong = true;
      setLoading(false);
    }
    if (!utils.validateEmail(email)) {
      err.email = `Email doesn't valid!`;
      kosong = true;
      setLoading(false);
    }

    // password validation
    if (password.length === 0) {
      err.password = `Password field can't be empty!`;
      kosong = true;
      setLoading(false);
    }
    if (password.length < 6) {
      err.password = `Minimal 6 character!`;
      kosong = true;
      setLoading(false);
    }

    setErrors(err);

    if (!loading && !kosong) {
      const body = {
        email: email,
        password: password,
      };

      let key = utils.randomAlphaNumeric();

      postData(
        "login",
        body,
        (data) => {
          setList([
            <AlertComponent key={key} type="success" msg="Authenticated" />,
          ]);

          // set token to redux and local storage
          dispatch(loginAction({ token: data.access_token, login: true }));

          setLoading(false);
        },
        (msg, errors) => {
          print(errors);
          setList([
            <AlertComponent
              key={key}
              type="error"
              msg={
                errors.message === "Unauthorized"
                  ? "Wrong email or password"
                  : errors.message
              }
            />,
          ]);
        },
        () => {
          // you can put loading here
          setLoading(false);
          abortController.abort();
          //print("ON FINISHED");
        }
      );
    }
  };

  const handleLoginGoogle = async (data) => {
    let key = utils.randomAlphaNumeric();
    let body = {
      name: data.profileObj.name,
      email: data.profileObj.email,
      link_image: data.profileObj.imageUrl,
      id: data.profileObj.googleId,
    };
    setLoading(true);

    postData(
      "login/google",
      body,
      (data) => {
        setList([
          <AlertComponent key={key} type="success" msg="Authenticated" />,
        ]);

        // set token to redux and local storage
        dispatch(loginAction({ token: data.access_token, login: true }));
      },
      (msg, errors) => {
        print(errors);
        setList([
          <AlertComponent
            key={key}
            type="error"
            msg={
              errors.message === "Unauthorized"
                ? "Wrong email or password"
                : errors.message
            }
          />,
        ]);
      },
      () => {
        // you can put loading here
        setLoading(false);
        //print("ON FINISHED");
      }
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.currentTarget;

    if ("email" === name) {
      setEmail(value);
    }
    if ("password" === name) {
      setPassword(value);
    }
  };

  if (login) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <>
      {loading && <LoadingBarier />}
      <div className="auth__wrapper">
        <img src={ornL} alt="unknown" className="auth__ornL" />
        <img src={ornR} alt="unknown" className="auth__ornR" />

        <div className="auth__form">
          <img src={logo} alt="unknown" />
          <h1>Welcome to Teken</h1>
          <p>
            Tidak memiliki akun?{" "}
            <Link to="/register">
              <b>Daftar disini</b>
            </Link>
          </p>

          <div className="auth__input">
            {/* Input Email */}
            <div className="relative flex w-full flex-wrap items-stretch mb-3">
              <span className="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3">
                {/* <i className="fas fa-lock"></i> */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  style={{ opacity: "0.3" }}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                  />
                </svg>
              </span>
              <input
                type="email"
                placeholder="Email"
                name="email"
                onChange={handleChange}
                className="px-3 py-3 w-full pl-12"
              />
              <small className="text-red-500">{errors.email}</small>
            </div>

            <div className="relative flex w-full flex-wrap items-stretch mb-3">
              <span className="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  style={{ opacity: "0.3" }}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                  />
                </svg>
              </span>
              <input
                autoComplete="off"
                type="password"
                placeholder="Password"
                name="password"
                onChange={handleChange}
                className="px-3 py-3 w-full pl-12"
              />
              <small className="text-red-500">{errors.password}</small>
            </div>

            <div className="text-right auth__forgot">
              <Link to="/email-forget">Lupa Password?</Link>
            </div>
          </div>

          <div className="auth__submit">
            <button onClick={handleLogin} className="login__btn">
              Login
            </button>
          </div>

          <div className="auth__divider">
            <span>Atau</span>
          </div>

          <div className="auth__google">
            <GoogleLogin
              clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
              buttonText="Log in with Google"
              onSuccess={handleLoginGoogle}
              onFailure={(x) => print(x)}
              cookiePolicy={"single_host_origin"}
              disabled={loading}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
