export const CreateSignatureStep = [
  {
    selector: '[data-tour="add-signature"]',
    content: "Klik disini untuk membuat signature baru",
  },
  {
    selector: '[data-tour="menu-signature"]',
    content: "Menu tambahan sesuai dengan kebutuhan pengguna",
  },
];
