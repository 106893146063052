export const LoginFirst = () => {
  return (
    <div className="signature__empty">
      <div className="text-5xl font-bold mb-2 text-red-500">WARNING</div>
      <div className="text-lg font-bold mb-10">
        You need to login first to access this document!
      </div>
      <a href="/login">
        <div className="btn__primary w-full rounded-md px-5 py-1">Login</div>
      </a>
    </div>
  );
};
