import { Link } from "react-router-dom";
import { getData } from "../../utility/network";
import utils, { print } from "../../utility/utils";
import { AlertComponent } from "../alerts/alert-component";
import { useCustomAlert } from "../alerts/alert-provider";
import { triggerBase64Download } from "react-base64-downloader";

export const StatusButton = ({
  mainStatus,
  userStatus,
  id,
  reqSendFinal = false,
  data,
  docTitle,
}) => {
  mainStatus = mainStatus && mainStatus.toLowerCase();
  userStatus = userStatus && userStatus.toLowerCase();

  const [setList] = useCustomAlert();

  if (
    mainStatus === "decline" ||
    mainStatus === "reject" ||
    mainStatus === "rejected" ||
    mainStatus === "Rejected"
  ) {
    // whatever the condition, auto null
    if (userStatus !== "reject") {
      return <></>;
    }

    // parent was rejected
    return (
      <Link to={`/dashboard/collaboration/edit/${id}`}>
        <div className="ml-2 text-sm text-white cursor-pointer font-semibold rounded-md flex justify-center py-1 px-3 btn__danger">
          Regenerate
        </div>
      </Link>
    );
  } else if (mainStatus === "accept" || mainStatus === "accepted") {
    const handleDownloadSign = () => {
      let key = utils.randomAlphaNumeric();
      let base64;

      getData(
        `collab/${id}/detail/${data.id}/save`,
        {},
        (res) => {
          base64 = "data:image/png;base64," + res.data.qr_code;
        },
        (msg, error) => {
          print(error);
          setList([
            <AlertComponent key={key} type="error" msg={error.message} />,
          ]);
        },
        () => {
          //print("Fetch signature download Finished");

          triggerBase64Download(
            base64,
            docTitle + "-" + data.name + "-" + data.id
          );
        }
      );
    };

    // all user have been accept the request
    return (
      <>
        <div>
          <div
            onClick={handleDownloadSign}
            className="ml-2 mr-6 sm:mr-0 text-sm font-semibold rounded-md flex justify-center py-1 px-3 btn__primary"
          >
            Download Signature
          </div>
          {reqSendFinal && (
            <Link to={`/dashboard/send-final-document/${id}`}>
              <div
                // onClick={() => setOpen(true)}
                className="mt-2 ml-2 text-sm font-semibold rounded-md flex justify-center py-1 px-3 btn__success"
              >
                Send Final Document
              </div>
            </Link>
          )}
        </div>
      </>
    );
  } else {
    const handleRemind = () => {
      let key = utils.randomAlphaNumeric();

      getData(
        `document/${id}/remind`,
        {},
        (data) => {
          setList([
            <AlertComponent
              key={key}
              type="success"
              msg="Reminder have been sent!"
            />,
          ]);
        },
        (msg, error) => {
          print(error);
          setList([
            <AlertComponent key={key} type="error" msg={error.message} />,
          ]);
        },
        () => {
          //print("Finished send remind");
        }
      );
    };

    if (userStatus === "waiting" || userStatus === "pending") {
      return (
        <div
          onClick={handleRemind}
          className="ml-2 text-sm font-semibold text-white cursor-pointer rounded-md flex justify-center py-1 px-3 btn__warning"
        >
          Remind
        </div>
      );
    } else {
      return <></>;
    }
  }
};
